export class APIMapBox {
  // https://account.mapbox.com/
  access_token =
    'pk.eyJ1IjoiaW90bnh0IiwiYSI6ImNrcmZ6bGZodDYxM3Qyd282MnRlOHk0cngifQ.BMlbTN6s8veYesYHD8C_8g';

  // https://docs.mapbox.com/api/search/geocoding/
  geocodeReverse = async (longitude: number, latitude: number): Promise<GeocodeReverseResult> => {
    return fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${this.access_token}&limit=1`,
    ).then(res => res.json());
  };

  // https://docs.mapbox.com/api/search/geocoding/#forward-geocoding
  lookupPlaceName = async (search_text:string) => {
    return fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json?access_token=${this.access_token}&limit=1`,
    ).then(res => res.json());
  }

  geocodeReversePlaceName = async (longitude: number, latitude: number): Promise<string | undefined> => {
    const result = await this.geocodeReverse(longitude,latitude);
    if (result && result.features && result.features[0] && result.features[0].place_name) return result.features[0].place_name;
  }
}

export const apimapbox = new APIMapBox();

export interface GeocodeFeature {
  center: [number,number],
  // context: [Array],
  geometry: Geometry,
  id: string,
  place_name: string,
  place_type: [],
  properties: [],
  relevance: 1,
  text: string,
  type: string,
}

export interface GeocodeReverseResult {
  attribution: string
  features: GeocodeFeature[]
  query: [number,number],
  type: 'FeatureCollection',
};

export interface Geometry {
  "type": string,
  "coordinates": [number,number]
}