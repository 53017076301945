import { calculateTokenTime } from "./calculateTokenTime";
import { IToken } from "./itoken";
import { isJson } from '../isjson'

export function checkToken(tokenInput?: string | null): IToken | undefined {
    const localStorageTokenString = localStorage.getItem("token");
    if (isJson(localStorageTokenString) && localStorageTokenString !== null) {
        const token = JSON.parse(localStorageTokenString);
        // console.log('token time left:', calculateTokenTime(token));
        if (calculateTokenTime(token) > 0) {
            return token;
        }
    }
}