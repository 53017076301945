// https://community.iotnxt.com/docs/commander/integration/theming-api/
// by Rouan van der Ende Fri 08 Jul 2022

import { IAPIOptions } from "./main"

export interface IUsername {
    id: string
    username: string
}

export class APIIoTnxtAccountManagerAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }

    account = {
        /** get information on the authorized person */
        get: async (): Promise<unknown> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/account-manager/v1/account`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        },
        owners: async (): Promise<IUsername[]> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/account-manager/v1/account/owners`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        },
        members: async (): Promise<IUsername[]> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/account-manager/v1/account/members`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        },
    }
}
