import React, { Suspense } from "react";
import { Box, FormControlLabel, Paper, Switch, CircularProgress } from '@mui/material';
import { IMapsProps, IMapsState, IMapsStateChangeEvent, INode, ViewMapState } from "./interfaces_maps";

import { IconLayer } from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl'
import { IconClusterLayer, clusterIconMapping } from "./iconClusterLayer";
import { InitialViewStateProps } from "@deck.gl/core/lib/deck"
import { WebMercatorViewport } from '@deck.gl/core';
import { ILatLon } from "..";
import { localStorageGetBoolean, localStorageSetBoolean } from "../utils/localstorage";

const DeckGL = React.lazy(() => import('@deck.gl/react'));

// https://stackoverflow.com/questions/7780981/how-to-validate-latitude-and-longitude
export const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;
export const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

export class Maps<T> extends React.Component<IMapsProps<T>, IMapsState<T>> {
    state: IMapsState<T> = {
        showCluster: this.props.showCluster,
        mapStyleToggle: true,
        hoverInfo: undefined,
        initialViewState: undefined,
        viewState: undefined
    };

    map?: typeof DeckGL;

    componentDidMount = () => {
        // this.getUserLocation();
        this.loadMapSettings();
    }

    loadMapSettings = () => {
        const mapStyleToggle = localStorageGetBoolean('mapStyleToggle', true);
        this.setState({ mapStyleToggle });
    }

    // getUserLocation = async () => {
    //     if ('geolocation' in navigator) {
    //         /* geolocation is available */

    //         // TODO: store users last known location so we can load it faster
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             // doSomething(position.coords.latitude, position.coords.longitude);
    //             this.setState({ center: [position.coords.latitude, position.coords.longitude] })
    //         });

    //     } else {
    //         /* geolocation IS NOT available */
    //     }
    // }

    public goToNewLocation = (initialViewState: InitialViewStateProps) => {
        console.log('going to new location', initialViewState);
        this.setState({ initialViewState });
    }

    renderTooltip() {

        if (!this.state.hoverInfo) return <div></div>

        const { object, x, y } = this.state.hoverInfo;
        let info = this.state.hoverInfo;

        if (info.objects) {
            return (
                <div className="tooltip interactive" style={{ left: x, top: y, position: 'absolute' }}>
                    {info.objects.map((data, index) => {
                        return (
                            <div key={index}>
                                <pre>{JSON.stringify(data, null, 2)}</pre>
                                {/* <h5>{name}</h5>
                                <div>Year: {year || 'unknown'}</div>
                                <div>Class: {meteorClass}</div>
                                <div>Mass: {mass}g</div> */}
                            </div>
                        );
                    })}
                </div>
            );
        }

        if (!object) {
            return null;
        }

        return object.cluster ? (
            <Paper sx={{ left: x, top: y - 35, position: 'absolute', p: 0.5, pl: 1, pr: 1 }}>
                {object.point_count} records
            </Paper>
        ) : (
            <Paper sx={{ left: x, top: y - 35, position: 'absolute', p: 0.5, pl: 1, pr: 1 }}>
                {object.data.instanceName}
            </Paper>
        );

    }

    render() {

        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocationOnIcon"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>

        if (!this.props.listOfNodes) return <CircularProgress />
        // @ts-ignore
        let StaticMapR: any = StaticMap;

        const layer = this.state.showCluster
            ? new IconClusterLayer<INode<T>>({
                id: 'icon-cluster',
                data: this.props.listOfNodes,
                // @ts-ignore
                iconAtlas: '/assets/vodaMapMarkerCluster.png',
                // @ts-ignore
                iconMapping: clusterIconMapping,
                // @ts-ignore
                getPosition: d => d.location.coordinates,
                // @ts-ignore
                // getSize: d => 5,
                sizeScale: 40,
                // @ts-ignore
                pickable: true,
                onHover: (hoverInfo) => { this.setState({ hoverInfo }) },
            })
            : new IconLayer<INode<T>>({
                id: 'icon-layer',
                data: this.props.listOfNodes,
                pickable: true,
                // iconAtlas and iconMapping are required
                // getIcon: return a string
                // iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
                iconAtlas: '/assets/vodaMapMarker.png',
                iconMapping: {
                    marker: { x: 0, y: 0, width: 128, height: 256, mask: false },
                    selected: { x: 128, y: 0, width: 128, height: 256, mask: false }
                },
                getIcon: (d: any) => {
                    // console.log(d);
                    return d.picked ? 'selected' : 'marker'
                },
                sizeScale: 10,
                // @ts-ignore
                getPosition: d => d.location.coordinates,
                getSize: d => 8,
                onHover: (hoverInfo) => { this.setState({ hoverInfo }) }
            });


        return <>
            <Box sx={{ pl: 2 }}>
                <FormControlLabel control={<Switch
                    value={this.state.mapStyleToggle}
                    checked={this.state.mapStyleToggle}

                    onChange={() => {
                        let mapStyleToggle = !this.state.mapStyleToggle;
                        localStorageSetBoolean('mapStyleToggle', mapStyleToggle);
                        this.setState({ mapStyleToggle });
                    }} />} label={this.state.mapStyleToggle ? 'Street' : 'Satelite'} />
            </Box>
            <Box sx={{
                width: '100%',
                height: this.props.height,
                overflow: 'hidden',
                display: 'flex',
                position: 'relative',
            }}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    '.mapboxgl-ctrl': {
                        display: 'none !important'
                    }
                }}>
                    <Suspense fallback={<CircularProgress />}>
                        {
                            // @ts-ignore
                            <DeckGL
                                ref={(el) => {
                                    // @ts-ignore
                                    if (el) this.map = el;
                                }}
                                initialViewState={this.state.initialViewState || this.props.initialViewState || {
                                    longitude: -122.41669,
                                    latitude: 37.7853,
                                    zoom: 13,
                                    pitch: 0,
                                    bearing: 0
                                }}
                                onClick={
                                    (info: any) => {

                                        if (this.props.disabled === true) return;

                                        if (this.props.onClick) this.props.onClick(info as any);

                                        let ob = info.object as any;

                                        /** if the user clicks on a cluster for center and zoom a bit */
                                        if (!ob) return;
                                        if (ob.cluster) {
                                            let longitude = info.coordinate ? info.coordinate[0] : 0;
                                            let latitude = info.coordinate ? info.coordinate[1] : 0;
                                            let zoom = 10;
                                            if (this.props.initialViewState?.zoom) zoom = this.props.initialViewState?.zoom;
                                            if (this.state.viewState?.zoom) zoom = this.state.viewState?.zoom;
                                            if (info.viewport?.zoom) zoom = info.viewport.zoom;

                                            zoom += 3;

                                            this.setState({ initialViewState: { zoom, latitude, longitude, bearing: 0, pitch: 0 } })
                                        }
                                    }
                                }

                                getCursor={(e) => {
                                    if (this.props.disabled === true) return 'default';
                                    // drag map
                                    if (!e.isHovering && e.isDragging) return 'grabbing';

                                    // hovering and dragging on a marker?                        
                                    if (e.isHovering && e.isDragging) return 'move';

                                    // hovering over a marker
                                    if (e.isHovering && !e.isDragging) return 'pointer';

                                    // default cursor
                                    if (!e.isHovering && !e.isDragging) return 'default';
                                    return 'default'
                                }}
                                onViewStateChange={(data) => {
                                    if (this.props.disabled === true) return;
                                    // add bounds to output
                                    const viewport = new WebMercatorViewport(data.viewState);
                                    const nwTemp = viewport.unproject([0, 0]);
                                    const seTemp = viewport.unproject([viewport.width, viewport.height]);
                                    const nw: ILatLon = { longitude: nwTemp[0], latitude: nwTemp[1] };
                                    const se: ILatLon = { longitude: seTemp[0], latitude: seTemp[1] }
                                    let output: IMapsStateChangeEvent = { ...data, ...{ bounds: { nw, se } } }
                                    // end add bounds to output

                                    this.setState({ viewState: output.viewState as ViewMapState })
                                    if (this.props.showCluster && output?.viewState?.zoom < 15) {
                                        this.setState({ showCluster: true })
                                    } else {
                                        this.setState({ showCluster: false })
                                    }
                                    if (this.props.onViewStateChange) this.props.onViewStateChange(output);
                                }}
                                controller={(this.props.disabled === true) ? undefined : true}
                                layers={[layer]} >

                                <StaticMapR
                                    // mapStyle="mapbox://styles/mapbox/streets-v11"
                                    mapStyle={this.state.mapStyleToggle ? "mapbox://styles/mapbox/streets-v11" : "mapbox://styles/mapbox/satellite-streets-v11"}
                                    attributionControl={false}
                                    mapboxApiAccessToken={this.props.mapboxApiAccessToken} />

                                {this.renderTooltip()}
                            </DeckGL>}
                    </Suspense>
                </Box>

            </Box >
        </>
    }

}