import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { IMainProps, IMainState } from "./interfaces";
import { Outlet } from "react-router-dom";
import { isJson } from "../utils";
import { OAuth } from "../utils";




export class Main extends React.Component<IMainProps, IMainState> {
  state: IMainState = {
    height: window.innerHeight,
    darkMode: true
  };

  localStateKey = "mainStateStorage";

  componentDidMount = async () => {

    const loadMainState = await localStorage.getItem(this.localStateKey);
    if (loadMainState && isJson(loadMainState)) {
      let lastState = JSON.parse(loadMainState) as IMainState;
      const newState: Partial<IMainState> = {};
      if (lastState.open !== undefined) newState.open = lastState.open;
      if (lastState.darkMode !== undefined)
        newState.darkMode = lastState.darkMode;

      this.setState({ darkMode: newState.darkMode });
    } else {
      this.setState({ open: true }); //default
    }

    window.addEventListener("resize", this.handleResizeChanges);
  };

  componentWillUnmount = async () => {
    window.removeEventListener("resize", this.handleResizeChanges);
  };

  toggle = async () => {
    const newState = { open: !this.state.open };
    this.setState(newState);
    await localStorage.setItem(this.localStateKey, JSON.stringify(newState));
  };

  handleDarkModeChanges = (e: MediaQueryListEvent) => {
    this.setState({ darkMode: e.matches });
  };

  handleResizeChanges = () => {
    this.setState({ height: window.innerHeight });
  };


  render(): React.ReactNode {


    return (<OAuth appsettings={this.props.appsettings} onAuth={async (auth) => {
      this.props.onAuth(auth);
    }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: window.innerHeight
          }}
        >

          {this.props.alert}

          <Outlet />
        </Box>
      </Box>
    </OAuth>
    );
  }
}
