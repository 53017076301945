// IoT.nxt
// By Rouan van der Ende
// Apr 2022

// test with https://localhost:6001/site/12f8ea9e-f1bd-4a4e-894f-eced91d5044b

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    Tooltip,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import * as React from 'react';

import {
    Params,
    useLocation,
    useNavigate,
    useParams,
    Location as RouterLocation,
    NavigateFunction
} from "react-router-dom";

import { IVSpaceProperty } from './interfaces_vspaces';

import {
    DataGridPro,
    GridColumns,
    GridEnrichedColDef,
    LicenseInfo,
} from '@mui/x-data-grid-pro';

import { WidgetText } from '../components/widget';

import {
    DeleteTwoTone,
    HistoryTwoTone,
    RefreshTwoTone,
    SaveTwoTone,
    UndoTwoTone
} from '@mui/icons-material';

import _ from 'lodash';

import { INode, isLatitude, isLongitude, Maps, ViewMapState } from '../components/maps';
import { PageView } from './page';
import { IToken } from '../components/utils/oauth/itoken';
import { APIVodafoneVSpacesAPI } from './api';
import { ICloudServiceActionResult } from './interfaces';
import { Link as RouterLink } from 'react-router-dom'
import { AllowedUses } from './lists_use';
import { APIMapBox, GeocodeReverseResult } from '../common/api_mapbox';
import { Countries, Regions } from './countries';
import clsx from 'clsx';
import { gridStyles } from './vspace_gridstyles';
import { formatCurrency } from '../components/widget/formatCurrency';
import { processInstanceCalculations, removeCalculations } from './vspace_calculations';
import { localStorageGetBoolean, localStorageSetBoolean } from '../components/utils/localstorage';

import { IAppState } from '../App';

LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

interface VSpaceSiteViewProps {
    onInstanceId: (instanceId: string) => IVSpaceProperty[]
    router?: {
        location: RouterLocation
        params: Params<"instanceId">
        navigate: NavigateFunction
    }
    columns?: GridColumns<any>
    token: IToken
    onClickRefresh: () => void
    appState: IAppState
}

interface VSpaceSiteViewState {
    data?: IVSpaceProperty[]
    dataOrig?: IVSpaceProperty[]
    instanceId?: string

    /** when typing in a new purpose the text is kept here */
    newPurposeText?: string
    mapZoom: number
    mapViewState?: ViewMapState
    mapLongitude?: number
    mapLatitude?: number

    /** used to display please wait info alert */
    pleaseWait: boolean;
    /** to display success/error messages */
    lastResponses: ICloudServiceActionResult<any>[]

    enableSetLocation: boolean
    mapboxReverseResult?: GeocodeReverseResult

    showDeleteSiteConfirmDialog: boolean

    /** if the site is editable or not depends on the user permissions for operating entity */
    editable?: boolean
}

interface PivotTable<T> {
    id: string
    headerName: string
    column?: GridEnrichedColDef<T>
    grandTotal: number
}

const filteredOutFields: (keyof IVSpaceProperty)[] = [
    "id",
    "use",
    "instanceName",
    "latitude",
    "longitude",
    "country",
    "region",
    "currency",
    "city",
    "operatingEntity",
    "originalUse",
    "assetId",
    "retailId",
    "state",
    "instanceId",
    "templateId",
    "templateName",
    "year",
    "buildingArea",
    "numberOfBuildings", // moved to sidebar
    "freeHoldBuildings", // moved to sidebar
];

class VSpaceSiteView extends React.Component<VSpaceSiteViewProps, VSpaceSiteViewState> {
    state: VSpaceSiteViewState = {
        newPurposeText: '',
        mapZoom: 0,
        pleaseWait: false,
        lastResponses: [],
        enableSetLocation: false,
        mapboxReverseResult: undefined,
        showDeleteSiteConfirmDialog: false,
        editable: undefined
    }

    // private gridRef: any;
    // // @ts-ignore
    // constructor(props) {
    //     super(props);
    //     this.gridRef = React.createRef();
    // }

    componentDidMount = () => {
        this.getData();
        this.animatezoom();
    }

    animatezoom = () => {
        const zooms = [];
        for (var z = 0.1; z < 15; z += 0.1) {
            zooms.push(z);
        }

        zooms.forEach(z => {
            setTimeout(() => {
                this.setState({ mapZoom: z })
            }, 0 + (250 * z));
        });
    }

    getData = async () => {

        if (!this.props.router?.params.instanceId) throw Error('missing instanceId');

        let data: IVSpaceProperty[] = this.props.onInstanceId(this.props.router.params.instanceId);
        let latLong = this.getLatLong(data);

        // PERMISSIONS

        let editable = (data && data[0] && data[0].operatingEntity && this.props.appState.userPermissionEntities)
        ? (this.props.appState.userPermissionEntities.indexOf(data[0].operatingEntity) >= 0)
        : this.state.editable
        if (this.props.appState.simplifiedAccessRules?.isAdmin) editable = true;
        // END PERMISSIONS

        await this.setState({
            data,
            dataOrig: JSON.parse(JSON.stringify(data)),
            instanceId: this.props.router.params.instanceId,
            mapLatitude: latLong.latitude,
            mapLongitude: latLong.longitude,
            editable
        });
    }

    addUse = (use?: string) => {
        if (!use) throw new Error('need a use string to remove');
        if (!this.state.data) return;
        if (!this.state.data[0]) return;
        let newEntry: IVSpaceProperty = JSON.parse(JSON.stringify(this.state.data[0])); // deep clone
        // newEntry.id = `${newEntry.instanceId}_${use}`;
        delete newEntry.id;
        newEntry.use = use

        // new purpose rows should be zeroed out for the visible fields.
        Object.keys(newEntry).forEach((k) => {
            // @ts-ignore
            if (filteredOutFields.indexOf(k) === -1) newEntry[k] = 0;
        })

        let data = this.state.data;
        data.push(newEntry);

        this.setState({ data, newPurposeText: '' });
    }

    removeUse = async (use?: string) => {
        if (!use) throw new Error('need a use string to remove');
        if (!this.state.data) throw Error('Missing data');
        console.log(`Removing use '${use}'`)


        if (this.state.data.length === 1) {
            this.setState({ lastResponses: [{ isSuccessful: false, exceptionMessage: 'Not allowed to remove the last use. Please first add another use.' }] });
            setTimeout(() => {
                this.setState({ lastResponses: [] });
            }, 2500)
            return;
        }

        let data = _.clone(this.state.data);
        await this.setState({ data: undefined });
        data = data?.filter(d => d.use !== use);
        this.setState({ data });
    }

    getLatLong = (data: IVSpaceProperty[]): { latitude: number, longitude: number } => {
        if (!data) throw Error('missing data for coordinates');
        const longitude = this.getCoordinates(data).location.coordinates[0]
        const latitude = this.getCoordinates(data).location.coordinates[1]
        this.getMapboxLocation({ latitude, longitude });
        return { latitude, longitude }
    }

    getMapboxLocation = async (latlng: { latitude: number, longitude: number }) => {
        let mapboxReverseResult = await new APIMapBox().geocodeReverse(latlng.longitude, latlng.latitude);
        console.log(mapboxReverseResult);
        this.setState({ mapboxReverseResult })
    }

    getCoordinates = (data: IVSpaceProperty[]): INode<IVSpaceProperty> => {
        let coordinates: [number, number] = [0, 0];
        if (!data) throw Error('missing data for coordinates');
        if (!data[0]) throw Error('missing data for coordinates');
        let d = data[0];
        if (d.latitude && d.latitude >= -90 && d.latitude <= 90) coordinates[1] = typeof d.latitude === 'string' ? parseFloat(d.latitude) : d.latitude;
        if (d.longitude && d.longitude >= -180 && d.longitude <= 180) coordinates[0] = typeof d.longitude === 'string' ? parseFloat(d.longitude) : d.longitude;
        let output = { data: d, location: { type: "Point", coordinates } };
        return output as INode<IVSpaceProperty>;
    }

    /** prepares data for the vertical grid.
     * rows become columns and columns become rows */
    prepareGridData = () => {
        if (!this.props.columns) throw Error('Missing column data');
        if (!this.state.data) throw Error('Missing data');
        if (!this.state.data[0]) throw Error('Invalid data');

        let columns: GridColumns<PivotTable<IVSpaceProperty>> = [];

        columns.unshift({
            field: 'headerName',
            headerName: ' ',
            align: 'left',
            width: 300,
            cellClassName: (params) => {
                return clsx('vspacegrid', {
                    notEditable: (this.state.editable) ? (params.row?.column?.editable === false) : true,
                })
            },
        })

        this.state.data.map(processInstanceCalculations).forEach(d => {
            let width = 150;
            if (d.use) {
                let w = d.use.length * 20;
                if (w > width) width = w;
            }

            columns.push({
                field: d.use || 'not set',
                headerName: d.use,
                editable: true,
                sortable: false,
                align: 'right',
                width,
                // flex: 1,
                cellClassName: (params) => {
                    return clsx('vspacegrid', {
                        notEditable: (this.state.editable) ? (params.row?.column?.editable === false) : true,
                    })
                },
                renderCell: (params) => {
                    if (params.id === 'action') {
                        return <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            sx={{ width: '100%' }}
                            onClick={() => { this.removeUse(params.field); }}
                            startIcon={<DeleteTwoTone />}>Delete use</Button>;
                        // return <>
                        //     <Typography>{params.colDef.headerName}</Typography>
                        //     <Button color="error" startIcon={<DeleteTwoTone />}>Delete use</Button>
                        //     <IconButton color="error" onClick={() => { this.removeUse(params.field); }}><DeleteTwoTone /></IconButton>
                        // </>
                    }

                    if (params.row.headerName.toLowerCase().indexOf('cost') >= 0) return <Typography>{formatCurrency(params.value, this.getProperty().currency)}</Typography>

                    return <Typography>{params.value}</Typography>;
                },

            });
        });

        columns.push({
            field: 'grandTotal',
            headerName: 'Grand Total',
            editable: false,
            align: 'right',
            width: 200,
            renderCell: (params) => {
                if (params.row.headerName.toLowerCase().indexOf('cost') >= 0) return <Typography>{formatCurrency(params.value, this.getProperty().currency)}</Typography>
                return (params.id === 'action') ? <Typography></Typography> : <Typography sx={{ fontWeight: 'bold' }}>{params.value}</Typography>
            },
            cellClassName: (params) => {
                return clsx('vspacegrid', {
                    notEditable: true,
                })
            },
        })

        let rows: PivotTable<IVSpaceProperty>[] = [];

        rows = this.props.columns.map((c, index) => {
            let newrow: PivotTable<IVSpaceProperty> = {
                id: `${this.state.instanceId}_${index}`,
                headerName: c.headerName || 'missing',
                column: c,
                grandTotal: 0
            }

            this.state.data?.forEach(d => {
                // @ts-ignore
                if (d.use) {
                    // @ts-ignore
                    let val = d[c.field as keyof d];
                    // @ts-ignore
                    newrow[d.use] = val
                    newrow.grandTotal += val;
                };
            })

            return newrow;
        })

        // filters out rows we dont want to edit
        rows = rows.filter(r => {
            if (!r.column) return true; // Action rows have no original column data.
            return (filteredOutFields.indexOf(r.column.field as keyof IVSpaceProperty) < 0)
        });

        rows = rows.sort((a, b) => a.headerName > b.headerName ? 1 : -1)

        // only add the action row to delete use columns if the user has edit rights
        if (this.state.editable) rows.unshift({ id: 'action', headerName: '', column: undefined, grandTotal: 0 })

        // // add grandTotal
        // rows = rows.map( r => {
        //     console.log({r});
        //     r.grandTotal = 0;
        //     return r;
        // })

        return { rows, columns };
    }

    getTotalCost = (): number => {
        if (!this.state.data) throw new Error('missing data');
        let totalCost = this.state.data.map(d => {
            return (d.estateCost || 0)
                + (d.facilitiesHardCost || 0)
                + (d.facilitiesSoftCost || 0)
                + (d.utilityCost || 0);
        }).reduce((partialSum, a) => partialSum + a, 0);

        return totalCost;
    }

    /** calculate the total needed for the side panel total area required to meet group target. */
    getTotalAreaRequiredToMeetGroupTarget = (): number => {
        if (!this.state.data) throw new Error('missing data');

        const totalAssignedUsers = this.state.data.map(d => {
            return (d.assignedUsers || 0)
        }).reduce((partialSum, a) => partialSum + a, 0);

        /** formula according to Timothy word doc is total assigned users times 10 */
        const totalAreaRequiredToMeetGroupTarget = totalAssignedUsers * 10.0;
        return totalAreaRequiredToMeetGroupTarget;
    }

    getCostOfSurplusAreaPA = (): number => {
        const costOfSurplusAreaPA = 0;
        return costOfSurplusAreaPA;
    }

    getTotalOnProperty(propertyName: keyof IVSpaceProperty): number {
        if (!this.state.data) return 0;

        return this.state.data.map(d => {
            let val = d[propertyName];
            if (typeof val === "number") return val;
            return 0;
        }).reduce((partialSum, a) => partialSum + a, 0);
    }

    /** when grid cell changes we update the state data */
    updateValue = (update: {
        use: string,
        field: string,
        value: number
    }) => {
        if (!update) throw Error('missing update');
        if (!this.state.data) throw Error('missing state.data');

        let data = _.clone(this.state.data);


        data = data.map(d => {
            if (d.use === update.use) d[update.field as keyof IVSpaceProperty] = update.value as any;
            return d;
        })


        this.setState({ data });
    }

    undoChanges = () => {
        // write state.dataOrig into state.data
        this.setState({ data: JSON.parse(JSON.stringify(this.state.dataOrig)) }); // deep clone
    }

    saveChanges = async () => {


        const vspaceapi = new APIVodafoneVSpacesAPI(this.props.token);
        if (!this.props.token) throw new Error('missing token');
        if (!this.state.data) throw new Error('missing data');
        if (!this.state.dataOrig) throw new Error('missing dataOrig');

        this.setState({ pleaseWait: true });
        // compare dataOrig to data and remove uses if needed
        let dataOrigUses = this.state.dataOrig.map(d => d.use).sort((a, b) => ((a || '') > (b || '')) ? 1 : -1);
        let dataUses = this.state.data.map(d => d.use).sort((a, b) => ((a || '') > (b || '')) ? 1 : -1);
        let deletedUses = dataOrigUses.filter(d => dataUses.indexOf(d) < 0)
        let deletedInstances = deletedUses.map(d => {
            if (!this.state.dataOrig) throw Error('missing dataOrig for removing')
            return this.state.dataOrig.filter(o => o.use === d)[0]
        })
        console.log(deletedInstances);

        let deleteTasks = deletedInstances.map(d => vspaceapi.api.v1.VSpacesDigitwin.RemoveInstance(d));
        let deleteResults = await Promise.all(deleteTasks);
        this.setState({ lastResponses: deleteResults, pleaseWait: false });



        let instancesToAddOrUpdate = this.state.data.filter(d => {
            console.log('checking for diff');
            if (!this.state.dataOrig) throw new Error('missing dataOrig to check changes');
            console.log('filtering');
            let orig = this.state.dataOrig.filter(o => o.use === d.use);
            console.log(1);
            if (orig.length === 0) return true; // its new so we will add it.
            console.log(2);

            // did exist before so check if it changed.
            if (orig[0]) return (JSON.stringify(orig[0]) !== JSON.stringify(d));
            console.log(3);
            return false;
        })
        console.log(4);
        console.log({ instancesToAddOrUpdate });
        if (instancesToAddOrUpdate.length !== 0) {
            this.setState({ pleaseWait: true });
            let tasks = instancesToAddOrUpdate.map(d => vspaceapi.api.v1.VSpacesDigitwin.AddOrUpdateInstance(removeCalculations(d)));
            // let result = await vspaceapi.api.v1.VSpacesDigitwin.AddOrUpdateManyInstances(this.state.data);
            let result = await Promise.all(tasks);
            this.setState({ pleaseWait: false, lastResponses: deleteResults.concat(result) });
        }

        await this.refresh();
    }

    /** returns true if changes were made that can be saved */
    wasThereChanges = (): boolean => {
        if (!this.state.data) return false;
        if (!this.state.dataOrig) return false;
        let dataChanged = (JSON.stringify(this.state.data) !== JSON.stringify(this.state.dataOrig));
        return dataChanged;
    }

    getListOfUsesCurrentUses = (): string[] => {
        if (!this.state.data) return [];
        let currentUses = this.state.data.map(d => d.use || '');
        return currentUses;
    }

    currentlyInUseForPurpose = (useToCheck: string): boolean => {
        let currentUses = this.getListOfUsesCurrentUses();
        return (currentUses.indexOf(useToCheck) >= 0);
    }

    setLocation = (newLocation: { latitude: number, longitude: number }) => {
        if (!newLocation) throw Error('expected new location data')
        let data = this.state.data;
        if (!data) throw new Error('missing data');

        data = data.map(d => {
            // sets the data locations to the current mapView location.
            if (newLocation) {
                d.longitude = newLocation.longitude;
                d.latitude = newLocation.latitude;
            }
            return d;
        })

        this.setState({
            data,
            mapLongitude: newLocation.longitude,
            mapLatitude: newLocation.latitude
        });
    }

    setProperty = (newValues: Partial<IVSpaceProperty>) => {
        if (!newValues) throw Error('expected new location data')
        let data = this.state.data;
        if (!data) throw new Error('missing data');
        data = data.map(d => {
            return { ...d, ...newValues };
        })

        let mapLongitude = this.state.mapLongitude;
        let mapLatitude = this.state.mapLatitude;

        if (newValues.longitude !== undefined && isLongitude(newValues.longitude)) mapLongitude = newValues.longitude;
        if (newValues.latitude !== undefined && isLatitude(newValues.latitude)) mapLatitude = newValues.latitude;

        this.setState({ data, mapLongitude, mapLatitude });
    }

    refresh = async () => {
        this.setState({ pleaseWait: true, lastResponses: [] });
        await this.props.onClickRefresh();
        await delay(100); // wait for parent to render props
        await this.getData();
        this.setState({ pleaseWait: false })
    }

    getProperty = () => {
        if (!this.state.data) throw new Error('Missing property data');
        return this.state.data[0]; // all property values should be the same...
    }

    deleteSite = async () => {
        console.log('delete site...');
        const vspaceapi = new APIVodafoneVSpacesAPI(this.props.token);
        if (!this.state.dataOrig) return;

        let deleteTasks = this.state.dataOrig.map(d => vspaceapi.api.v1.VSpacesDigitwin.RemoveInstance(d));
        let deleteResults = await Promise.all(deleteTasks);
        this.setState({ lastResponses: deleteResults, pleaseWait: false });

        this.props.router?.navigate('/');
    }

    render() {

        // uncomment to reset the siteviewtip
        // localStorageSetBoolean('showSiteViewTip', true);

        if (!this.state.data) return <CircularProgress />
        if (!this.props.columns) return <Alert severity='error'>Missing column data</Alert>
        if (!this.state.data[0]) return <Alert severity='error'>Invalid data</Alert>

        let property = this.getProperty();

        let { rows, columns } = this.prepareGridData();

        if (!rows) return <CircularProgress />
        if (!columns) return <CircularProgress />

        let changes = this.wasThereChanges();

        return (<PageView
            id="vspace_site_view"
            backButton
            title={`${property.instanceName}`}
            caption={'Site View'}
            pleaseWait={this.state.pleaseWait}
            lastResponses={this.state.lastResponses}
            header={<>

                {/* DELETE SITE START */}
                {this.state.editable &&
                    <Button
                        disabled={!this.state.editable}
                        variant="outlined"
                        color="error"
                        sx={{ mr: 1 }}
                        size="small"
                        onClick={() => { this.setState({ showDeleteSiteConfirmDialog: true }) }}
                        startIcon={<DeleteTwoTone />}
                    >
                        DELETE SITE
                    </Button>
                }

                <Dialog
                    open={this.state.showDeleteSiteConfirmDialog}
                    onClose={() => { this.setState({ showDeleteSiteConfirmDialog: false }); }}>
                    <DialogTitle>Delete site?</DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Are you sure you want to delete <strong>{this.getProperty().instanceName}</strong> completely?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            startIcon={<UndoTwoTone />}
                            variant="outlined"
                            onClick={() => { this.setState({ showDeleteSiteConfirmDialog: false }); }}>
                            CANCEL</Button>
                        <Button
                            startIcon={<DeleteTwoTone />}
                            color="error"
                            variant='outlined'
                            onClick={() => { this.deleteSite(); }} autoFocus>
                            CONFIRM DELETE SITE
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* DELETE SITE END */}

                <Button
                    variant="outlined"
                    sx={{ mr: 1 }}
                    size="small"
                    onClick={() => { this.refresh(); }}
                    startIcon={<RefreshTwoTone />}>
                    REFRESH
                </Button>
                <Button
                    startIcon={<HistoryTwoTone />}
                    sx={{ mr: 1 }}
                    component={RouterLink}
                    to={`/history/${property.instanceId}`}
                    variant="outlined" size="small">VIEW HISTORY</Button>
                {this.state.editable && <>
                    <Button
                        disabled={!changes}
                        startIcon={<UndoTwoTone />}
                        sx={{ mr: 1 }}
                        variant="outlined" size="small" onClick={() => {
                            this.undoChanges();
                        }}>UNDO</Button>
                    <Button
                        disabled={!changes}
                        startIcon={<SaveTwoTone />}
                        variant="outlined" size="small" onClick={() => {
                            this.saveChanges();
                        }}>SAVE CHANGES</Button>
                </>}
            </>}
        >


            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <Paper elevation={5} sx={{ width: 400, display: 'flex', flexDirection: 'column', p: 0 }}>
                    <Box sx={{ height: 300 }}>
                        <Tooltip placement="right"
                            arrow
                            title={this.state.editable ? "Drag or scroll to explore. Click anywhere on the map to update property location." : "Shows the location of this site."}
                        >
                            <Box
                                sx={{
                                    m: 0, p: 0,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden',
                                }}>

                                {/* <Box sx={{
                                m: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                p: 1
                            }}>
                                <Button
                                    sx={{ mb: 0.25 }}
                                    variant='outlined'
                                    size="small"
                                    onClick={() => {
                                        if (!this.state.data) throw new Error('missing data');
                                        this.setState({
                                            mapLatitude: this.getLatLong(this.state.data).latitude,
                                            mapLongitude: this.getLatLong(this.state.data).longitude
                                        })
                                    }}>Center View</Button>
                            </Box> */}

                                <Maps<IVSpaceProperty>
                                    mapboxApiAccessToken={'pk.eyJ1IjoiaW90bnh0IiwiYSI6ImNsMXE0YXFoZjExNjYzZXBkY3l1Mms5NzEifQ._1ILYjgYE380E5YsUGGoiw'}
                                    height={300}
                                    showCluster={false}
                                    disabled={!this.state.editable}
                                    enableMapStyleToggle={true}
                                    // mapStyleToggle={this.state.mapStyleToggle}
                                    onClick={(info) => {

                                        if (!this.state.editable) return; // do nothing on clicks

                                        if (info.picked === false && info.coordinate) {
                                            let location = { latitude: info.coordinate[1], longitude: info.coordinate[0] }
                                            this.setLocation(location);
                                            this.getMapboxLocation(location);
                                        }
                                    }}
                                    onHover={(mapHoverItem) => {
                                        // this.setState({ mapHoverItem });
                                    }}
                                    initialViewState={{
                                        longitude: this.state.mapLongitude,
                                        latitude: this.state.mapLatitude,
                                        zoom: this.state.mapZoom,
                                        pitch: 0,
                                        bearing: 0
                                    }}
                                    listOfNodes={[this.getCoordinates(this.state.data)]}
                                    onViewStateChange={(data) => {
                                        if (!this.state.editable) return; // do nothing on clicks

                                        if (data.viewState) {
                                            this.setState({
                                                mapViewState: data.viewState,
                                                mapZoom: data.viewState.zoom,
                                                mapLatitude: data.viewState.latitude,
                                                mapLongitude: data.viewState.longitude
                                            });
                                        }
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box>

                        <Box sx={{ p: 2, pt: 1 }}>
                            {!this.state.mapboxReverseResult && <LinearProgress />}
                            {this.state.mapboxReverseResult && this.state.mapboxReverseResult.features.length === 0 && <Typography variant="caption">Could not look up location data.</Typography>}
                            {this.state.mapboxReverseResult && this.state.mapboxReverseResult.features && this.state.mapboxReverseResult.features[0] &&
                                <Tooltip arrow title="Reverse lat/long lookup for this location.">
                                    <Typography variant="caption">{this.state.mapboxReverseResult?.features[0].place_name}</Typography>
                                </Tooltip>
                            }
                        </Box>



                        <Box sx={{ mt: 0.5, p: 0.5 }}>
                            <Grid container spacing={1} columns={{ xs: 2 }}>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Latitude'
                                        value={property.latitude}
                                        type="latitude"
                                        onChange={(e) => {
                                            let latitude = parseFloat(e.target.value);
                                            if (latitude && !isNaN(latitude) && e.target.value) this.setProperty({ latitude })
                                        }} />
                                </Grid>
                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Longitude'
                                        value={property.longitude}
                                        type="longitude"
                                        onChange={(e) => {
                                            let longitude = parseFloat(e.target.value);
                                            if (longitude && !isNaN(longitude) && e.target.value) this.setProperty({ longitude })
                                        }} />
                                </Grid>

                                <Grid item xs={2}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Name'
                                        value={property.instanceName}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='City'
                                        value={property.city}
                                        onChange={(e) => { this.setProperty({ city: e.target.value }); }}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Country'
                                        value={property.country}
                                        onChange={(e) => { this.setProperty({ country: e.target.value }); }}
                                        valueOptions={Countries.map(c => c.name)}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Region'
                                        value={property.region}
                                        onChange={(e) => { this.setProperty({ region: e.target.value }); }}
                                        valueOptions={Regions.map(r => r.name)}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Building Area'
                                        value={property.buildingArea || 0}
                                        unit="m2"
                                        onChange={(e) => { this.setProperty({ buildingArea: e.target.value }) }}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Asset ID'
                                        value={property.assetId}
                                        onChange={(e) => { this.setProperty({ assetId: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Currency'
                                        value={property.currency}
                                        valueOptions={_.uniq(Countries.map(c => c.currency.code)).sort((a, b) => a > b ? 1 : -1)}
                                        onChange={(e) => { this.setProperty({ currency: e.target.value }); }}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Number of buildings'
                                        value={property.numberOfBuildings}
                                        onChange={(e) => { this.setProperty({ numberOfBuildings: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <WidgetText
                                        disabled={!this.state.editable}
                                        title='Freehold buildings'
                                        value={property.freeHoldBuildings}
                                        onChange={(e) => { this.setProperty({ freeHoldBuildings: e.target.value }) }}
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <WidgetText title='Grand Total Cost'
                                        disabled={!this.state.editable}
                                        value={formatCurrency(
                                            this.getTotalOnProperty("calculatedTotalCost"),
                                            property.currency)} />
                                </Grid>

                                {/* <Grid item xs={2}>
                                    <WidgetText
                                        title='Area required to meet group target (Assigned users x 10)'
                                        unit="m²"
                                        value={this.getTotalOnProperty("calculatedAreaRequiredToMeetGroupTarget")}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <WidgetText
                                        title='Grand Total cost of surplus area p.a'
                                        error={property.utilizedArea === 0}
                                        helperText={(property.utilizedArea === 0) ? 'Missing utilized area data' : undefined}
                                        value={formatCurrency(
                                            this.getTotalOnProperty("calculatedCostOfSurplusAreaPA"),
                                            property.currency)}
                                    />
                                </Grid> */}

                            </Grid>
                        </Box>

                    </Box>

                </Paper>






                <Box sx={{ flex: 1, m: 2, mt: 0 }}>

                    {this.state.editable &&
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                            {this.state.editable ?
                                <Alert severity="info" sx={{ m: 0, p: 0.1, pl: 1, pr: 1 }}>Permissions: view, edit &amp; delete</Alert>
                                : <Alert severity="info" sx={{ m: 0, p: 0.1, pl: 1, pr: 1 }}>Permissions: view only</Alert>
                            }


                            <Box sx={{ flex: 1 }} />
                            <Box>
                                <Paper elevation={0} sx={{ width: 400 }} >
                                    <FormControl sx={{ width: '100%' }} size="small">
                                        <InputLabel id="addPurposeId">Add new purpose</InputLabel>
                                        <Select
                                            labelId="addPurposeId"
                                            value={this.state.newPurposeText}
                                            onChange={(e) => {
                                                // this.setState({ newPurposeText: e.target.value });
                                                this.addUse(e.target.value);
                                            }}
                                            label="Add new purpose"
                                        >
                                            <MenuItem value="" disabled sx={{ display: 'none' }} />
                                            {AllowedUses.map(u => <MenuItem
                                                key={u}
                                                value={u}
                                                disabled={this.currentlyInUseForPurpose(u)}
                                            >{u}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Paper>
                            </Box>
                        </Box>
                    }

                    <Paper elevation={5} sx={{ ...{ mt: 1 }, ...gridStyles }}>

                        {(localStorageGetBoolean('showSiteViewTip', true) && this.state.editable) && <Alert
                            sx={{ m: 0, p: 0.1, pl: 1, pr: 1 }}
                            onClose={() => {
                                localStorageSetBoolean('showSiteViewTip', false);
                                this.setState({});
                            }}
                            severity="info"
                        >Tip: To edit values select a cell and press enter or double click. Use the [Save Changes] button if you are happy with your changes.</Alert>}

                        <DataGridPro<PivotTable<IVSpaceProperty>>
                            density='compact'
                            columns={columns}
                            hideFooter
                            // headerHeight={0}
                            rows={rows}
                            disableColumnReorder
                            showColumnRightBorder={false}
                            disableChildrenFiltering
                            disableChildrenSorting
                            disableColumnFilter
                            disableMultipleSelection
                            disableColumnMenu
                            // disableColumnPinning
                            // disableRowGrouping
                            disableSelectionOnClick
                            autoHeight
                            editMode='cell'
                            // ref={this.gridRef}
                            // experimentalFeatures={{ newEditingApi: true }}
                            isCellEditable={(params) => {

                                if (!this.state.editable) return false;

                                if (params.row.column?.editable === false) return false;
                                return (params.id !== 'action')
                            }}
                            isRowSelectable={(params) => {
                                if (params.row?.column?.editable === false) return false;
                                return (params.id !== 'action')
                            }}
                            onCellEditStart={() => {
                                console.log('Press enter to commit changes')
                            }}
                            // https://mui.com/x/react-data-grid/editing/#events
                            onCellEditCommit={(params: any, event: any) => {
                                if (event.key !== 'Enter') {
                                    let value = parseFloat(params.value);

                                    if (isNaN(value)) {
                                        event.defaultMuiPrevented = true;
                                        return;
                                    }

                                    if (!params.row.column) {
                                        event.defaultMuiPrevented = true;
                                        return;
                                    }

                                    this.updateValue({
                                        use: params.field,
                                        field: params.row.column.field,
                                        value
                                    })
                                }
                            }}

                            onCellEditStop={(params, event: any, details) => {
                                let value = parseFloat(event.target.value);
                                if (isNaN(value)) return;
                                if (!params.row.column) return;
                                this.updateValue({
                                    use: params.field,
                                    field: params.row.column.field,
                                    value
                                })

                            }}
                        />



                    </Paper>



                </Box>
            </Box >

        </PageView>);
    }
}


export default function VSpaceSiteViewRoute(props: VSpaceSiteViewProps) {
    let params = useParams<"instanceId">();
    let location = useLocation();
    let navigate = useNavigate();

    if (!params.instanceId) return <Alert severity="info">Missing instanceId</Alert>

    return <VSpaceSiteView {...props}
        router={{ location, navigate, params }}
    />
}




export function delay(time: number) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(undefined);
        }, time);
    })
}
