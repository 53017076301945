// https://community.iotnxt.com/docs/commander/integration/theming-api/
// by Rouan van der Ende Fri 08 Jul 2022

import { APIIoTnxtThemingServiceAPIs } from "./theming";
import { APIIoTnxtUserPreferenceAPIServiceAPIs } from "./userpreference"
import { APIIoTnxtAuthorizationAPIs } from './authorization'
import { APIIoTnxtAccountManagerAPIs } from "./accountManager";
import { APIIoTnxtDAPIAPIs } from './dapi';
import { APIIoTnxtPolicyServerAPIs } from "./policyServer";
import { INodeSettings } from "../interfaces";

export interface IAPIOptions {
    authorization: string,
    baseUrl: string
    nodeapisettings: INodeSettings
}


/** frontend api calls */
export class APIIoTnxtMain {
    private options: IAPIOptions;

    userpreferences: APIIoTnxtUserPreferenceAPIServiceAPIs
    theming: APIIoTnxtThemingServiceAPIs
    authorization: APIIoTnxtAuthorizationAPIs;
    accountManager: APIIoTnxtAccountManagerAPIs;
    dapi: APIIoTnxtDAPIAPIs;
    policyServer: APIIoTnxtPolicyServerAPIs;

    constructor(options: IAPIOptions) {
        this.options = options;
        this.userpreferences = new APIIoTnxtUserPreferenceAPIServiceAPIs(options);
        this.theming = new APIIoTnxtThemingServiceAPIs(options);
        this.authorization = new APIIoTnxtAuthorizationAPIs(options);
        this.accountManager = new APIIoTnxtAccountManagerAPIs(options);
        this.dapi = new APIIoTnxtDAPIAPIs(options);
        this.policyServer = new APIIoTnxtPolicyServerAPIs(options);
    }

    /** for quick testing. */
    fetch = async (path: string, method: string, body?: string): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}${path}`, {
            method,
            headers: {
                authorization: this.options.authorization,
                "content-type" : "application/json"
            },
            body
        }).then((res) => res.json());
    };
}


