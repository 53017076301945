// from https://mui.com/material-ui/react-avatar/

export function stringToColor(inputstring: string) {

    const commanderColours: any = {
        "--vc-1-light": "#e46835",
        "--vc-1-light-contrast": "#252525",
        "--vc-1-medium": "#cb4e1b",
        "--vc-1-medium-contrast": "#ffffff",
        "--vc-1-dark": "#9e3d15",
        "--vc-1-dark-contrast": "#ffffff",
        "--vc-2-light": "#f7e08e",
        "--vc-2-light-contrast": "#252525",
        "--vc-2-medium": "#f4d35e",
        "--vc-2-medium-contrast": "#252525",
        "--vc-2-dark": "#f1c62e",
        "--vc-2-dark-contrast": "#252525",
        "--vc-3-light": "#00dcd7",
        "--vc-3-light-contrast": "#252525",
        "--vc-3-medium": "#00a9a5",
        "--vc-3-medium-contrast": "#252525",
        "--vc-3-dark": "#007673",
        "--vc-3-dark-contrast": "#ffffff",
        "--vc-4-light": "#1d7fb4",
        "--vc-4-light-contrast": "#000000",
        "--vc-4-medium": "#166088",
        "--vc-4-medium-contrast": "#ffffff",
        "--vc-4-dark": "#0f415c",
        "--vc-4-dark-contrast": "#ffffff",
        "--vc-5-light": "#133393",
        "--vc-5-light-contrast": "#ffffff",
        "--vc-5-medium": "#0d2366",
        "--vc-5-medium-contrast": "#ffffff",
        "--vc-5-dark": "#071339",
        "--vc-5-dark-contrast": "#ffffff",
        "--vc-6-light": "#763aab",
        "--vc-6-light-contrast": "#ffffff",
        "--vc-6-medium": "#5c2d85",
        "--vc-6-medium-contrast": "#ffffff",
        "--vc-6-dark": "#42205f",
        "--vc-6-dark-contrast": "#ffffff",
        "--vc-7-light": "#e632e9",
        "--vc-7-light-contrast": "#000000",
        "--vc-7-medium": "#ce16d2",
        "--vc-7-medium-contrast": "#000000",
        "--vc-7-dark": "#a111a4",
        "--vc-7-dark-contrast": "#ffffff",
        "--vc-8-light": "#cc219f",
        "--vc-8-light-contrast": "#ffffff",
        "--vc-8-medium": "#a01a7d",
        "--vc-8-medium-contrast": "#ffffff",
        "--vc-8-dark": "#74135b",
        "--vc-8-dark-contrast": "#ffffff",
    }

    const _shades: string[] = [
        'light',
        'medium',
        'dark'
    ];

    // let hash = 0;
    // let i;

    // /* eslint-disable no-bitwise */
    // for (i = 0; i < inputstring.length; i += 1) {
    //     hash = inputstring.charCodeAt(i) + ((hash << 5) - hash);
    // }

    // let color = '#';

    // for (i = 0; i < 3; i += 1) {
    //     const value = (hash >> (i * 8)) & 0xff;
    //     color += `00${value.toString(16)}`.slice(-2);
    // }
    // /* eslint-enable no-bitwise */

    // return color;

    const hash: number = stringToHash(inputstring);

    const colourVar: string = `--vc-${Math.round(inputstring.length % 8)}-${_shades[Math.round(Math.abs(hash) % 3)]}`;

    return {
        background: commanderColours[colourVar],
        borderColor: commanderColours[colourVar],
        colour: commanderColours[`${colourVar}-contrast`]
    }

}

export function stringAvatar(name?: string) {




    if (!name) return {};

    if (name?.trim() === '') return {};

    if (name.split(' ').length < 2) return {
        sx: {
            color: (name) ? stringToColor(name).colour : undefined,
            bgcolor: (name) ? stringToColor(name).background : undefined,
            width: 35.22, height: 35.22,
            fontSize: '14px',
            fontWeight: 500
        },
        children: `${name[0]}`
    }

    return {
        sx: {
            color: (name) ? stringToColor(name).colour : undefined,
            bgcolor: (name) ? stringToColor(name).background : undefined,
            width: 35.22, height: 35.22,
            fontSize: '14px',
            fontWeight: 500
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase(),
    };
}


export function stringToHash(value: string): number {
    let hash: number = 0;

    for (let i: number = 0; i < value.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hash;
}
