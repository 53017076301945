import React from "react"
import { isJson, IToken, ITokenDecoded } from "..";
import jwt_decode from 'jwt-decode';

interface ITokenParseState {
    decoded?: ITokenDecoded
}

interface ITokenParseProps {
    property?: keyof ITokenDecoded
    component?: (props: ITokenDecoded) => React.ReactNode
}

export class TokenParse extends React.Component<ITokenParseProps, ITokenParseState> {
    state: ITokenParseState = {
        decoded: getDecodedToken()
    }


    render(): JSX.Element {
        if (!this.state.decoded) return <></>;

        if (this.props.component) {
            let NestedComponent = this.props.component(this.state.decoded);
            // @ts-ignore
            return <NestedComponent />
        }

        if (!this.props.property) return <>error need property</>;

        return <>{this.state.decoded?.[this.props.property] || 'error'}</>
    }
}



export function getDecodedToken(): ITokenDecoded | undefined {
    const token = localStorage.getItem("token");
    if (token && isJson(token)) {
        const parsed: IToken = JSON.parse(token);
        const decoded: ITokenDecoded = jwt_decode(parsed.access_token)
        return decoded;
    }
}