import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';

export const clusterIconMapping = {
    "marker-1": {
      "x": 0,
      "y": 0,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-2": {
      "x": 128,
      "y": 0,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-3": {
      "x": 256,
      "y": 0,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-4": {
      "x": 384,
      "y": 0,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-5": {
      "x": 0,
      "y": 128,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-6": {
      "x": 128,
      "y": 128,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-7": {
      "x": 256,
      "y": 128,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-8": {
      "x": 384,
      "y": 128,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-9": {
      "x": 0,
      "y": 256,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-10": {
      "x": 128,
      "y": 256,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-20": {
      "x": 256,
      "y": 256,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-30": {
      "x": 384,
      "y": 256,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-40": {
      "x": 0,
      "y": 384,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-50": {
      "x": 128,
      "y": 384,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-60": {
      "x": 256,
      "y": 384,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-70": {
      "x": 384,
      "y": 384,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-80": {
      "x": 0,
      "y": 512,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-90": {
      "x": 128,
      "y": 512,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker-100": {
      "x": 256,
      "y": 512,
      "width": 128,
      "height": 128,
      "anchorY": 128
    },
    "marker": {
      "x": 384,
      "y": 512,
      "width": 128,
      "height": 128,
      "anchorY": 128
    }
  }

function getIconName(size: number) {
    if (size === 0) {
        return '';
    }
    if (size < 10) {
        return `marker-${size}`;
    }
    if (size < 100) {
        return `marker-${Math.floor(size / 10)}0`;
    }
    return 'marker-100';
}

function getIconSize(size: number) {
    return Math.min(100, size) / 100 + 1;
}

export class IconClusterLayer<D> extends CompositeLayer<D> {
    shouldUpdateState({ changeFlags }: any) {
        return changeFlags.somethingChanged;
    }

    updateState({ props, oldProps, changeFlags }: any) {
        const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

        if (rebuildIndex) {
            const index = new Supercluster({ maxZoom: 16, radius: props.sizeScale * Math.sqrt(2) });
            index.load(
                props.data.map((d:any) => ({
                    geometry: { coordinates: props.getPosition(d) },
                    properties: d
                }))
            );
            this.setState({ index });
        }

        const z = Math.floor(this.context.viewport.zoom);
        if (rebuildIndex || z !== this.state.z) {
            this.setState({
                data: this.state.index.getClusters([-180, -85, 180, 85], z),
                z
            });
        }
    }

    getPickingInfo({ info, mode }: any) {
        const pickedObject = info.object && info.object.properties;
        if (pickedObject) {
            if (pickedObject.cluster && mode !== 'hover') {
                info.objects = this.state.index
                    .getLeaves(pickedObject.cluster_id, 25)
                    .map((f:any) => f.properties);
            }
            info.object = pickedObject;
        }
        return info;
    }

    renderLayers() {
        const { data } = this.state;
        const { iconAtlas, iconMapping, sizeScale } = this.props as any;

        return new IconLayer(
            this.getSubLayerProps({
                id: 'icon',
                data,
                iconAtlas,
                iconMapping,
                sizeScale,
                getPosition: (d:any) => d.geometry.coordinates,
                getIcon: (d:any) => getIconName(d.properties.cluster ? d.properties.point_count : 1),
                getSize: (d:any) => getIconSize(d.properties.cluster ? d.properties.point_count : 1)
            })
        );
    }
}