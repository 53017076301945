import { IVSpaceProperty } from "./interfaces_vspaces";

export const processInstanceCalculations = (p: IVSpaceProperty): IVSpaceProperty => {
    if (!p.latitude) p.latitude = 0;
    if (!p.longitude) p.longitude = 0;

    // zero nulls
    if (!p.assignedUsers) p.assignedUsers = 0;
    if (!p.utilizedArea) p.utilizedArea = 0;
    if (!p.utilityCost) p.utilityCost = 0;
    if (!p.estateCost) p.estateCost = 0;
    if (!p.facilitiesHardCost) p.facilitiesHardCost = 0;
    if (!p.facilitiesSoftCost) p.facilitiesSoftCost = 0;

    // from spec/chat:
    // --------------------
    // the Total Cost: Utility cost + Estate Cost + Facilities Hard cost + Facilities Soft Cost,
    // Area required to meet group target: Assigned Usersx10 and
    // Cost of Surplus Area p.a=:Property cost – (cost per sqm x F:Area required to meet group target)
    // cost per sqm: Total Property Cost/Utilized Area
    // is for each Purpose. and the values shown under the map view must be the total, similar to the total Cost currently used.

    p.calculatedAreaRequiredToMeetGroupTarget = p.assignedUsers * 10;
    p.calculatedTotalCost = p.utilityCost + p.estateCost + p.facilitiesHardCost + p.facilitiesSoftCost;
    p.calculatedCostPerSQM = p.calculatedTotalCost / p.utilizedArea;

    if (isNaN(p.calculatedCostPerSQM)) p.calculatedCostPerSQM = 0;

    // Calculate Cost of Surplus Area
    const costGroupTarget = (p.calculatedCostPerSQM * p.calculatedAreaRequiredToMeetGroupTarget);

    p.calculatedCostOfSurplusAreaPA = p.calculatedTotalCost - costGroupTarget;
    if (isNaN(p.calculatedCostOfSurplusAreaPA)) p.calculatedCostOfSurplusAreaPA = 0;

    if (!isFinite(p.calculatedCostOfSurplusAreaPA)) p.calculatedCostOfSurplusAreaPA = 0;

    if (!isFinite(p.calculatedCostPerSQM)) p.calculatedCostPerSQM = 0;
    return p;
}


export const removeCalculations = (p: IVSpaceProperty): IVSpaceProperty => {
    let tempcopy: IVSpaceProperty = JSON.parse(JSON.stringify(p));
    delete tempcopy.calculatedAreaRequiredToMeetGroupTarget;
    delete tempcopy.calculatedCostOfSurplusAreaPA;
    delete tempcopy.calculatedCostPerSQM;
    delete tempcopy.calculatedTotalCost;
    delete tempcopy.cacheInsertedDate;
    delete tempcopy.cacheItemState;
    delete tempcopy.cacheLastUpdatedDate;
    return tempcopy;
}


export const isDataDifferent = (incoming: IVSpaceProperty, existing: IVSpaceProperty): boolean => {
    let foundDifference = false;
    Object.keys(incoming).forEach(k => {
        try {
            if (incoming[k as keyof IVSpaceProperty] !== existing[k as keyof IVSpaceProperty]) foundDifference = true;
        } catch (err: any) { console.log(`Error isDataDifferent(). Could not compare key ${k}, Error: ${err.message}`) }
    })

    return foundDifference;
}