// https://community.iotnxt.com/docs/commander/integration/theming-api/
// by Rouan van der Ende Fri 08 Jul 2022

import { IAPIOptions } from "./main"
import { encodeQueryData } from "./util_encodequerydata"

export interface AddThemeResponseDto {
    themeId: string
    rowVersion: string
};

export interface AlarmPrioritySoundsDto {
    accountId?: string
    high?: string
    medium?: string
    low?: string
};

export interface DownloadLogoConfigDto {
    layout: IconLayout
    text?: string
    icon?: string
};

export interface Icon {
    contentType?: string
    content?: string
};

export type IconLayout = "imageWithText" | "image" | "text";

export type IconMode = "light" | "dark";



export interface SetAccountDefaultThemeRequest {
    themeId: string
};

export interface SharedThemeRequest {
    themeId: string
    shareToggle: boolean
};

export interface Theme {
    id: string
    name?: string
    disabled: boolean
    config?: any
    shared: boolean
    locked: boolean
    accountId: string
    rowVersion: string
    appearance: ThemeAppearance
};

export type ThemeAppearance = 0 | 1;

export interface ThemeDto {
    id: string
    name?: string
    disabled: boolean
    config?: any
    shared: boolean
    locked: boolean
    rowVersion: string
    appearance: ThemeAppearance
};

export interface ViewLogoConfigDto {
    layout: IconLayout
    text?: string
    lightIcon: Icon
    darkIcon: Icon
};



export class APIIoTnxtThemingServiceAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }

    private rest_get_v1alarm_priority_sounds = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/alarm-priority-sounds`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_put_v1logo_config = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/logo-config`, {
            method: 'put',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_get_v1logo_config = async (): Promise<ViewLogoConfigDto> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/logo-config`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_get_v1logo_config_mode_ = async (params: { mode: IconMode, }): Promise<DownloadLogoConfigDto> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/logo-config/{mode}?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_get_v1themes = async (params: { excludeDisabled: boolean, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_post_v1themes = async (): Promise<AddThemeResponseDto> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    /** Returns a theme from its id */
    private rest_get_v1themes_id = async (params: { id: string, }): Promise<ThemeDto> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/${params.id}`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_patch_v1themes_id_disable = async (params: { id: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/{id}/disable?${encodeQueryData(params)}`, {
            method: 'patch',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_patch_v1themes_id_enable = async (params: { id: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/{id}/enable?${encodeQueryData(params)}`, {
            method: 'patch',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };



    private rest_post_v1themesaccountdefault = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/account/default`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    private rest_delete_v1themesaccountdefault = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/account/default`, {
            method: 'delete',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_get_v1themes_themeId_load = async (params: { themeId: string, rowVersion: string, }): Promise<ThemeDto> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/{themeId}/load?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_put_v1themes_themeId_ = async (params: { themeId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/{themeId}?${encodeQueryData(params)}`, {
            method: 'put',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_delete_v1themes_themeId_ = async (params: { themeId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/{themeId}?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_post_v1themesshared = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/shared`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    api = {
        alarmPrioritySounds: {
            get: this.rest_get_v1alarm_priority_sounds,
        },
        logoConfig: {
            get: this.rest_get_v1logo_config
        },
        themes: {
            get: this.rest_get_v1themes,
            getById: this.rest_get_v1themes_id
        }
    }

    account = {
        default: {
            get: async (): Promise<ThemeDto> => {
                return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/theming/v1/themes/account/default`, {
                    method: 'get',
                    headers: {
                        authorization: this.options.authorization,
                    },
                }).then((res) => res.json());
            }
        }
    };

    
}
