import { AccountCircleRounded, HomeRounded, PersonRounded, ScatterPlotRounded } from '@mui/icons-material';
import {
    Toolbar,
    IconButton,
    Box,
    CssBaseline,
    createTheme,
    ThemeProvider,
    ThemeOptions,
    Divider,
    Button,
    Avatar,
    Typography,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    Chip
} from '@mui/material';

import React from 'react';

import { Outlet, Link as RouterLink } from 'react-router-dom';
import { INodeSettings, IToken } from '../../interfaces';
import { checkSystemDarkMode, DarkModeToggle, TDisplayMode } from './darkModeToggle';
import { ThemeSelector } from './themeSelector';
import { stringAvatar } from '../utils/avatarname'
import { IAppState } from '../../App';
interface IRouterWrapperState {
    menuOpen: boolean
    displayMode: TDisplayMode
    darkMode?: boolean
    theme?: ThemeOptions
    menuAnchor?: any
}

interface IRouterWrapperProps {
    token: IToken;
    appState: IAppState;
    nodesettings: INodeSettings
}

export default class RouterWrapper extends React.Component<IRouterWrapperProps, IRouterWrapperState> {
    state: IRouterWrapperState = {
        menuOpen: false,
        displayMode: 'system',
        darkMode: checkSystemDarkMode()
    }

    componentDidMount = () => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            console.log(e.matches);
            // const newColorScheme = e.matches ? "dark" : "light";
            if (this.state.displayMode === 'system') this.setState({ darkMode: e.matches });
        });
    }

    render() {


        const lightTheme = createTheme({
            palette: {
                background: {
                    default: '#e8e8e8'
                }
            },
        });

        const darkTheme = createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: "#008dcb",
                },
                secondary: {
                    main: "#f78419",
                    contrastText: "rgba(255,255,255,0.87)",
                },
                background: {
                    default: "#272b34",
                    paper: "#272b34",
                },
                text: {
                    primary: "#eeeeee",
                },
            },
        });

        let theme = this.state.darkMode ? darkTheme : lightTheme;
        if (this.state.theme) theme = createTheme(this.state.theme);


        return (<ThemeProvider theme={theme}>
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <CssBaseline />
                <Toolbar variant="dense" sx={{ mt: 1 }}>
                    <IconButton component={RouterLink} to="/">
                        <HomeRounded />
                    </IconButton>

                    {this.props.appState?.simplifiedAccessRules?.isAdmin ? <Typography>ADMIN</Typography> : undefined}
                    <Box sx={{ flexGrow: 1 }} />

                    <Box>
                        {this.props.appState?.entityAccessGroups?.map(e => <Tooltip
                            key={e.id}
                            title={this.props.appState.simplifiedAccessRules?.editList.filter(i => i.entityAccessGroup.id === e.id).map(x => x.entity.name).join(', ') || ''} >
                            <Chip component={Button}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    window.open(`${this.props.nodesettings.NodeSettings.DashboardUrl}/_apps/account-manager/details/entity-access-groups/edit/${e.id}`, '_blank')
                                }}
                                size="small"
                                label={e.name} sx={{ mr: 1, mt: 1, cursor: 'pointer' }} />
                        </Tooltip>)}
                    </Box>

                    <Tooltip title={this.props.appState.profile?.username || ''}>
                        <IconButton size="small" onClick={e => { this.setState({ menuOpen: true, menuAnchor: e.currentTarget }) }}>
                            <Avatar {...stringAvatar(`${this.props.appState.profile?.firstName} ${this.props.appState.profile?.lastName}`)} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                <Menu
                    anchorEl={this.state.menuAnchor}
                    id="account-menu"
                    open={this.state.menuOpen}
                    onClose={() => { this.setState({ menuOpen: false }); }}
                    onClick={() => { this.setState({ menuOpen: false }); }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <MenuItem
                        component={RouterLink}
                        to="/"
                    >
                        <ListItemIcon>
                            <HomeRounded fontSize="small" />
                        </ListItemIcon>
                        Home
                    </MenuItem>

                    <MenuItem
                        component={RouterLink}
                        to="/account"
                    >
                        <ListItemIcon>
                            <AccountCircleRounded fontSize="small" />
                        </ListItemIcon>
                        Account
                    </MenuItem>

                    <MenuItem
                        component={RouterLink}
                        to="/members"
                    >
                        <ListItemIcon>
                            <PersonRounded fontSize="small" />
                        </ListItemIcon>
                        Members
                    </MenuItem>

                    <MenuItem
                        component={RouterLink}
                        to="/entityaccess"
                    >
                        <ListItemIcon>
                            <ScatterPlotRounded fontSize="small" />
                        </ListItemIcon>
                        Entity Access Groups
                    </MenuItem>

                    <Divider orientation='horizontal' />

                    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <DarkModeToggle onChange={(displayMode, darkMode) => { this.setState({ displayMode, darkMode, theme: undefined }); }} />
                        <ThemeSelector token={this.props.token} onThemeChange={(theme) => { this.setState({ theme }) }} />
                    </Box>

                </Menu>

                <Box sx={{
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: "column",
                }}>
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>);
    }
}