import {
    APIIoTnxtMain,
    IDapiEntityAccessGroup,
    IEntity,
    IEntityPathAccess
} from "../commanderapi";
import { INodeSettings } from "../interfaces";

export interface ISimplifiedAccessRules {
    isAdmin: boolean
    viewAll: boolean
    editList: { entity: IEntity, pathAccess: IEntityPathAccess, entityAccessGroup: IDapiEntityAccessGroup }[]
}

export const getInitialData = async (options: {
    authorization: string,
    baseUrl: string,
    baseUrlRobot: string
    nodeapisettings: INodeSettings
}) => {
    const iotnxtAPI = new APIIoTnxtMain({
        authorization: options.authorization,
        baseUrl: options.baseUrl,
        nodeapisettings: options.nodeapisettings
    });

    const iotnxtAPIRobot = new APIIoTnxtMain({
        authorization: options.authorization,
        baseUrl: options.baseUrlRobot,
        nodeapisettings: options.nodeapisettings
    });

    const [profile, groups, entityList] = await Promise.all([
        iotnxtAPI.authorization.profile.get(),
        iotnxtAPIRobot.policyServer.groups(),
        iotnxtAPIRobot.dapi.EntityTree.GetAccountChildren().then(r => r.result)
    ]);

    // this.setState({ profile, groups, entityList });

    /** Which groups this user may access */
    let entityAccessGroups = await iotnxtAPIRobot.dapi.EntityAccess.GetEntityAccessGroups({ username: profile.username }).then(r => r.result);

    if (entityAccessGroups === null) entityAccessGroups = [];

    let entityAccessGroupData = await Promise.all(entityAccessGroups.map(entityAccessGroup => iotnxtAPIRobot.dapi.EntityAccess.GetPathAccess({
        path: `/${options.nodeapisettings.DigitwinClientOptions.DefaultParentId}`,
        recursive: true,
        sidType: "groupId",
        sid: entityAccessGroup.id
    }).then(r =>
        ({ entityPathAccess: r.result, entityAccessGroup })
    )));

    /// SIMPLIFIED ACCESS RULES START
    let simplifiedAccessRules: ISimplifiedAccessRules = {
        isAdmin: entityAccessGroups.filter(group => group.name === "VS Admin").length > 0,
        viewAll: true,
        editList: []
    };

    entityAccessGroupData.forEach(eagd => eagd.entityPathAccess.filter(pathAccess => pathAccess.grant === 'allowed')
        .forEach(
            pathAccess => {
                entityList.filter(e => pathAccess.path.endsWith(e.entityId)).forEach(entity => {
                    // TODO check for duplicates first?
                    simplifiedAccessRules.editList.push({
                        entityAccessGroup: eagd.entityAccessGroup,
                        pathAccess,
                        entity
                    })
                })
            }));

    let userPermissionEntities = simplifiedAccessRules.editList.map(x => x.entity.name);
    /// SIMPLIFIED ACCESS RULES DONE
    // this.setState({ entityAccessGroups, entityAccessGroupData, simplifiedAccessRules, userPermissionEntities });
    return { profile, groups, entityList, entityAccessGroups, entityAccessGroupData, simplifiedAccessRules, userPermissionEntities };
}