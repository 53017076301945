
import { encodeQueryData } from '../encodeQueryData'

export function authSPA(authentication: {
    scope: string
    client_id: string
    server?: string
    client_secret?: string
}, options?: { prompt?: string }) {

    localStorage.removeItem('token');
    const { client_id, scope, server } = authentication;
    let prompt: any = 'silent';
    if (options) {
        prompt = options.prompt;
    }
    const payload = {
        response_type: "token",
        client_id,
        redirect_uri: new URL("", window.location.origin).toString(),
        scope,
        state: JSON.stringify({
            pathname: window.location.pathname,
            search: window.location.search,
        }),
        prompt
    };

    window.location.replace(`${server}/auth/connect/authorize?${encodeQueryData(payload)}`);
}