import * as React from 'react';

import {
    Typography,
    Box,
    TextField,
    Paper,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { isLatitude, isLongitude } from '../maps';



interface WidgetTextProps {
    title: string
    value?: any
    type?: 'latitude' | 'longitude'
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<any>) => void
    valueOptions?: string[]
    error?: boolean
    helperText?: string
    unit?: string
    disabled?: boolean
}

interface WidgetTextState {
    value: any
    origValue: any
}

export class WidgetText extends React.Component<WidgetTextProps, WidgetTextState> {
    state = {
        origValue: this.props.value,
        value: this.props.value
    }

    componentWillReceiveProps = (nextProps: WidgetTextProps) => {

        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            if (nextProps.value !== this.state.origValue) this.setState({ value: nextProps.value, origValue: nextProps.value })
        }
    }


    onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<any>) => {

        if (this.props.onChange === undefined) return;

        this.setState({ value: e.target.value })

        if (!this.props.onChange) return;

        if (this.props.type === 'latitude' && isLatitude(parseFloat(e.target.value))) {
            this.props.onChange(e);
            return;
        }

        if (this.props.type === 'longitude' && isLongitude(parseFloat(e.target.value))) {
            this.props.onChange(e);
            return;
        }

        this.props.onChange(e);
    }

    renderValue = () => {

        if ((this.props.onChange === undefined) || (this.props.disabled)) return <>
            <Typography sx={{ p: 1, color: 'primary.main',  display:'inline'}}>{this.state.value}
            </Typography>
            {this.props.unit && <Typography sx={{ display:'inline', opacity: 0.5 }}>
                {this.props.unit}
            </Typography>}
        </>

        if (this.props.valueOptions) return <Select
            disabled={this.props.disabled}
            sx={{ width: '100%' }}
            value={this.state.value}
            size="small"
            onChange={this.onChangeValue}
        >
            <MenuItem value="" disabled sx={{ display: 'none' }} />
            {this.props.valueOptions.map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
        </Select>



        return <TextField
            disabled={this.props.disabled}
            error={this.props.error}
            helperText={this.props.helperText}
            size="small"
            sx={{ width: '100%' }}
            value={this.state.value}
            onChange={this.onChangeValue} />;
    }

    render() {
        return (
            <Paper
                elevation={0}
                sx={{
                    p: 0.5,
                    background: 'rgba(125,125,125,0.05)',

                }}>
                <Box>
                    <Typography variant='caption' sx={{ pl: 0.5 }}>{this.props.title}</Typography>
                </Box>
                <Paper elevation={0}>
                    {this.renderValue()}
                </Paper>
            </Paper>);
    }
}
