import { IToken } from "./itoken";

export function catchToken(): IToken | undefined {

    // try to get token from local storage.
    // if (loadToken()) return loadToken();

    if (window.location.hash.includes("error")) {
        const decoded: any = {};
        for (const y of window.location.hash.slice(1).split("&")) {
            const c = y.split("=");
            decoded[c[0]] = unescape(c[1]);
        }
        if (decoded.error && decoded.error === "login_required") throw new Error(decoded.error);
    }

    if (!window.location.hash.includes("access_token")) return;

    const decoded: any = {};
    for (const y of window.location.hash.slice(1).split("&")) {
        const c = y.split("=");
        decoded[c[0]] = unescape(c[1]);
    }
    let token: IToken = decoded;
    const expires = new Date();
    expires.setTime(new Date().getTime() + (parseInt(token.expires_in)*100));
    token.expires = expires.toISOString();

    // console.log(token);
    localStorage.setItem("token", JSON.stringify(token));

    const state = (token.state) ? JSON.parse(token.state) : { pathname: '/', search: ''};

    /** redirect */
    const uri = `${state.pathname}${state.search}`;
    console.log(`Redirecting to ${uri}`);
    window.location.replace(uri);
    return token;
}
