import * as React from "react";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { AppBar, Button } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

export interface NavBarProps {
  username?: string;
  title: string;
}
export interface NavBarState {
  anchorEl: any;
  mobileMoreAnchorEl: any;
  /** navigate somewhere? */
  to?: string;
}

export class NavBar extends React.Component<NavBarProps, NavBarState> {
  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <Button
              sx={{
                display: { xs: "none", sm: "block" },
              }}
              variant="text"
              color="inherit"
              component={Link}
              to="/"
            >
              {this.props.title}
            </Button>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton
              component={Link}
              to="/account"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
