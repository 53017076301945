import { Brightness5TwoTone, Brightness7TwoTone, SettingsBrightnessTwoTone } from '@mui/icons-material';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import * as React from 'react';


interface DarkModeToggleProps {
    onChange: (displayMode: TDisplayMode, darkMode: boolean) => void
}

interface DarkModeToggleState {
    displayMode: TDisplayMode
}

export type TDisplayMode = "dark" | "system" | "light";

export class DarkModeToggle extends React.Component<DarkModeToggleProps, DarkModeToggleState> {

    state: DarkModeToggleState = {
        displayMode: 'system'
    }



    render() {
        return (
            <>
                <Typography variant="caption">DARK MODE TOGGLE</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={this.state.displayMode}
                    exclusive
                    onChange={(e, displayMode) => {
                        if (displayMode) {
                            this.setState({ displayMode });
                            if (this.props.onChange) {
                                let darkMode = false;
                                if (displayMode === 'dark') darkMode = true;
                                if (displayMode === 'light') darkMode = false;
                                if (displayMode === 'system') darkMode = checkSystemDarkMode();
                                this.props.onChange(displayMode, darkMode);
                            }
                        }
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton value="light" aria-label="lightmode">
                        <Brightness5TwoTone sx={{ mr: 1 }} /> Light
                    </ToggleButton>

                    <ToggleButton value="system" aria-label="system">
                        <SettingsBrightnessTwoTone sx={{ mr: 1 }} /> System
                    </ToggleButton>

                    <ToggleButton value="dark" aria-label="darkmode">
                        <Brightness7TwoTone sx={{ mr: 1 }} /> Dark
                    </ToggleButton>
                </ToggleButtonGroup>
            </>);
    }
}


export const checkSystemDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}