// https://community.iotnxt.com/docs/commander/integration/theming-api/
// by Rouan van der Ende Fri 08 Jul 2022

import { IAPIOptions } from "./main"

export interface IProfile {
    "id": string
    "username": string
    "email": string
    "firstName": string
    "lastName": string
    "phoneNumber": string | null,
    "countryCode": string | null,
    "isEmailVerified": boolean,
    "inviteAccepted": boolean,
    "enabled": boolean,
    "roles": string[]
    "groups": string[]
    "applications": string[]
    "userType": string
}

export interface IRole {
    id: string
    key: string
    seeded: boolean
    includeAllPermissions: boolean
    name: string
    normalizedName: string
    permissions: string[]
    excludedPermissions: string[] | null
    description: string
    accountTypeTags: number[]
    userAccountTypeTags: number[]
}

export interface IAccount {
    accountId: string
    alias: string
    enabled: boolean
    name: string
}

export class APIIoTnxtAuthorizationAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }

    /** get information on the authorized person */
    private get_authorization_profile = async (): Promise<IProfile> => {
        return fetch(`${this.options.baseUrl}/auth/api/v1/authorization/profile`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private get_authorization_profile_accounts = async (): Promise<IAccount[]> => {
        return fetch(`${this.options.baseUrl}/auth/api/v1/authorization/profile/accounts`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private get_authorization_roles = async (): Promise<IRole[]> => {
        return fetch(`${this.options.baseUrl}/auth/api/v1/authorization/roles`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private get_user_groups = async (): Promise<any> => {
        return fetch(`${this.options.baseUrl}/auth/api/v1/authorization/user-groups`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private get_users = async (): Promise<any> => {
        return fetch(`${this.options.baseUrl}/auth/api/v1/authorization/users`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    // API

    profile = {
        get: this.get_authorization_profile,
        accounts: this.get_authorization_profile_accounts
    }
    roles = this.get_authorization_roles
    userGroups = this.get_user_groups;
    users = this.get_users;
}
