
import * as React from 'react';

import {
    Box,
    Grid,
    Paper,
    Select,
    TextField,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Button
} from '@mui/material';

import { Countries, isCountryInRegionCode, Regions } from './countries';
import { listOfOperatingEntities } from './list_operatingEntities';
import { AllowedUses } from './lists_use';
import { CloseTwoTone } from '@mui/icons-material';

interface IFilterCriteriaProps {
    sx?: any
    /** let parent know of filter changes that need to be applied */
    onFilterChange: (options: IFilterCriteriaState) => void
}

export interface IFilterCriteriaState {
    region: string
    country: string
    city: string
    instanceName: string
    operatingEntity: string
    use: string
}

export class FilterCriteria extends React.Component<IFilterCriteriaProps, IFilterCriteriaState> {
    state: IFilterCriteriaState = {
        region: '',
        country: '',
        city: '',
        instanceName: '',
        operatingEntity: '',
        use: ''
    }

    getListOfOperatingEntities = () => {
        return listOfOperatingEntities
            // add list of countries.
            // if a country is selected we add that to the list of operating entities
            // if only a region is selected we add only those countries to the list.
            .concat(this.state.country
                ? [this.state.country]
                : Countries.map(c => c.name).filter(r => this.state.region
                    ? isCountryInRegionCode(r, this.state.region)
                    : true
                ))
    }

    setRegion = async (region: string) => {
        // if the region stays the same do nothing.
        if (region === this.state.region) return;
        // if the region changes we clear the selected country aswell.
        await this.setState({ region, country: '' });
        // if selected operating entity is no longer valid then clear it.
        if (this.getListOfOperatingEntities().indexOf(this.state.operatingEntity) < 0) await this.setState({ operatingEntity: '' });
        // let parent know of filter changes.
        this.props.onFilterChange(this.state);
    }

    setCountry = async (country: string) => {
        // if the country stays the same do nothing.
        if (country === this.state.country) return;
        await this.setState({ country: country });
        // if selected operating entity is no longer valid then clear it.
        if (this.getListOfOperatingEntities().indexOf(this.state.operatingEntity) < 0) await this.setState({ operatingEntity: '' })
        // let parent know of filter changes.
        this.props.onFilterChange(this.state);
    }

    setCityName = async (city: string) => {
        await this.setState({ city });
        this.props.onFilterChange(this.state);
    }

    setOperatingEntity = async (operatingEntity: string) => {
        await this.setState({ operatingEntity });
        this.props.onFilterChange(this.state);
    }

    setUsePurpose = async (use: string) => {
        await this.setState({ use });
        this.props.onFilterChange(this.state);
    }

    setInstanceName = async (instanceName: string) => {
        await this.setState({ instanceName });
        this.props.onFilterChange(this.state);
    }

    renderContent = () => {
        return <Grid container spacing={1} columns={{ xs: 1 }}>
            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <TextField
                    label="Search by Property Name"
                    sx={{ width: '100%' }}
                    value={this.state.instanceName}
                    onChange={async e => { this.setInstanceName(e.target.value); }}
                />
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={async e => { this.setInstanceName(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>


            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <FormControl fullWidth>
                    <InputLabel id="regionDropDown">Region</InputLabel>
                    <Select
                        labelId="regionDropDown"
                        id="regionDropDownSelect"
                        value={this.state.region}
                        label="Region"
                        onChange={async e => { this.setRegion(e.target.value); }}
                    >
                        {Regions.map(r => <MenuItem key={r.code} value={r.code}>{r.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={e => { this.setRegion(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.country}
                        label="Country"
                        onChange={async e => { this.setCountry(e.target.value); }}
                    >
                        {Countries.filter(c => (this.state.region ? (c.region === this.state.region) : true)).map(c => c.name).map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={e => { this.setCountry(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <TextField
                    label="City Name"
                    sx={{ width: '100%' }}
                    value={this.state.city}
                    onChange={async e => { this.setCityName(e.target.value); }}
                />
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={e => { this.setCityName(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <FormControl fullWidth>
                    <InputLabel id="operatingEntityDropdown">Operating Entity</InputLabel>
                    <Select
                        labelId="operatingEntityDropdown"
                        id="operatingEntityDropdownSelect"
                        value={this.state.operatingEntity}
                        label="Operating Entity"
                        onChange={async e => { this.setOperatingEntity(e.target.value); }}
                    >
                        {this.getListOfOperatingEntities().map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={e => { this.setOperatingEntity(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                <FormControl fullWidth>
                    <InputLabel id="usePurposeDropDown">Use/Purpose</InputLabel>
                    <Select
                        labelId="usePurposeDropDown"
                        id="usePurposeDropDownSelect"
                        value={this.state.use}
                        label="Use/Purpose"
                        onChange={async e => { this.setUsePurpose(e.target.value); }}
                    >
                        {AllowedUses.map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
                    </Select>
                </FormControl>
                <Box sx={{ pt: 1.5, pl: 0.5 }}>
                    <IconButton color="primary" size="small" onClick={async e => { this.setUsePurpose(''); }}>
                        <CloseTwoTone />
                    </IconButton>
                </Box>
            </Grid>



        </Grid>
    }

    render() {
        return (<Box sx={this.props.sx} >
            <Box>
                <Paper elevation={0} sx={{ p: 1, height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
                        <Typography variant='caption' sx={{ pt: 1 }}>Filter Criteria</Typography>
                        <Box sx={{ flex: 1 }} />
                        <Button startIcon={<CloseTwoTone />} variant="outlined" size="small"
                            onClick={async e => {
                                await this.setState({
                                    region: '',
                                    country: '',
                                    city: '',
                                    instanceName: '',
                                    operatingEntity: '',
                                    use: ''
                                });
                                this.props.onFilterChange(this.state);
                            }}
                        >Clear All</Button>
                    </Box>

                    {this.renderContent()}
                </Paper>
            </Box>
        </Box>);
    }
}
