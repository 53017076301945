import { Box, Button, Chip, CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { APIIoTnxtMain, IUsername, IDapiEntityAccessGroup } from "../../commanderapi";

import {
    DataGridPro,
    LicenseInfo} from '@mui/x-data-grid-pro';
import { LinkRounded } from "@mui/icons-material";
import { config } from '../../App'
import { INodeSettings } from "../../interfaces";

LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

interface Props {
    authorization: string
    nodesettings: INodeSettings
}

interface State {
    // profile?: IProfile
    // groups?: IGroup[]
    owners?: IUsername[],
    members?: IUsername[]
    rows?: any[]
}

interface IVSpaceUser extends IUsername {
    id: string,
    username: string,
    accountType: 'member' | 'owner',
    entityAccessGroups?: IDapiEntityAccessGroup[]
}

export class UsersDisplay extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = () => { this.getDataAsync(); }

    getDataAsync = async () => {

        // const iotnxtAPI = new APIIoTnxtMain({
        //     authorization: this.props.authorization,
        //     baseUrl: config.baseUrl
        // });

        const iotnxtAPIRobot = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrlRobot,
            nodeapisettings: this.props.nodesettings
        });

        const [members, owners] = await Promise.all([
            iotnxtAPIRobot.accountManager.account.members(),
            iotnxtAPIRobot.accountManager.account.owners()
        ]);

        let rows: IVSpaceUser[] = [];

        members.forEach(m => {
            rows.push({
                id: m.id,
                username: m.username,
                accountType: 'member'
            })
        })

        owners.forEach(o => {
            rows.push({
                id: o.id,
                username: o.username,
                accountType: 'owner'
            })
        })

        await Promise.all(rows.map(user => iotnxtAPIRobot.dapi.EntityAccess.GetEntityAccessGroups({ username: user.username }).then(entityResult => {
            user.entityAccessGroups = entityResult.result
        })));

        // console.log({ rows })

        this.setState({ members, owners, rows });
    }

    render() {
        // if (!this.state.users) return <CircularProgress />

        // let p = this.state.users;

        if (!this.state.members || !this.state.owners) return <CircularProgress />

        if (!this.state.rows) return <CircularProgress />

        return <Paper elevation={10} sx={{ m: 2, p: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h4" sx={{ p: 2 }}>Members</Typography>
                <Box sx={{ mt: 2 }}>
                    <Button variant="outlined" 
                    target="_blank"
                    href={`${this.props.nodesettings.NodeSettings.DashboardUrl}/_apps/account-manager/details/members`}>
                        Edit Members
                    </Button>
                </Box>
                <Box sx={{ flex: 1 }} />
            </Box>

            <DataGridPro<IVSpaceUser>
                rows={this.state.rows}
                density="compact"
                sx={{ height: 500 }}
                pageSize={10}
                columns={[
                    {
                        field: 'id',
                        headerName: '',
                        renderCell: (props => <IconButton
                            size="small"
                            color="primary"
                            target="_blank"
                            href={`${this.props.nodesettings.NodeSettings.DashboardUrl}/_apps/account-manager/details/members/${props.row.id}`}>
                            <LinkRounded />
                        </IconButton>)
                    },
                    {
                        field: 'username', width: 400,
                    },
                    { field: 'accountType' },
                    {
                        field: 'entityAccessGroups',
                        flex: 1,
                        renderCell: (props) => (props.row.entityAccessGroups && (props.row.entityAccessGroups.length > 0))
                            ? props.row.entityAccessGroups.map(e => <Chip size="small" key={e.id} label={e.name} sx={{ mr: 1 }} />)
                            : <Typography sx={{ opacity: 0.5 }}>none</Typography>
                    }
                ]}
            />
        </Paper>
    }
}