// https://community.iotnxt.com/docs/commander/integration/theming-api/
// by Rouan van der Ende Fri 08 Jul 2022

import { IProfile } from "./authorization";
import { IAPIOptions } from "./main"


export class APIIoTnxtPolicyServerAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }

    account = {
        users: {
            getById: async (params: { userid: string }): Promise<IProfile> => {
                return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/policy-server/v2/account/users/${params.userid}`, {
                    method: 'get',
                    headers: {
                        authorization: this.options.authorization,
                        "content-type": "application/json"
                    },
                }).then((res) => res.json());
            }
        }
    }

    groups = async (): Promise<IGroup[]> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/policy-server/v2/groups`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
                "content-type": "application/json"
            },
        }).then((res) => res.json());
    }
}


export interface IGroup {
    roles: string[]
    applications: string[]
    id:string
    displayName:string
    description: string | null
    active: boolean
    createdAt: string
    createdBy: IDisplay,
    updatedAt: string | null
    updatedBy: IDisplay | null
    users: IDisplay[]
    userCount: number
}

export interface IDisplay {
    id: string
    display: string
}