import { Box, IconButton, Skeleton, ThemeOptions, Typography } from '@mui/material';
import * as React from 'react';
import { APIVodafoneVSpacesAPI } from '../../vspace/api';
import { ITheme, IThemeResponse } from '../../vspace/interfaces';
import { IToken } from '../utils';

import {
    DataGridPro,
    LicenseInfo
} from '@mui/x-data-grid-pro';
import { CheckTwoTone } from '@mui/icons-material';

LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

interface ThemeSelectorProps {
    token: IToken;
    onThemeChange: (theme: ThemeOptions) => void
}

interface ThemeSelectorState {
    themeData?: IThemeResponse
    activeTheme?: ITheme
}

export type TDisplayMode = "dark" | "system" | "light";



export class ThemeSelector extends React.Component<ThemeSelectorProps, ThemeSelectorState> {


    state: ThemeSelectorState = {

    }

    api = new APIVodafoneVSpacesAPI();

    componentDidMount = () => {
        this.api.authorization = `${this.props.token.token_type} ${this.props.token.access_token}`;
        this.getTheme();
    }

    getTheme = async () => {
        let response = await this.api.api.v1.VSpacesFrontend.GetThemeProperties();
        if (!response.data) return;
        let activeTheme = response.data.themes[response.data.activeThemeName];
        console.log({ activeTheme });
        this.setState({ activeTheme, themeData: response.data })
    }

    setTheme = async (activeThemeName: string) => {
        let themeData = this.state.themeData;
        if (!themeData) return;
        themeData.activeThemeName = activeThemeName;
        this.setState({ themeData });

        let selectedTheme = themeData.themes[activeThemeName];

        let theme: ThemeOptions = {
            palette: {
                mode: checkIfColourIsLight(selectedTheme.mainContentBackgroundColour) ? 'light' : 'dark',
                primary: {
                    main: selectedTheme.widgetContentAccentColour,
                },
                secondary: {
                    main: selectedTheme.rightMenuTabHeaderTextHoverColour,
                    contrastText: selectedTheme.mainContentTextColour,
                },
                background: {
                    default: selectedTheme.mainContentBackgroundColour,
                    paper: selectedTheme.landingPageMainContentBackgroundColour,
                },
                text: {
                    primary: selectedTheme.mainContentTextColour,
                },
            },
        }

        this.props.onThemeChange(theme);
    }

    render() {

        if (!this.state.themeData) return <Skeleton variant="text" width={'100%'} height={118} />

        return (<>
            <Typography variant="caption" sx={{ mt: 2 }}>THEMES</Typography>

            <DataGridPro autoHeight
                hideFooter
                density="compact"
                columns={[{
                    field: 'active',
                    headerName: '',
                    renderCell: (props) => props.value
                        ? <IconButton onClick={() => { this.setTheme(props.row.name); }}>
                            <CheckTwoTone color="success" />
                        </IconButton>
                        : <IconButton onClick={() => { this.setTheme(props.row.name); }}>
                            <CheckTwoTone sx={{ opacity: 0.25 }} />
                        </IconButton>,
                    width: 5
                },
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 120
                }, {
                    field: 'data',
                    headerName: 'Colours',
                    flex: 2,
                    width: 400,
                    renderCell: (props) => <ThemePreview theme={props.row.data} />
                }]}
                rows={Object.entries(this.state.themeData.themes).map(v => {
                    let active = v[0] === this.state.themeData?.activeThemeName;
                    return { active, name: v[0], id: v[0], data: v[1] }
                })}
            />
        </>);
    }
}

export const ThemePreview = (props: { theme: ITheme }) => {
    // let list = ["mainContentBackgroundColour", "mainContentTextColour", "inputBackgroundColour" ];
    let list = Object.keys(props.theme);
    return <Box sx={{ width: '100%', height: 10, display: 'flex', flexDirection: 'row' }}>
        {list.map((p, i) => <Box key={i} sx={{ height: 10, flex: 1, backgroundColor: props.theme[p as keyof ITheme] }} />)}
    </Box>
}


export const checkIfColourIsLight = (rgbastring: string): boolean => {
    try {
        let a = rgbastring.slice(5, -1).split(',').map(i => parseFloat(i))
        if (a[0] > 125) return true;
        if (a[1] > 125) return true;
        if (a[2] > 125) return true;
        return false;
    } catch (err) { return false; }
}