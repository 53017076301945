import _ from "lodash";
import { ViewMapState } from "../components/maps";

export const Regions: IRegion[] = [
    {
        name: "Africa",
        code: "AF",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": 1.3930234973179005,
            "longitude": 19.773710007941038,
            "zoom": 2.5690983522765927,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
    {
        name: "North America",
        code: "NA",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": 37.06932401238583,
            "longitude": -94.68383665264315,
            "zoom": 3.3252516818875097,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
    {
        name: "Oceania",
        code: "OC",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": -16.972589130226204,
            "longitude": 133.0997625317953,
            "zoom": 2.6071332441576116,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
    // {
    //     name: "Antarctica",
    //     code: "AN",
    //     viewState: {}
    // },
    {
        name: "Asia",
        code: "AS",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": 39.796873957087115,
            "longitude": 93.0164181819775,
            "zoom": 2.587711030909388,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
    {
        name: "Europe",
        code: "EU",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": 54.90519073264755,
            "longitude": 11.072373252058009,
            "zoom": 2.7492758793569694,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
    {
        name: "South America",
        code: "SA",
        viewState: {
            "width": 1058,
            "height": 678,
            "latitude": -26.99973966845697,
            "longitude": -64.5372811200609,
            "zoom": 2.354428233693936,
            "bearing": 0,
            "pitch": 0,
            "altitude": 1.5,
            "maxZoom": 20,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "position": [
                0,
                0,
                0
            ]
        }
    },
];

export interface IRegion {
    name: string
    code: string
    viewState: ViewMapState
}

export function regionCodeToName(code: string) {
    let z = Regions.filter(r => r.code === code)
    if (!z[0]) return code;
    return z[0].name;
}

export function isCountryInRegionCode(country: string, regionCode: string) {
    let find = Countries
        .filter(c => c.name === country)
        .filter(c => c.region === regionCode)

    return (find.length > 0)
}

/** Returns an array of currency codes AFN, ZAR, USD etc.. */
export function listOfCurrencies() {
    return _.uniq(Countries.map(c => c.currency.code)).sort((a, b) => a > b ? 1 : -1);
}

export const Countries = [
    {
        "name": "Afghanistan",
        "code": "AF",
        "capital": "Kabul",
        "region": "AS",
        "currency": {
            "code": "AFN",
            "name": "Afghan afghani",
            "symbol": "؋"
        },
        "language": {
            "code": "ps",
            "name": "Pashto",
        },
        "flag": "https://restcountries.eu/data/afg.svg",
    },
    {
        "name": "Åland Islands",
        "code": "AX",
        "capital": "Mariehamn",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "sv",
            "name": "Swedish",
        },
        "flag": "https://restcountries.eu/data/ala.svg",
    },
    {
        "name": "Albania",
        "code": "AL",
        "capital": "Tirana",
        "region": "EU",
        "currency": {
            "code": "ALL",
            "name": "Albanian lek",
            "symbol": "L"
        },
        "language": {
            "code": "sq",
            "name": "Albanian",
        },
        "flag": "https://restcountries.eu/data/alb.svg",
    },
    {
        "name": "Algeria",
        "code": "DZ",
        "capital": "Algiers",
        "region": "AF",
        "currency": {
            "code": "DZD",
            "name": "Algerian dinar",
            "symbol": "د.ج"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/dza.svg",
    },
    {
        "name": "American Samoa",
        "code": "AS",
        "capital": "Pago Pago",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United State Dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/asm.svg",
    },
    {
        "name": "Andorra",
        "code": "AD",
        "capital": "Andorra la Vella",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "ca",
            "name": "Catalan",
        },
        "flag": "https://restcountries.eu/data/and.svg",
    },
    {
        "name": "Angola",
        "code": "AO",
        "capital": "Luanda",
        "region": "AF",
        "currency": {
            "code": "AOA",
            "name": "Angolan kwanza",
            "symbol": "Kz"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/ago.svg",
    },
    {
        "name": "Anguilla",
        "code": "AI",
        "capital": "The Valley",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/aia.svg",
    },
    {
        "name": "Antigua and Barbuda",
        "code": "AG",
        "capital": "Saint John's",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/atg.svg",
    },
    {
        "name": "Argentina",
        "code": "AR",
        "capital": "Buenos Aires",
        "region": "SA",
        "currency": {
            "code": "ARS",
            "name": "Argentine peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/arg.svg",
    },
    {
        "name": "Armenia",
        "code": "AM",
        "capital": "Yerevan",
        "region": "AS",
        "currency": {
            "code": "AMD",
            "name": "Armenian dram",
            "symbol": null
        },
        "language": {
            "code": "hy",
            "name": "Armenian",
        },
        "flag": "https://restcountries.eu/data/arm.svg",
    },
    {
        "name": "Aruba",
        "code": "AW",
        "capital": "Oranjestad",
        "region": "SA",
        "currency": {
            "code": "AWG",
            "name": "Aruban florin",
            "symbol": "ƒ"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/abw.svg",
    },
    {
        "name": "Australia",
        "code": "AU",
        "capital": "Canberra",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/aus.svg",
    },
    {
        "name": "Austria",
        "code": "AT",
        "capital": "Vienna",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "de",
            "name": "German",
        },
        "flag": "https://restcountries.eu/data/aut.svg",
    },
    {
        "name": "Azerbaijan",
        "code": "AZ",
        "capital": "Baku",
        "region": "AS",
        "currency": {
            "code": "AZN",
            "name": "Azerbaijani manat",
            "symbol": null
        },
        "language": {
            "code": "az",
            "name": "Azerbaijani",
        },
        "flag": "https://restcountries.eu/data/aze.svg",
    },
    {
        "name": "Bahamas",
        "code": "BS",
        "capital": "Nassau",
        "region": "NA",
        "currency": {
            "code": "BSD",
            "name": "Bahamian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/bhs.svg",
    },
    {
        "name": "Bahrain",
        "code": "BH",
        "capital": "Manama",
        "region": "AS",
        "currency": {
            "code": "BHD",
            "name": "Bahraini dinar",
            "symbol": ".د.ب"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/bhr.svg",
    },
    {
        "name": "Bangladesh",
        "code": "BD",
        "capital": "Dhaka",
        "region": "AS",
        "currency": {
            "code": "BDT",
            "name": "Bangladeshi taka",
            "symbol": "৳"
        },
        "language": {
            "code": "bn",
            "name": "Bengali",
        },
        "flag": "https://restcountries.eu/data/bgd.svg",
    },
    {
        "name": "Barbados",
        "code": "BB",
        "capital": "Bridgetown",
        "region": "NA",
        "currency": {
            "code": "BBD",
            "name": "Barbadian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/brb.svg",
    },
    {
        "name": "Belarus",
        "code": "BY",
        "capital": "Minsk",
        "region": "EU",
        "currency": {
            "code": "BYN",
            "name": "New Belarusian ruble",
            "symbol": "Br"
        },
        "language": {
            "code": "be",
            "name": "Belarusian",
        },
        "flag": "https://restcountries.eu/data/blr.svg",
    },
    {
        "name": "Belgium",
        "code": "BE",
        "capital": "Brussels",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/bel.svg",
    },
    {
        "name": "Belize",
        "code": "BZ",
        "capital": "Belmopan",
        "region": "NA",
        "currency": {
            "code": "BZD",
            "name": "Belize dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/blz.svg",
    },
    {
        "name": "Benin",
        "code": "BJ",
        "capital": "Porto-Novo",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/ben.svg",
    },
    {
        "name": "Bermuda",
        "code": "BM",
        "capital": "Hamilton",
        "region": "NA",
        "currency": {
            "code": "BMD",
            "name": "Bermudian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/bmu.svg",
    },
    {
        "name": "Bhutan",
        "code": "BT",
        "capital": "Thimphu",
        "region": "AS",
        "currency": {
            "code": "BTN",
            "name": "Bhutanese ngultrum",
            "symbol": "Nu."
        },
        "language": {
            "code": "dz",
            "name": "Dzongkha",
        },
        "flag": "https://restcountries.eu/data/btn.svg",
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "code": "BO",
        "capital": "Sucre",
        "region": "SA",
        "currency": {
            "code": "BOB",
            "name": "Bolivian boliviano",
            "symbol": "Bs."
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/bol.svg",
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "code": "BQ",
        "capital": "Kralendijk",
        "region": "SA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/bes.svg",
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "BA",
        "capital": "Sarajevo",
        "region": "EU",
        "currency": {
            "code": "BAM",
            "name": "Bosnia and Herzegovina convertible mark",
            "symbol": null
        },
        "language": {
            "code": "bs",
            "name": "Bosnian",
        },
        "flag": "https://restcountries.eu/data/bih.svg",
    },
    {
        "name": "Botswana",
        "code": "BW",
        "capital": "Gaborone",
        "region": "AF",
        "currency": {
            "code": "BWP",
            "name": "Botswana pula",
            "symbol": "P"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/bwa.svg",
    },
    {
        "name": "Bouvet Island",
        "code": "BV",
        "capital": "",
        "region": "AN",
        "currency": {
            "code": "NOK",
            "name": "Norwegian krone",
            "symbol": "kr"
        },
        "language": {
            "code": "no",
            "name": "Norwegian",
        },
        "flag": "https://restcountries.eu/data/bvt.svg",
    },
    {
        "name": "Brazil",
        "code": "BR",
        "capital": "Brasília",
        "region": "SA",
        "currency": {
            "code": "BRL",
            "name": "Brazilian real",
            "symbol": "R$"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/bra.svg",
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "capital": "Diego Garcia",
        "region": "AF",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/iot.svg",
    },
    {
        "name": "United States Minor Outlying Islands",
        "code": "UM",
        "capital": "",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States Dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/umi.svg",
    },
    {
        "name": "Virgin Islands (British)",
        "code": "VG",
        "capital": "Road Town",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/vgb.svg",
    },
    {
        "name": "Virgin Islands (U.S.)",
        "code": "VI",
        "capital": "Charlotte Amalie",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/vir.svg",
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN",
        "capital": "Bandar Seri Begawan",
        "region": "AS",
        "currency": {
            "code": "BND",
            "name": "Brunei dollar",
            "symbol": "$"
        },
        "language": {
            "code": "ms",
            "name": "Malay",
        },
        "flag": "https://restcountries.eu/data/brn.svg",
    },
    {
        "name": "Bulgaria",
        "code": "BG",
        "capital": "Sofia",
        "region": "EU",
        "currency": {
            "code": "BGN",
            "name": "Bulgarian lev",
            "symbol": "лв"
        },
        "language": {
            "code": "bg",
            "name": "Bulgarian",
        },
        "flag": "https://restcountries.eu/data/bgr.svg",
    },
    {
        "name": "Burkina Faso",
        "code": "BF",
        "capital": "Ouagadougou",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/bfa.svg",
    },
    {
        "name": "Burundi",
        "code": "BI",
        "capital": "Bujumbura",
        "region": "AF",
        "currency": {
            "code": "BIF",
            "name": "Burundian franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/bdi.svg",
    },
    {
        "name": "Cambodia",
        "code": "KH",
        "capital": "Phnom Penh",
        "region": "AS",
        "currency": {
            "code": "KHR",
            "name": "Cambodian riel",
            "symbol": "៛"
        },
        "language": {
            "code": "km",
            "name": "Khmer",
        },
        "flag": "https://restcountries.eu/data/khm.svg",
    },
    {
        "name": "Cameroon",
        "code": "CM",
        "capital": "Yaoundé",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/cmr.svg",
    },
    {
        "name": "Canada",
        "code": "CA",
        "capital": "Ottawa",
        "region": "NA",
        "currency": {
            "code": "CAD",
            "name": "Canadian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/can.svg",
    },
    {
        "name": "Cabo Verde",
        "code": "CV",
        "capital": "Praia",
        "region": "AF",
        "currency": {
            "code": "CVE",
            "name": "Cape Verdean escudo",
            "symbol": "Esc"
        },
        "language": {
            "code": "pt",
            "iso639_2": "por",
            "name": "Portuguese",
            "nativeName": "Português"
        },
        "flag": "https://restcountries.eu/data/cpv.svg",
    },
    {
        "name": "Cayman Islands",
        "code": "KY",
        "capital": "George Town",
        "region": "NA",
        "demonym": "Caymanian",
        "currency": {
            "code": "KYD",
            "name": "Cayman Islands dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/cym.svg",
    },
    {
        "name": "Central African Republic",
        "code": "CF",
        "capital": "Bangui",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/caf.svg",
    },
    {
        "name": "Chad",
        "code": "TD",
        "capital": "N'Djamena",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/tcd.svg",
    },
    {
        "name": "Chile",
        "code": "CL",
        "capital": "Santiago",
        "region": "SA",
        "currency": {
            "code": "CLP",
            "name": "Chilean peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "iso639_2": "spa",
            "name": "Spanish",
            "nativeName": "Español"
        },
        "flag": "https://restcountries.eu/data/chl.svg",
    },
    {
        "name": "China",
        "code": "CN",
        "capital": "Beijing",
        "region": "AS",
        "currency": {
            "code": "CNY",
            "name": "Chinese yuan",
            "symbol": "¥"
        },
        "language": {
            "code": "zh",
            "name": "Chinese",
        },
        "flag": "https://restcountries.eu/data/chn.svg",
    },
    {
        "name": "Christmas Island",
        "code": "CX",
        "capital": "Flying Fish Cove",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/cxr.svg",
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "capital": "West Island",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/cck.svg",
    },
    {
        "name": "Colombia",
        "code": "CO",
        "capital": "Bogotá",
        "region": "SA",
        "currency": {
            "code": "COP",
            "name": "Colombian peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/col.svg",
    },
    {
        "name": "Comoros",
        "code": "KM",
        "capital": "Moroni",
        "region": "AF",
        "currency": {
            "code": "KMF",
            "name": "Comorian franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/com.svg",
    },
    {
        "name": "Congo",
        "code": "CG",
        "capital": "Brazzaville",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/cog.svg",
    },
    {
        "name": "Congo (Democratic Republic of the)",
        "code": "CD",
        "capital": "Kinshasa",
        "region": "AF",
        "currency": {
            "code": "CDF",
            "name": "Congolese franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/cod.svg",
    },
    {
        "name": "Cook Islands",
        "code": "CK",
        "capital": "Avarua",
        "region": "OC",
        "currency": {
            "code": "NZD",
            "name": "New Zealand dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/cok.svg",
    },
    {
        "name": "Costa Rica",
        "code": "CR",
        "capital": "San José",
        "region": "NA",
        "currency": {
            "code": "CRC",
            "name": "Costa Rican colón",
            "symbol": "₡"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/cri.svg",
    },
    {
        "name": "Croatia",
        "code": "HR",
        "capital": "Zagreb",
        "region": "EU",
        "currency": {
            "code": "HRK",
            "name": "Croatian kuna",
            "symbol": "kn"
        },
        "language": {
            "code": "hr",
            "name": "Croatian",
        },
        "flag": "https://restcountries.eu/data/hrv.svg",
    },
    {
        "name": "Cuba",
        "code": "CU",
        "capital": "Havana",
        "region": "NA",
        "currency": {
            "code": "CUC",
            "name": "Cuban convertible peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/cub.svg",
    },
    {
        "name": "Curaçao",
        "code": "CW",
        "capital": "Willemstad",
        "region": "SA",
        "currency": {
            "code": "ANG",
            "name": "Netherlands Antillean guilder",
            "symbol": "ƒ"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/cuw.svg",
    },
    {
        "name": "Cyprus",
        "code": "CY",
        "capital": "Nicosia",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "tr",
            "name": "Turkish",
        },
        "flag": "https://restcountries.eu/data/cyp.svg",
    },
    {
        "name": "Czech Republic",
        "code": "CZ",
        "capital": "Prague",
        "region": "EU",
        "currency": {
            "code": "CZK",
            "name": "Czech koruna",
            "symbol": "Kč"
        },
        "language": {
            "code": "cs",
            "name": "Czech",
        },
        "flag": "https://restcountries.eu/data/cze.svg",
    },
    {
        "name": "Denmark",
        "code": "DK",
        "capital": "Copenhagen",
        "region": "EU",
        "currency": {
            "code": "DKK",
            "name": "Danish krone",
            "symbol": "kr"
        },
        "language": {
            "code": "da",
            "name": "Danish",
        },
        "flag": "https://restcountries.eu/data/dnk.svg",
    },
    {
        "name": "Djibouti",
        "code": "DJ",
        "capital": "Djibouti",
        "region": "AF",
        "currency": {
            "code": "DJF",
            "name": "Djiboutian franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/dji.svg",
    },
    {
        "name": "Dominica",
        "code": "DM",
        "capital": "Roseau",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/dma.svg",
    },
    {
        "name": "Dominican Republic",
        "code": "DO",
        "capital": "Santo Domingo",
        "region": "NA",
        "currency": {
            "code": "DOP",
            "name": "Dominican peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/dom.svg",
    },
    {
        "name": "Ecuador",
        "code": "EC",
        "capital": "Quito",
        "region": "SA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/ecu.svg",
    },
    {
        "name": "Egypt",
        "code": "EG",
        "capital": "Cairo",
        "region": "AF",
        "currency": {
            "code": "EGP",
            "name": "Egyptian pound",
            "symbol": "£"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/egy.svg",
    },
    {
        "name": "El Salvador",
        "code": "SV",
        "capital": "San Salvador",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/slv.svg",
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ",
        "capital": "Malabo",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "es",
            "iso639_2": "spa",
            "name": "Spanish",
            "nativeName": "Español"
        },
        "flag": "https://restcountries.eu/data/gnq.svg",
    },
    {
        "name": "Eritrea",
        "code": "ER",
        "capital": "Asmara",
        "region": "AF",
        "currency": {
            "code": "ERN",
            "name": "Eritrean nakfa",
            "symbol": "Nfk"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/eri.svg",
    },
    {
        "name": "Estonia",
        "code": "EE",
        "capital": "Tallinn",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "et",
            "name": "Estonian",
        },
        "flag": "https://restcountries.eu/data/est.svg",
    },
    {
        "name": "Ethiopia",
        "code": "ET",
        "capital": "Addis Ababa",
        "region": "AF",
        "currency": {
            "code": "ETB",
            "name": "Ethiopian birr",
            "symbol": "Br"
        },
        "language": {
            "code": "am",
            "name": "Amharic",
        },
        "flag": "https://restcountries.eu/data/eth.svg",
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK",
        "capital": "Stanley",
        "region": "SA",
        "currency": {
            "code": "FKP",
            "name": "Falkland Islands pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/flk.svg",
    },
    {
        "name": "Faroe Islands",
        "code": "FO",
        "capital": "Tórshavn",
        "region": "EU",
        "currency": {
            "code": "DKK",
            "name": "Danish krone",
            "symbol": "kr"
        },
        "language": {
            "code": "fo",
            "name": "Faroese",
        },
        "flag": "https://restcountries.eu/data/fro.svg",
    },
    {
        "name": "Fiji",
        "code": "FJ",
        "capital": "Suva",
        "region": "OC",
        "currency": {
            "code": "FJD",
            "name": "Fijian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/fji.svg",
    },
    {
        "name": "Finland",
        "code": "FI",
        "capital": "Helsinki",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fi",
            "iso639_2": "fin",
            "name": "Finnish",
            "nativeName": "suomi"
        },
        "flag": "https://restcountries.eu/data/fin.svg",
    },
    {
        "name": "France",
        "code": "FR",
        "capital": "Paris",
        "region": "EU",
        "demonym": "French",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/fra.svg",
    },
    {
        "name": "French Guiana",
        "code": "GF",
        "capital": "Cayenne",
        "region": "SA",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/guf.svg",
    },
    {
        "name": "French Polynesia",
        "code": "PF",
        "capital": "Papeetē",
        "region": "OC",
        "currency": {
            "code": "XPF",
            "name": "CFP franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/pyf.svg",
    },
    {
        "name": "French Southern Territories",
        "code": "TF",
        "capital": "Port-aux-Français",
        "region": "AF",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/atf.svg",
    },
    {
        "name": "Gabon",
        "code": "GA",
        "capital": "Libreville",
        "region": "AF",
        "currency": {
            "code": "XAF",
            "name": "Central African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/gab.svg",
    },
    {
        "name": "Gambia",
        "code": "GM",
        "capital": "Banjul",
        "region": "AF",
        "currency": {
            "code": "GMD",
            "name": "Gambian dalasi",
            "symbol": "D"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/gmb.svg",
    },
    {
        "name": "Georgia",
        "code": "GE",
        "capital": "Tbilisi",
        "region": "AS",
        "currency": {
            "code": "GEL",
            "name": "Georgian Lari",
            "symbol": "ლ"
        },
        "language": {
            "code": "ka",
            "name": "Georgian",
        },
        "flag": "https://restcountries.eu/data/geo.svg",
    },
    {
        "name": "Germany",
        "code": "DE",
        "capital": "Berlin",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "de",
            "name": "German",
        },
        "flag": "https://restcountries.eu/data/deu.svg",
    },
    {
        "name": "Ghana",
        "code": "GH",
        "capital": "Accra",
        "region": "AF",
        "currency": {
            "code": "GHS",
            "name": "Ghanaian cedi",
            "symbol": "₵"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/gha.svg",
    },
    {
        "name": "Gibraltar",
        "code": "GI",
        "capital": "Gibraltar",
        "region": "EU",
        "currency": {
            "code": "GIP",
            "name": "Gibraltar pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/gib.svg",
    },
    {
        "name": "Greece",
        "code": "GR",
        "capital": "Athens",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "el",
            "name": "Greek (modern)",
        },
        "flag": "https://restcountries.eu/data/grc.svg",
    },
    {
        "name": "Greenland",
        "code": "GL",
        "capital": "Nuuk",
        "region": "NA",
        "currency": {
            "code": "DKK",
            "name": "Danish krone",
            "symbol": "kr"
        },
        "language": {
            "code": "kl",
            "name": "Kalaallisut",
        },
        "flag": "https://restcountries.eu/data/grl.svg",
    },
    {
        "name": "Grenada",
        "code": "GD",
        "capital": "St. George's",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/grd.svg",
    },
    {
        "name": "Guadeloupe",
        "code": "GP",
        "capital": "Basse-Terre",
        "region": "NA",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/glp.svg",
    },
    {
        "name": "Guam",
        "code": "GU",
        "capital": "Hagåtña",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/gum.svg",
    },
    {
        "name": "Guatemala",
        "code": "GT",
        "capital": "Guatemala City",
        "region": "NA",
        "currency": {
            "code": "GTQ",
            "name": "Guatemalan quetzal",
            "symbol": "Q"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/gtm.svg",
    },
    {
        "name": "Guernsey",
        "code": "GG",
        "capital": "St. Peter Port",
        "region": "EU",
        "currency": {
            "code": "GBP",
            "name": "British pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/ggy.svg",
    },
    {
        "name": "Guinea",
        "code": "GN",
        "capital": "Conakry",
        "region": "AF",
        "currency": {
            "code": "GNF",
            "name": "Guinean franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/gin.svg",
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW",
        "capital": "Bissau",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/gnb.svg"
    },
    {
        "name": "Guyana",
        "code": "GY",
        "capital": "Georgetown",
        "region": "SA",
        "currency": {
            "code": "GYD",
            "name": "Guyanese dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/guy.svg"
    },
    {
        "name": "Haiti",
        "code": "HT",
        "capital": "Port-au-Prince",
        "region": "Americas",
        "currency": {
            "code": "HTG",
            "name": "Haitian gourde",
            "symbol": "G"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/hti.svg"
    },
    // {
    //     "name": "Heard Island and McDonald Islands",
    //     "code": "HM",
    //     "capital": "",
    //     "region": "",
    //     "currency": {
    //         "code": "AUD",
    //         "name": "Australian dollar",
    //         "symbol": "$"
    //     },
    //     "language": {
    //         "code": "en",
    //         "name": "English",
    //     },
    //     "flag": "https://restcountries.eu/data/hmd.svg"
    // },
    {
        "name": "Holy See",
        "code": "VA",
        "capital": "Rome",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/vat.svg"
    },
    {
        "name": "Honduras",
        "code": "HN",
        "capital": "Tegucigalpa",
        "region": "NA",
        "currency": {
            "code": "HNL",
            "name": "Honduran lempira",
            "symbol": "L"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/hnd.svg"
    },
    {
        "name": "Hong Kong",
        "code": "HK",
        "capital": "City of Victoria",
        "region": "AS",
        "currency": {
            "code": "HKD",
            "name": "Hong Kong dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/hkg.svg"
    },
    {
        "name": "Hungary",
        "code": "HU",
        "capital": "Budapest",
        "region": "EU",
        "currency": {
            "code": "HUF",
            "name": "Hungarian forint",
            "symbol": "Ft"
        },
        "language": {
            "code": "hu",
            "name": "Hungarian",
        },
        "flag": "https://restcountries.eu/data/hun.svg"
    },
    {
        "name": "Iceland",
        "code": "IS",
        "capital": "Reykjavík",
        "region": "EU",
        "currency": {
            "code": "ISK",
            "name": "Icelandic króna",
            "symbol": "kr"
        },
        "language": {
            "code": "is",
            "name": "Icelandic",
        },
        "flag": "https://restcountries.eu/data/isl.svg"
    },
    {
        "name": "India",
        "code": "IN",
        "capital": "New Delhi",
        "region": "AS",
        "currency": {
            "code": "INR",
            "name": "Indian rupee",
            "symbol": "₹"
        },
        "language": {
            "code": "hi",
            "name": "Hindi",
        },
        "flag": "https://restcountries.eu/data/ind.svg"
    },
    {
        "name": "Indonesia",
        "code": "ID",
        "capital": "Jakarta",
        "region": "AS",
        "currency": {
            "code": "IDR",
            "name": "Indonesian rupiah",
            "symbol": "Rp"
        },
        "language": {
            "code": "id",
            "name": "Indonesian",
        },
        "flag": "https://restcountries.eu/data/idn.svg"
    },
    {
        "name": "Côte d'Ivoire",
        "code": "CI",
        "capital": "Yamoussoukro",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/civ.svg"
    },
    {
        "name": "Iran (Islamic Republic of)",
        "code": "IR",
        "capital": "Tehran",
        "region": "AS",
        "currency": {
            "code": "IRR",
            "name": "Iranian rial",
            "symbol": "﷼"
        },
        "language": {
            "code": "fa",
            "name": "Persian (Farsi)",
        },
        "flag": "https://restcountries.eu/data/irn.svg"
    },
    {
        "name": "Iraq",
        "code": "IQ",
        "capital": "Baghdad",
        "region": "AS",
        "currency": {
            "code": "IQD",
            "name": "Iraqi dinar",
            "symbol": "ع.د"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/irq.svg"
    },
    {
        "name": "Ireland",
        "code": "IE",
        "capital": "Dublin",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "ga",
            "name": "Irish",
        },
        "flag": "https://restcountries.eu/data/irl.svg"
    },
    {
        "name": "Isle of Man",
        "code": "IM",
        "capital": "Douglas",
        "region": "EU",
        "currency": {
            "code": "GBP",
            "name": "British pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/imn.svg"
    },
    {
        "name": "Israel",
        "code": "IL",
        "capital": "Jerusalem",
        "region": "AS",
        "currency": {
            "code": "ILS",
            "name": "Israeli new shekel",
            "symbol": "₪"
        },
        "language": {
            "code": "he",
            "name": "Hebrew (modern)",
        },
        "flag": "https://restcountries.eu/data/isr.svg"
    },
    {
        "name": "Italy",
        "code": "IT",
        "capital": "Rome",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "it",
            "name": "Italian",
        },
        "flag": "https://restcountries.eu/data/ita.svg"
    },
    {
        "name": "Jamaica",
        "code": "JM",
        "capital": "Kingston",
        "region": "NA",
        "currency": {
            "code": "JMD",
            "name": "Jamaican dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/jam.svg"
    },
    {
        "name": "Japan",
        "code": "JP",
        "capital": "Tokyo",
        "region": "AS",
        "currency": {
            "code": "JPY",
            "name": "Japanese yen",
            "symbol": "¥"
        },
        "language": {
            "code": "ja",
            "name": "Japanese",
        },
        "flag": "https://restcountries.eu/data/jpn.svg"
    },
    {
        "name": "Jersey",
        "code": "JE",
        "capital": "Saint Helier",
        "region": "EU",
        "currency": {
            "code": "GBP",
            "name": "British pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "iso639_2": "eng",
            "name": "English",
            "nativeName": "English"
        },
        "flag": "https://restcountries.eu/data/jey.svg"
    },
    {
        "name": "Jordan",
        "code": "JO",
        "capital": "Amman",
        "region": "AS",
        "currency": {
            "code": "JOD",
            "name": "Jordanian dinar",
            "symbol": "د.ا"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/jor.svg"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ",
        "capital": "Astana",
        "region": "AS",
        "currency": {
            "code": "KZT",
            "name": "Kazakhstani tenge",
            "symbol": null
        },
        "language": {
            "code": "kk",
            "name": "Kazakh",
        },
        "flag": "https://restcountries.eu/data/kaz.svg"
    },
    {
        "name": "Kenya",
        "code": "KE",
        "capital": "Nairobi",
        "region": "AF",
        "currency": {
            "code": "KES",
            "name": "Kenyan shilling",
            "symbol": "Sh"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/ken.svg"
    },
    {
        "name": "Kiribati",
        "code": "KI",
        "capital": "South Tarawa",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/kir.svg"
    },
    {
        "name": "Kuwait",
        "code": "KW",
        "capital": "Kuwait City",
        "region": "AS",
        "currency": {
            "code": "KWD",
            "name": "Kuwaiti dinar",
            "symbol": "د.ك"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/kwt.svg"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG",
        "capital": "Bishkek",
        "region": "AS",
        "currency": {
            "code": "KGS",
            "name": "Kyrgyzstani som",
            "symbol": "с"
        },
        "language": {
            "code": "ky",
            "name": "Kyrgyz",
        },
        "flag": "https://restcountries.eu/data/kgz.svg"
    },
    {
        "name": "Lao People's Democratic Republic",
        "code": "LA",
        "capital": "Vientiane",
        "region": "AS",
        "currency": {
            "code": "LAK",
            "name": "Lao kip",
            "symbol": "₭"
        },
        "language": {
            "code": "lo",
            "name": "Lao",
        },
        "flag": "https://restcountries.eu/data/lao.svg"
    },
    {
        "name": "Latvia",
        "code": "LV",
        "capital": "Riga",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "lv",
            "name": "Latvian",
        },
        "flag": "https://restcountries.eu/data/lva.svg"
    },
    {
        "name": "Lebanon",
        "code": "LB",
        "capital": "Beirut",
        "region": "AS",
        "currency": {
            "code": "LBP",
            "name": "Lebanese pound",
            "symbol": "ل.ل"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/lbn.svg"
    },
    {
        "name": "Lesotho",
        "code": "LS",
        "capital": "Maseru",
        "region": "AF",
        "currency": {
            "code": "LSL",
            "name": "Lesotho loti",
            "symbol": "L"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/lso.svg"
    },
    {
        "name": "Liberia",
        "code": "LR",
        "capital": "Monrovia",
        "region": "AF",
        "currency": {
            "code": "LRD",
            "name": "Liberian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/lbr.svg"
    },
    {
        "name": "Libya",
        "code": "LY",
        "capital": "Tripoli",
        "region": "AF",
        "currency": {
            "code": "LYD",
            "name": "Libyan dinar",
            "symbol": "ل.د"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/lby.svg"
    },
    {
        "name": "Liechtenstein",
        "code": "LI",
        "capital": "Vaduz",
        "region": "EU",
        "currency": {
            "code": "CHF",
            "name": "Swiss franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "de",
            "name": "German",
        },
        "flag": "https://restcountries.eu/data/lie.svg"
    },
    {
        "name": "Lithuania",
        "code": "LT",
        "capital": "Vilnius",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "lt",
            "name": "Lithuanian",
        },
        "flag": "https://restcountries.eu/data/ltu.svg"
    },
    {
        "name": "Luxembourg",
        "code": "LU",
        "capital": "Luxembourg",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/lux.svg"
    },
    {
        "name": "Macao",
        "code": "MO",
        "capital": "",
        "region": "AS",
        "currency": {
            "code": "MOP",
            "name": "Macanese pataca",
            "symbol": "P"
        },
        "language": {
            "code": "zh",
            "name": "Chinese",
        },
        "flag": "https://restcountries.eu/data/mac.svg"
    },
    {
        "name": "Macedonia (the former Yugoslav Republic of)",
        "code": "MK",
        "capital": "Skopje",
        "region": "EU",
        "currency": {
            "code": "MKD",
            "name": "Macedonian denar",
            "symbol": "ден"
        },
        "language": {
            "code": "mk",
            "name": "Macedonian",
        },
        "flag": "https://restcountries.eu/data/mkd.svg"
    },
    {
        "name": "Madagascar",
        "code": "MG",
        "capital": "Antananarivo",
        "region": "AF",
        "currency": {
            "code": "MGA",
            "name": "Malagasy ariary",
            "symbol": "Ar"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/mdg.svg"
    },
    {
        "name": "Malawi",
        "code": "MW",
        "capital": "Lilongwe",
        "region": "AF",
        "currency": {
            "code": "MWK",
            "name": "Malawian kwacha",
            "symbol": "MK"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/mwi.svg"
    },
    {
        "name": "Malaysia",
        "code": "MY",
        "capital": "Kuala Lumpur",
        "region": "AS",
        "currency": {
            "code": "MYR",
            "name": "Malaysian ringgit",
            "symbol": "RM"
        },
        "language": {
            "code": null,
            "name": "Malaysian",
        },
        "flag": "https://restcountries.eu/data/mys.svg"
    },
    {
        "name": "Maldives",
        "code": "MV",
        "capital": "Malé",
        "region": "AS",
        "currency": {
            "code": "MVR",
            "name": "Maldivian rufiyaa",
            "symbol": ".ރ"
        },
        "language": {
            "code": "dv",
            "name": "Divehi",
        },
        "flag": "https://restcountries.eu/data/mdv.svg"
    },
    {
        "name": "Mali",
        "code": "ML",
        "capital": "Bamako",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/mli.svg"
    },
    {
        "name": "Malta",
        "code": "MT",
        "capital": "Valletta",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "mt",
            "name": "Maltese",
        },
        "flag": "https://restcountries.eu/data/mlt.svg"
    },
    {
        "name": "Marshall Islands",
        "code": "MH",
        "capital": "Majuro",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/mhl.svg"
    },
    {
        "name": "Martinique",
        "code": "MQ",
        "capital": "Fort-de-France",
        "region": "Americas",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/mtq.svg"
    },
    {
        "name": "Mauritania",
        "code": "MR",
        "capital": "Nouakchott",
        "region": "AF",
        "currency": {
            "code": "MRO",
            "name": "Mauritanian ouguiya",
            "symbol": "UM"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/mrt.svg"
    },
    {
        "name": "Mauritius",
        "code": "MU",
        "capital": "Port Louis",
        "region": "AF",
        "currency": {
            "code": "MUR",
            "name": "Mauritian rupee",
            "symbol": "₨"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/mus.svg"
    },
    {
        "name": "Mayotte",
        "code": "YT",
        "capital": "Mamoudzou",
        "region": "AF",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/myt.svg"
    },
    {
        "name": "Mexico",
        "code": "MX",
        "capital": "Mexico City",
        "region": "NA",
        "currency": {
            "code": "MXN",
            "name": "Mexican peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/mex.svg"
    },
    {
        "name": "Micronesia (Federated States of)",
        "code": "FM",
        "capital": "Palikir",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/fsm.svg"
    },
    {
        "name": "Moldova (Republic of)",
        "code": "MD",
        "capital": "Chișinău",
        "region": "EU",
        "currency": {
            "code": "MDL",
            "name": "Moldovan leu",
            "symbol": "L"
        },
        "language": {
            "code": "ro",
            "name": "Romanian",
        },
        "flag": "https://restcountries.eu/data/mda.svg"
    },
    {
        "name": "Monaco",
        "code": "MC",
        "capital": "Monaco",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/mco.svg"
    },
    {
        "name": "Mongolia",
        "code": "MN",
        "capital": "Ulan Bator",
        "region": "AS",
        "currency": {
            "code": "MNT",
            "name": "Mongolian tögrög",
            "symbol": "₮"
        },
        "language": {
            "code": "mn",
            "name": "Mongolian",
        },
        "flag": "https://restcountries.eu/data/mng.svg"
    },
    {
        "name": "Montenegro",
        "code": "ME",
        "capital": "Podgorica",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "sr",
            "name": "Serbian",
        },
        "flag": "https://restcountries.eu/data/mne.svg"
    },
    {
        "name": "Montserrat",
        "code": "MS",
        "capital": "Plymouth",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/msr.svg"
    },
    {
        "name": "Morocco",
        "code": "MA",
        "capital": "Rabat",
        "region": "AF",
        "currency": {
            "code": "MAD",
            "name": "Moroccan dirham",
            "symbol": "د.م."
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/mar.svg"
    },
    {
        "name": "Mozambique",
        "code": "MZ",
        "capital": "Maputo",
        "region": "AF",
        "currency": {
            "code": "MZN",
            "name": "Mozambican metical",
            "symbol": "MT"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/moz.svg"
    },
    {
        "name": "Myanmar",
        "code": "MM",
        "capital": "Naypyidaw",
        "region": "AS",
        "currency": {
            "code": "MMK",
            "name": "Burmese kyat",
            "symbol": "Ks"
        },
        "language": {
            "code": "my",
            "name": "Burmese",
        },
        "flag": "https://restcountries.eu/data/mmr.svg"
    },
    {
        "name": "Namibia",
        "code": "NA",
        "capital": "Windhoek",
        "region": "AF",
        "currency": {
            "code": "NAD",
            "name": "Namibian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/nam.svg"
    },
    {
        "name": "Nauru",
        "code": "NR",
        "capital": "Yaren",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/nru.svg"
    },
    {
        "name": "Nepal",
        "code": "NP",
        "capital": "Kathmandu",
        "region": "AS",
        "currency": {
            "code": "NPR",
            "name": "Nepalese rupee",
            "symbol": "₨"
        },
        "language": {
            "code": "ne",
            "name": "Nepali",
        },
        "flag": "https://restcountries.eu/data/npl.svg"
    },
    {
        "name": "Netherlands",
        "code": "NL",
        "capital": "Amsterdam",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/nld.svg"
    },
    {
        "name": "New Caledonia",
        "code": "NC",
        "capital": "Nouméa",
        "region": "OC",
        "currency": {
            "code": "XPF",
            "name": "CFP franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/ncl.svg"
    },
    {
        "name": "New Zealand",
        "code": "NZ",
        "capital": "Wellington",
        "region": "OC",
        "currency": {
            "code": "NZD",
            "name": "New Zealand dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/nzl.svg"
    },
    {
        "name": "Nicaragua",
        "code": "NI",
        "capital": "Managua",
        "region": "NA",
        "currency": {
            "code": "NIO",
            "name": "Nicaraguan córdoba",
            "symbol": "C$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/nic.svg"
    },
    {
        "name": "Niger",
        "code": "NE",
        "capital": "Niamey",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/ner.svg"
    },
    {
        "name": "Nigeria",
        "code": "NG",
        "capital": "Abuja",
        "region": "AF",
        "currency": {
            "code": "NGN",
            "name": "Nigerian naira",
            "symbol": "₦"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/nga.svg"
    },
    {
        "name": "Niue",
        "code": "NU",
        "capital": "Alofi",
        "region": "OC",
        "currency": {
            "code": "NZD",
            "name": "New Zealand dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/niu.svg"
    },
    {
        "name": "Norfolk Island",
        "code": "NF",
        "capital": "Kingston",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/nfk.svg"
    },
    {
        "name": "Korea (Democratic People's Republic of)",
        "code": "KP",
        "capital": "Pyongyang",
        "region": "AS",
        "currency": {
            "code": "KPW",
            "name": "North Korean won",
            "symbol": "₩"
        },
        "language": {
            "code": "ko",
            "name": "Korean",
        },
        "flag": "https://restcountries.eu/data/prk.svg"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "capital": "Saipan",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/mnp.svg"
    },
    {
        "name": "Norway",
        "code": "NO",
        "capital": "Oslo",
        "region": "EU",
        "currency": {
            "code": "NOK",
            "name": "Norwegian krone",
            "symbol": "kr"
        },
        "language": {
            "code": "no",
            "name": "Norwegian",
        },
        "flag": "https://restcountries.eu/data/nor.svg"
    },
    {
        "name": "Oman",
        "code": "OM",
        "capital": "Muscat",
        "region": "AS",
        "currency": {
            "code": "OMR",
            "name": "Omani rial",
            "symbol": "ر.ع."
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/omn.svg"
    },
    {
        "name": "Pakistan",
        "code": "PK",
        "capital": "Islamabad",
        "region": "AS",
        "currency": {
            "code": "PKR",
            "name": "Pakistani rupee",
            "symbol": "₨"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/pak.svg"
    },
    {
        "name": "Palau",
        "code": "PW",
        "capital": "Ngerulmud",
        "region": "OC",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/plw.svg"
    },
    {
        "name": "Palestine, State of",
        "code": "PS",
        "capital": "Ramallah",
        "region": "AS",
        "currency": {
            "code": "ILS",
            "name": "Israeli new sheqel",
            "symbol": "₪"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/pse.svg"
    },
    {
        "name": "Panama",
        "code": "PA",
        "capital": "Panama City",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/pan.svg"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG",
        "capital": "Port Moresby",
        "region": "OC",
        "currency": {
            "code": "PGK",
            "name": "Papua New Guinean kina",
            "symbol": "K"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/png.svg"
    },
    {
        "name": "Paraguay",
        "code": "PY",
        "capital": "Asunción",
        "region": "SA",
        "currency": {
            "code": "PYG",
            "name": "Paraguayan guaraní",
            "symbol": "₲"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/pry.svg"
    },
    {
        "name": "Peru",
        "code": "PE",
        "capital": "Lima",
        "region": "SA",
        "currency": {
            "code": "PEN",
            "name": "Peruvian sol",
            "symbol": "S/."
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/per.svg"
    },
    {
        "name": "Philippines",
        "code": "PH",
        "capital": "Manila",
        "region": "AS",
        "currency": {
            "code": "PHP",
            "name": "Philippine peso",
            "symbol": "₱"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/phl.svg"
    },
    {
        "name": "Pitcairn",
        "code": "PN",
        "capital": "Adamstown",
        "region": "OC",
        "currency": {
            "code": "NZD",
            "name": "New Zealand dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/pcn.svg"
    },
    {
        "name": "Poland",
        "code": "PL",
        "capital": "Warsaw",
        "region": "EU",
        "currency": {
            "code": "PLN",
            "name": "Polish złoty",
            "symbol": "zł"
        },
        "language": {
            "code": "pl",
            "name": "Polish",
        },
        "flag": "https://restcountries.eu/data/pol.svg"
    },
    {
        "name": "Portugal",
        "code": "PT",
        "capital": "Lisbon",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/prt.svg"
    },
    {
        "name": "Puerto Rico",
        "code": "PR",
        "capital": "San Juan",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/pri.svg"
    },
    {
        "name": "Qatar",
        "code": "QA",
        "capital": "Doha",
        "region": "AS",
        "currency": {
            "code": "QAR",
            "name": "Qatari riyal",
            "symbol": "ر.ق"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/qat.svg"
    },
    {
        "name": "Republic of Kosovo",
        "code": "XK",
        "capital": "Pristina",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "sq",
            "name": "Albanian",
        },
        "flag": "https://restcountries.eu/data/kos.svg"
    },
    {
        "name": "Réunion",
        "code": "RE",
        "capital": "Saint-Denis",
        "region": "AF",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/reu.svg"
    },
    {
        "name": "Romania",
        "code": "RO",
        "capital": "Bucharest",
        "region": "EU",
        "currency": {
            "code": "RON",
            "name": "Romanian leu",
            "symbol": "lei"
        },
        "language": {
            "code": "ro",
            "name": "Romanian",
        },
        "flag": "https://restcountries.eu/data/rou.svg"
    },
    {
        "name": "Russian Federation",
        "code": "RU",
        "capital": "Moscow",
        "region": "EU",
        "currency": {
            "code": "RUB",
            "name": "Russian ruble",
            "symbol": "₽"
        },
        "language": {
            "code": "ru",
            "name": "Russian",
        },
        "flag": "https://restcountries.eu/data/rus.svg"
    },
    {
        "name": "Rwanda",
        "code": "RW",
        "capital": "Kigali",
        "region": "AF",
        "currency": {
            "code": "RWF",
            "name": "Rwandan franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "rw",
            "name": "Kinyarwanda",
        },
        "flag": "https://restcountries.eu/data/rwa.svg"
    },
    {
        "name": "Saint Barthélemy",
        "code": "BL",
        "capital": "Gustavia",
        "region": "NA",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/blm.svg"
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "code": "SH",
        "capital": "Jamestown",
        "region": "AF",
        "currency": {
            "code": "SHP",
            "name": "Saint Helena pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/shn.svg"
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "KN",
        "capital": "Basseterre",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/kna.svg"
    },
    {
        "name": "Saint Lucia",
        "code": "LC",
        "capital": "Castries",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/lca.svg"
    },
    {
        "name": "Saint Martin (French part)",
        "code": "MF",
        "capital": "Marigot",
        "region": "NA",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/maf.svg"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "PM",
        "capital": "Saint-Pierre",
        "region": "NA",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/spm.svg"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "VC",
        "capital": "Kingstown",
        "region": "NA",
        "currency": {
            "code": "XCD",
            "name": "East Caribbean dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/vct.svg"
    },
    {
        "name": "Samoa",
        "code": "WS",
        "capital": "Apia",
        "region": "OC",
        "currency": {
            "code": "WST",
            "name": "Samoan tālā",
            "symbol": "T"
        },
        "language": {
            "code": "sm",
            "name": "Samoan",
        },
        "flag": "https://restcountries.eu/data/wsm.svg"
    },
    {
        "name": "San Marino",
        "code": "SM",
        "capital": "City of San Marino",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "it",
            "name": "Italian",
        },
        "flag": "https://restcountries.eu/data/smr.svg"
    },
    {
        "name": "Sao Tome and Principe",
        "code": "ST",
        "capital": "São Tomé",
        "region": "AF",
        "currency": {
            "code": "STD",
            "name": "São Tomé and Príncipe dobra",
            "symbol": "Db"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/stp.svg"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA",
        "capital": "Riyadh",
        "region": "AS",
        "currency": {
            "code": "SAR",
            "name": "Saudi riyal",
            "symbol": "ر.س"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/sau.svg"
    },
    {
        "name": "Senegal",
        "code": "SN",
        "capital": "Dakar",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/sen.svg"
    },
    {
        "name": "Serbia",
        "code": "RS",
        "capital": "Belgrade",
        "region": "EU",
        "currency": {
            "code": "RSD",
            "name": "Serbian dinar",
            "symbol": "дин."
        },
        "language": {
            "code": "sr",
            "name": "Serbian",
        },
        "flag": "https://restcountries.eu/data/srb.svg"
    },
    {
        "name": "Seychelles",
        "code": "SC",
        "capital": "Victoria",
        "region": "AF",
        "currency": {
            "code": "SCR",
            "name": "Seychellois rupee",
            "symbol": "₨"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/syc.svg"
    },
    {
        "name": "Sierra Leone",
        "code": "SL",
        "capital": "Freetown",
        "region": "AF",
        "currency": {
            "code": "SLL",
            "name": "Sierra Leonean leone",
            "symbol": "Le"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/sle.svg"
    },
    {
        "name": "Singapore",
        "code": "SG",
        "capital": "Singapore",
        "region": "AS",
        "currency": {
            "code": "SGD",
            "name": "Singapore dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/sgp.svg"
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "code": "SX",
        "capital": "Philipsburg",
        "region": "Americas",
        "currency": {
            "code": "ANG",
            "name": "Netherlands Antillean guilder",
            "symbol": "ƒ"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/sxm.svg"
    },
    {
        "name": "Slovakia",
        "code": "SK",
        "capital": "Bratislava",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "sk",
            "name": "Slovak",
        },
        "flag": "https://restcountries.eu/data/svk.svg"
    },
    {
        "name": "Slovenia",
        "code": "SI",
        "capital": "Ljubljana",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "sl",
            "name": "Slovene",
        },
        "flag": "https://restcountries.eu/data/svn.svg"
    },
    {
        "name": "Solomon Islands",
        "code": "SB",
        "capital": "Honiara",
        "region": "OC",
        "currency": {
            "code": "SBD",
            "name": "Solomon Islands dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/slb.svg"
    },
    {
        "name": "Somalia",
        "code": "SO",
        "capital": "Mogadishu",
        "region": "AF",
        "currency": {
            "code": "SOS",
            "name": "Somali shilling",
            "symbol": "Sh"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/som.svg"
    },
    {
        "name": "South Africa",
        "code": "ZA",
        "capital": "Pretoria",
        "region": "AF",
        "currency": {
            "code": "ZAR",
            "name": "South African rand",
            "symbol": "R"
        },
        "language": {
            "code": "en",
            "iso639_2": "eng",
            "name": "English",
            "nativeName": "English"
        },
        "flag": "https://restcountries.eu/data/zaf.svg"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS",
        "capital": "King Edward Point",
        "region": "NA",
        "currency": {
            "code": "GBP",
            "name": "British pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/sgs.svg"
    },
    {
        "name": "Korea (Republic of)",
        "code": "KR",
        "capital": "Seoul",
        "region": "AS",
        "currency": {
            "code": "KRW",
            "name": "South Korean won",
            "symbol": "₩"
        },
        "language": {
            "code": "ko",
            "name": "Korean",
        },
        "flag": "https://restcountries.eu/data/kor.svg"
    },
    {
        "name": "South Sudan",
        "code": "SS",
        "capital": "Juba",
        "region": "AF",
        "currency": {
            "code": "SSP",
            "name": "South Sudanese pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/ssd.svg"
    },
    {
        "name": "Spain",
        "code": "ES",
        "capital": "Madrid",
        "region": "EU",
        "currency": {
            "code": "EUR",
            "name": "Euro",
            "symbol": "€"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/esp.svg"
    },
    {
        "name": "Sri Lanka",
        "code": "LK",
        "capital": "Colombo",
        "region": "AS",
        "currency": {
            "code": "LKR",
            "name": "Sri Lankan rupee",
            "symbol": "Rs"
        },
        "language": {
            "code": "si",
            "iso639_2": "sin",
            "name": "Sinhalese",
            "nativeName": "සිංහල"
        },
        "flag": "https://restcountries.eu/data/lka.svg"
    },
    {
        "name": "Sudan",
        "code": "SD",
        "capital": "Khartoum",
        "region": "AF",
        "currency": {
            "code": "SDG",
            "name": "Sudanese pound",
            "symbol": "ج.س."
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/sdn.svg"
    },
    {
        "name": "Suriname",
        "code": "SR",
        "capital": "Paramaribo",
        "region": "SA",
        "currency": {
            "code": "SRD",
            "name": "Surinamese dollar",
            "symbol": "$"
        },
        "language": {
            "code": "nl",
            "name": "Dutch",
        },
        "flag": "https://restcountries.eu/data/sur.svg"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "SJ",
        "capital": "Longyearbyen",
        "region": "EU",
        "currency": {
            "code": "NOK",
            "name": "Norwegian krone",
            "symbol": "kr"
        },
        "language": {
            "code": "no",
            "name": "Norwegian",
        },
        "flag": "https://restcountries.eu/data/sjm.svg"
    },
    {
        "name": "Swaziland",
        "code": "SZ",
        "capital": "Lobamba",
        "region": "AF",
        "currency": {
            "code": "SZL",
            "name": "Swazi lilangeni",
            "symbol": "L"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/swz.svg"
    },
    {
        "name": "Sweden",
        "code": "SE",
        "capital": "Stockholm",
        "region": "EU",
        "currency": {
            "code": "SEK",
            "name": "Swedish krona",
            "symbol": "kr"
        },
        "language": {
            "code": "sv",
            "name": "Swedish",
        },
        "flag": "https://restcountries.eu/data/swe.svg"
    },
    {
        "name": "Switzerland",
        "code": "CH",
        "capital": "Bern",
        "region": "EU",
        "currency": {
            "code": "CHF",
            "name": "Swiss franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "de",
            "name": "German",
        },
        "flag": "https://restcountries.eu/data/che.svg"
    },
    {
        "name": "Syrian Arab Republic",
        "code": "SY",
        "capital": "Damascus",
        "region": "AS",
        "currency": {
            "code": "SYP",
            "name": "Syrian pound",
            "symbol": "£"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/syr.svg"
    },
    {
        "name": "Taiwan",
        "code": "TW",
        "capital": "Taipei",
        "region": "AS",
        "currency": {
            "code": "TWD",
            "name": "New Taiwan dollar",
            "symbol": "$"
        },
        "language": {
            "code": "zh",
            "name": "Chinese",
        },
        "flag": "https://restcountries.eu/data/twn.svg"
    },
    {
        "name": "Tajikistan",
        "code": "TJ",
        "capital": "Dushanbe",
        "region": "AS",
        "currency": {
            "code": "TJS",
            "name": "Tajikistani somoni",
            "symbol": "ЅМ"
        },
        "language": {
            "code": "tg",
            "name": "Tajik",
        },
        "flag": "https://restcountries.eu/data/tjk.svg"
    },
    {
        "name": "Tanzania, United Republic of",
        "code": "TZ",
        "capital": "Dodoma",
        "region": "AF",
        "currency": {
            "code": "TZS",
            "name": "Tanzanian shilling",
            "symbol": "Sh"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/tza.svg"
    },
    {
        "name": "Thailand",
        "code": "TH",
        "capital": "Bangkok",
        "region": "AS",
        "currency": {
            "code": "THB",
            "name": "Thai baht",
            "symbol": "฿"
        },
        "language": {
            "code": "th",
            "name": "Thai",
        },
        "flag": "https://restcountries.eu/data/tha.svg"
    },
    {
        "name": "Timor-Leste",
        "code": "TL",
        "capital": "Dili",
        "region": "AS",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "pt",
            "name": "Portuguese",
        },
        "flag": "https://restcountries.eu/data/tls.svg"
    },
    {
        "name": "Togo",
        "code": "TG",
        "capital": "Lomé",
        "region": "AF",
        "currency": {
            "code": "XOF",
            "name": "West African CFA franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/tgo.svg"
    },
    {
        "name": "Tokelau",
        "code": "TK",
        "capital": "Fakaofo",
        "region": "OC",
        "currency": {
            "code": "NZD",
            "name": "New Zealand dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/tkl.svg"
    },
    {
        "name": "Tonga",
        "code": "TO",
        "capital": "Nuku'alofa",
        "region": "OC",
        "currency": {
            "code": "TOP",
            "name": "Tongan paʻanga",
            "symbol": "T$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/ton.svg"
    },
    {
        "name": "Trinidad and Tobago",
        "code": "TT",
        "capital": "Port of Spain",
        "region": "SA",
        "currency": {
            "code": "TTD",
            "name": "Trinidad and Tobago dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/tto.svg"
    },
    {
        "name": "Tunisia",
        "code": "TN",
        "capital": "Tunis",
        "region": "AF",
        "currency": {
            "code": "TND",
            "name": "Tunisian dinar",
            "symbol": "د.ت"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/tun.svg"
    },
    {
        "name": "Turkey",
        "code": "TR",
        "capital": "Ankara",
        "region": "AS",
        "currency": {
            "code": "TRY",
            "name": "Turkish lira",
            "symbol": null
        },
        "language": {
            "code": "tr",
            "name": "Turkish",
        },
        "flag": "https://restcountries.eu/data/tur.svg"
    },
    {
        "name": "Turkmenistan",
        "code": "TM",
        "capital": "Ashgabat",
        "region": "AS",
        "currency": {
            "code": "TMT",
            "name": "Turkmenistan manat",
            "symbol": "m"
        },
        "language": {
            "code": "tk",
            "name": "Turkmen",
        },
        "flag": "https://restcountries.eu/data/tkm.svg"
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "TC",
        "capital": "Cockburn Town",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/tca.svg"
    },
    {
        "name": "Tuvalu",
        "code": "TV",
        "capital": "Funafuti",
        "region": "OC",
        "currency": {
            "code": "AUD",
            "name": "Australian dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/tuv.svg"
    },
    {
        "name": "Uganda",
        "code": "UG",
        "capital": "Kampala",
        "region": "AF",
        "currency": {
            "code": "UGX",
            "name": "Ugandan shilling",
            "symbol": "Sh"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/uga.svg"
    },
    {
        "name": "Ukraine",
        "code": "UA",
        "capital": "Kiev",
        "region": "EU",
        "currency": {
            "code": "UAH",
            "name": "Ukrainian hryvnia",
            "symbol": "₴"
        },
        "language": {
            "code": "uk",
            "name": "Ukrainian",
        },
        "flag": "https://restcountries.eu/data/ukr.svg"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE",
        "capital": "Abu Dhabi",
        "region": "AS",
        "currency": {
            "code": "AED",
            "name": "United Arab Emirates dirham",
            "symbol": "د.إ"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/are.svg"
    },
    {
        // "name": "United Kingdom of Great Britain and Northern Ireland",
        "name": "United Kingdom",
        "code": "GB",
        "capital": "London",
        "region": "EU",
        "currency": {
            "code": "GBP",
            "name": "British pound",
            "symbol": "£"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/gbr.svg"
    },
    {
        "name": "United States of America",
        "code": "US",
        "capital": "Washington, D.C.",
        "region": "NA",
        "currency": {
            "code": "USD",
            "name": "United States dollar",
            "symbol": "$"
        },
        "language": {
            "code": "en",
            "iso639_2": "eng",
            "name": "English",
            "nativeName": "English"
        },
        "flag": "https://restcountries.eu/data/usa.svg"
    },
    {
        "name": "Uruguay",
        "code": "UY",
        "capital": "Montevideo",
        "region": "SA",
        "currency": {
            "code": "UYU",
            "name": "Uruguayan peso",
            "symbol": "$"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/ury.svg"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ",
        "capital": "Tashkent",
        "region": "AS",
        "currency": {
            "code": "UZS",
            "name": "Uzbekistani so'm",
            "symbol": null
        },
        "language": {
            "code": "uz",
            "name": "Uzbek",
        },
        "flag": "https://restcountries.eu/data/uzb.svg"
    },
    {
        "name": "Vanuatu",
        "code": "VU",
        "capital": "Port Vila",
        "region": "OC",
        "currency": {
            "code": "VUV",
            "name": "Vanuatu vatu",
            "symbol": "Vt"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/vut.svg"
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "code": "VE",
        "capital": "Caracas",
        "region": "SA",
        "currency": {
            "code": "VEF",
            "name": "Venezuelan bolívar",
            "symbol": "Bs F"
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/ven.svg"
    },
    {
        "name": "Viet Nam",
        "code": "VN",
        "capital": "Hanoi",
        "region": "AS",
        "currency": {
            "code": "VND",
            "name": "Vietnamese đồng",
            "symbol": "₫"
        },
        "language": {
            "code": "vi",
            "name": "Vietnamese",
        },
        "flag": "https://restcountries.eu/data/vnm.svg"
    },
    {
        "name": "Wallis and Futuna",
        "code": "WF",
        "capital": "Mata-Utu",
        "region": "OC",
        "currency": {
            "code": "XPF",
            "name": "CFP franc",
            "symbol": "Fr"
        },
        "language": {
            "code": "fr",
            "name": "French",
        },
        "flag": "https://restcountries.eu/data/wlf.svg"
    },
    {
        "name": "Western Sahara",
        "code": "EH",
        "capital": "El Aaiún",
        "region": "AF",
        "currency": {
            "code": "MAD",
            "name": "Moroccan dirham",
            "symbol": "د.م."
        },
        "language": {
            "code": "es",
            "name": "Spanish",
        },
        "flag": "https://restcountries.eu/data/esh.svg"
    },
    {
        "name": "Yemen",
        "code": "YE",
        "capital": "Sana'a",
        "region": "AS",
        "currency": {
            "code": "YER",
            "name": "Yemeni rial",
            "symbol": "﷼"
        },
        "language": {
            "code": "ar",
            "name": "Arabic",
        },
        "flag": "https://restcountries.eu/data/yem.svg"
    },
    {
        "name": "Zambia",
        "code": "ZM",
        "capital": "Lusaka",
        "region": "AF",
        "currency": {
            "code": "ZMW",
            "name": "Zambian kwacha",
            "symbol": "ZK"
        },
        "language": {
            "code": "en",
            "name": "English",
        },
        "flag": "https://restcountries.eu/data/zmb.svg"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW",
        "capital": "Harare",
        "region": "AF",
        "currency": {
            "code": "BWP",
            "name": "Botswana pula",
            "symbol": "P"
        },
        "language": {
            "code": "en",
            "iso639_2": "eng",
            "name": "English",
            "nativeName": "English"
        },
        "flag": "https://restcountries.eu/data/zwe.svg"
    }
];


export let countryGPS = [
    {
        "country": "AD",
        "latitude": 42.546245,
        "longitude": 1.601554,
        "name": "Andorra"
    },
    {
        "country": "AE",
        "latitude": 23.424076,
        "longitude": 53.847818,
        "name": "United Arab Emirates"
    },
    {
        "country": "AF",
        "latitude": 33.93911,
        "longitude": 67.709953,
        "name": "Afghanistan"
    },
    {
        "country": "AG",
        "latitude": 17.060816,
        "longitude": -61.796428,
        "name": "Antigua and Barbuda"
    },
    {
        "country": "AI",
        "latitude": 18.220554,
        "longitude": -63.068615,
        "name": "Anguilla"
    },
    {
        "country": "AL",
        "latitude": 41.153332,
        "longitude": 20.168331,
        "name": "Albania"
    },
    {
        "country": "AM",
        "latitude": 40.069099,
        "longitude": 45.038189,
        "name": "Armenia"
    },
    {
        "country": "AN",
        "latitude": 12.226079,
        "longitude": -69.060087,
        "name": "Netherlands Antilles"
    },
    {
        "country": "AO",
        "latitude": -11.202692,
        "longitude": 17.873887,
        "name": "Angola"
    },
    {
        "country": "AQ",
        "latitude": -75.250973,
        "longitude": -0.071389,
        "name": "Antarctica"
    },
    {
        "country": "AR",
        "latitude": -38.416097,
        "longitude": -63.616672,
        "name": "Argentina"
    },
    {
        "country": "AS",
        "latitude": -14.270972,
        "longitude": -170.132217,
        "name": "American Samoa"
    },
    {
        "country": "AT",
        "latitude": 47.516231,
        "longitude": 14.550072,
        "name": "Austria"
    },
    {
        "country": "AU",
        "latitude": -25.274398,
        "longitude": 133.775136,
        "name": "Australia"
    },
    {
        "country": "AW",
        "latitude": 12.52111,
        "longitude": -69.968338,
        "name": "Aruba"
    },
    {
        "country": "AZ",
        "latitude": 40.143105,
        "longitude": 47.576927,
        "name": "Azerbaijan"
    },
    {
        "country": "BA",
        "latitude": 43.915886,
        "longitude": 17.679076,
        "name": "Bosnia and Herzegovina"
    },
    {
        "country": "BB",
        "latitude": 13.193887,
        "longitude": -59.543198,
        "name": "Barbados"
    },
    {
        "country": "BD",
        "latitude": 23.684994,
        "longitude": 90.356331,
        "name": "Bangladesh"
    },
    {
        "country": "BE",
        "latitude": 50.503887,
        "longitude": 4.469936,
        "name": "Belgium"
    },
    {
        "country": "BF",
        "latitude": 12.238333,
        "longitude": -1.561593,
        "name": "Burkina Faso"
    },
    {
        "country": "BG",
        "latitude": 42.733883,
        "longitude": 25.48583,
        "name": "Bulgaria"
    },
    {
        "country": "BH",
        "latitude": 25.930414,
        "longitude": 50.637772,
        "name": "Bahrain"
    },
    {
        "country": "BI",
        "latitude": -3.373056,
        "longitude": 29.918886,
        "name": "Burundi"
    },
    {
        "country": "BJ",
        "latitude": 9.30769,
        "longitude": 2.315834,
        "name": "Benin"
    },
    {
        "country": "BM",
        "latitude": 32.321384,
        "longitude": -64.75737,
        "name": "Bermuda"
    },
    {
        "country": "BN",
        "latitude": 4.535277,
        "longitude": 114.727669,
        "name": "Brunei"
    },
    {
        "country": "BO",
        "latitude": -16.290154,
        "longitude": -63.588653,
        "name": "Bolivia"
    },
    {
        "country": "BR",
        "latitude": -14.235004,
        "longitude": -51.92528,
        "name": "Brazil"
    },
    {
        "country": "BS",
        "latitude": 25.03428,
        "longitude": -77.39628,
        "name": "Bahamas"
    },
    {
        "country": "BT",
        "latitude": 27.514162,
        "longitude": 90.433601,
        "name": "Bhutan"
    },
    {
        "country": "BV",
        "latitude": -54.423199,
        "longitude": 3.413194,
        "name": "Bouvet Island"
    },
    {
        "country": "BW",
        "latitude": -22.328474,
        "longitude": 24.684866,
        "name": "Botswana"
    },
    {
        "country": "BY",
        "latitude": 53.709807,
        "longitude": 27.953389,
        "name": "Belarus"
    },
    {
        "country": "BZ",
        "latitude": 17.189877,
        "longitude": -88.49765,
        "name": "Belize"
    },
    {
        "country": "CA",
        "latitude": 56.130366,
        "longitude": -106.346771,
        "name": "Canada"
    },
    {
        "country": "CC",
        "latitude": -12.164165,
        "longitude": 96.870956,
        "name": "Cocos [Keeling] Islands"
    },
    {
        "country": "CD",
        "latitude": -4.038333,
        "longitude": 21.758664,
        "name": "Congo"
    },
    {
        "country": "CD",
        "latitude": -4.038333,
        "longitude": 21.758664,
        "name": "Congo (Democratic Republic of the)"
    },
    {
        "country": "CD",
        "latitude": -4.038333,
        "longitude": 21.758664,
        "name": "Congo [DRC]"
    },
    {
        "country": "CF",
        "latitude": 6.611111,
        "longitude": 20.939444,
        "name": "Central African Republic"
    },
    {
        "country": "CG",
        "latitude": -0.228021,
        "longitude": 15.827659,
        "name": "Congo [Republic]"
    },
    {
        "country": "CH",
        "latitude": 46.818188,
        "longitude": 8.227512,
        "name": "Switzerland"
    },
    {
        "country": "CI",
        "latitude": 7.539989,
        "longitude": -5.54708,
        "name": "Côte d'Ivoire"
    },
    {
        "country": "CK",
        "latitude": -21.236736,
        "longitude": -159.777671,
        "name": "Cook Islands"
    },
    {
        "country": "CL",
        "latitude": -35.675147,
        "longitude": -71.542969,
        "name": "Chile"
    },
    {
        "country": "CM",
        "latitude": 7.369722,
        "longitude": 12.354722,
        "name": "Cameroon"
    },
    {
        "country": "CN",
        "latitude": 35.86166,
        "longitude": 104.195397,
        "name": "China"
    },
    {
        "country": "CO",
        "latitude": 4.570868,
        "longitude": -74.297333,
        "name": "Colombia"
    },
    {
        "country": "CR",
        "latitude": 9.748917,
        "longitude": -83.753428,
        "name": "Costa Rica"
    },
    {
        "country": "CU",
        "latitude": 21.521757,
        "longitude": -77.781167,
        "name": "Cuba"
    },
    {
        "country": "CV",
        "latitude": 16.002082,
        "longitude": -24.013197,
        "name": "Cape Verde"
    },
    {
        "country": "CX",
        "latitude": -10.447525,
        "longitude": 105.690449,
        "name": "Christmas Island"
    },
    {
        "country": "CY",
        "latitude": 35.126413,
        "longitude": 33.429859,
        "name": "Cyprus"
    },
    {
        "country": "CZ",
        "latitude": 49.817492,
        "longitude": 15.472962,
        "name": "Czech Republic"
    },
    {
        "country": "DE",
        "latitude": 51.165691,
        "longitude": 10.451526,
        "name": "Germany"
    },
    {
        "country": "DJ",
        "latitude": 11.825138,
        "longitude": 42.590275,
        "name": "Djibouti"
    },
    {
        "country": "DK",
        "latitude": 56.26392,
        "longitude": 9.501785,
        "name": "Denmark"
    },
    {
        "country": "DM",
        "latitude": 15.414999,
        "longitude": -61.370976,
        "name": "Dominica"
    },
    {
        "country": "DO",
        "latitude": 18.735693,
        "longitude": -70.162651,
        "name": "Dominican Republic"
    },
    {
        "country": "DZ",
        "latitude": 28.033886,
        "longitude": 1.659626,
        "name": "Algeria"
    },
    {
        "country": "EC",
        "latitude": -1.831239,
        "longitude": -78.183406,
        "name": "Ecuador"
    },
    {
        "country": "EE",
        "latitude": 58.595272,
        "longitude": 25.013607,
        "name": "Estonia"
    },
    {
        "country": "EG",
        "latitude": 26.820553,
        "longitude": 30.802498,
        "name": "Egypt"
    },
    {
        "country": "EH",
        "latitude": 24.215527,
        "longitude": -12.885834,
        "name": "Western Sahara"
    },
    {
        "country": "ER",
        "latitude": 15.179384,
        "longitude": 39.782334,
        "name": "Eritrea"
    },
    {
        "country": "ES",
        "latitude": 40.463667,
        "longitude": -3.74922,
        "name": "Spain"
    },
    {
        "country": "ET",
        "latitude": 9.145,
        "longitude": 40.489673,
        "name": "Ethiopia"
    },
    {
        "country": "FI",
        "latitude": 61.92411,
        "longitude": 25.748151,
        "name": "Finland"
    },
    {
        "country": "FJ",
        "latitude": -16.578193,
        "longitude": 179.414413,
        "name": "Fiji"
    },
    {
        "country": "FK",
        "latitude": -51.796253,
        "longitude": -59.523613,
        "name": "Falkland Islands [Islas Malvinas]"
    },
    {
        "country": "FM",
        "latitude": 7.425554,
        "longitude": 150.550812,
        "name": "Micronesia"
    },
    {
        "country": "FO",
        "latitude": 61.892635,
        "longitude": -6.911806,
        "name": "Faroe Islands"
    },
    {
        "country": "FR",
        "latitude": 46.227638,
        "longitude": 2.213749,
        "name": "France"
    },
    {
        "country": "GA",
        "latitude": -0.803689,
        "longitude": 11.609444,
        "name": "Gabon"
    },
    {
        "country": "GB",
        "latitude": 55.378051,
        "longitude": -3.435973,
        "name": "United Kingdom"
    },
    {
        "country": "GD",
        "latitude": 12.262776,
        "longitude": -61.604171,
        "name": "Grenada"
    },
    {
        "country": "GE",
        "latitude": 42.315407,
        "longitude": 43.356892,
        "name": "Georgia"
    },
    {
        "country": "GF",
        "latitude": 3.933889,
        "longitude": -53.125782,
        "name": "French Guiana"
    },
    {
        "country": "GG",
        "latitude": 49.465691,
        "longitude": -2.585278,
        "name": "Guernsey"
    },
    {
        "country": "GH",
        "latitude": 7.946527,
        "longitude": -1.023194,
        "name": "Ghana"
    },
    {
        "country": "GI",
        "latitude": 36.137741,
        "longitude": -5.345374,
        "name": "Gibraltar"
    },
    {
        "country": "GL",
        "latitude": 71.706936,
        "longitude": -42.604303,
        "name": "Greenland"
    },
    {
        "country": "GM",
        "latitude": 13.443182,
        "longitude": -15.310139,
        "name": "Gambia"
    },
    {
        "country": "GN",
        "latitude": 9.945587,
        "longitude": -9.696645,
        "name": "Guinea"
    },
    {
        "country": "GP",
        "latitude": 16.995971,
        "longitude": -62.067641,
        "name": "Guadeloupe"
    },
    {
        "country": "GQ",
        "latitude": 1.650801,
        "longitude": 10.267895,
        "name": "Equatorial Guinea"
    },
    {
        "country": "GR",
        "latitude": 39.074208,
        "longitude": 21.824312,
        "name": "Greece"
    },
    {
        "country": "GS",
        "latitude": -54.429579,
        "longitude": -36.587909,
        "name": "South Georgia and the South Sandwich Islands"
    },
    {
        "country": "GT",
        "latitude": 15.783471,
        "longitude": -90.230759,
        "name": "Guatemala"
    },
    {
        "country": "GU",
        "latitude": 13.444304,
        "longitude": 144.793731,
        "name": "Guam"
    },
    {
        "country": "GW",
        "latitude": 11.803749,
        "longitude": -15.180413,
        "name": "Guinea-Bissau"
    },
    {
        "country": "GY",
        "latitude": 4.860416,
        "longitude": -58.93018,
        "name": "Guyana"
    },
    {
        "country": "GZ",
        "latitude": 31.354676,
        "longitude": 34.308825,
        "name": "Gaza Strip"
    },
    {
        "country": "HK",
        "latitude": 22.396428,
        "longitude": 114.109497,
        "name": "Hong Kong"
    },
    {
        "country": "HM",
        "latitude": -53.08181,
        "longitude": 73.504158,
        "name": "Heard Island and McDonald Islands"
    },
    {
        "country": "HN",
        "latitude": 15.199999,
        "longitude": -86.241905,
        "name": "Honduras"
    },
    {
        "country": "HR",
        "latitude": 45.1,
        "longitude": 15.2,
        "name": "Croatia"
    },
    {
        "country": "HT",
        "latitude": 18.971187,
        "longitude": -72.285215,
        "name": "Haiti"
    },
    {
        "country": "HU",
        "latitude": 47.162494,
        "longitude": 19.503304,
        "name": "Hungary"
    },
    {
        "country": "ID",
        "latitude": -0.789275,
        "longitude": 113.921327,
        "name": "Indonesia"
    },
    {
        "country": "IE",
        "latitude": 53.41291,
        "longitude": -8.24389,
        "name": "Ireland"
    },
    {
        "country": "IL",
        "latitude": 31.046051,
        "longitude": 34.851612,
        "name": "Israel"
    },
    {
        "country": "IM",
        "latitude": 54.236107,
        "longitude": -4.548056,
        "name": "Isle of Man"
    },
    {
        "country": "IN",
        "latitude": 20.593684,
        "longitude": 78.96288,
        "name": "India"
    },
    {
        "country": "IO",
        "latitude": -6.343194,
        "longitude": 71.876519,
        "name": "British Indian Ocean Territory"
    },
    {
        "country": "IQ",
        "latitude": 33.223191,
        "longitude": 43.679291,
        "name": "Iraq"
    },
    {
        "country": "IR",
        "latitude": 32.427908,
        "longitude": 53.688046,
        "name": "Iran"
    },
    {
        "country": "IS",
        "latitude": 64.963051,
        "longitude": -19.020835,
        "name": "Iceland"
    },
    {
        "country": "IT",
        "latitude": 41.87194,
        "longitude": 12.56738,
        "name": "Italy"
    },
    {
        "country": "JE",
        "latitude": 49.214439,
        "longitude": -2.13125,
        "name": "Jersey"
    },
    {
        "country": "JM",
        "latitude": 18.109581,
        "longitude": -77.297508,
        "name": "Jamaica"
    },
    {
        "country": "JO",
        "latitude": 30.585164,
        "longitude": 36.238414,
        "name": "Jordan"
    },
    {
        "country": "JP",
        "latitude": 36.204824,
        "longitude": 138.252924,
        "name": "Japan"
    },
    {
        "country": "KE",
        "latitude": -0.023559,
        "longitude": 37.906193,
        "name": "Kenya"
    },
    {
        "country": "KG",
        "latitude": 41.20438,
        "longitude": 74.766098,
        "name": "Kyrgyzstan"
    },
    {
        "country": "KH",
        "latitude": 12.565679,
        "longitude": 104.990963,
        "name": "Cambodia"
    },
    {
        "country": "KI",
        "latitude": -3.370417,
        "longitude": -168.734039,
        "name": "Kiribati"
    },
    {
        "country": "KM",
        "latitude": -11.875001,
        "longitude": 43.872219,
        "name": "Comoros"
    },
    {
        "country": "KN",
        "latitude": 17.357822,
        "longitude": -62.782998,
        "name": "Saint Kitts and Nevis"
    },
    {
        "country": "KP",
        "latitude": 40.339852,
        "longitude": 127.510093,
        "name": "North Korea"
    },
    {
        "country": "KR",
        "latitude": 35.907757,
        "longitude": 127.766922,
        "name": "South Korea"
    },
    {
        "country": "KW",
        "latitude": 29.31166,
        "longitude": 47.481766,
        "name": "Kuwait"
    },
    {
        "country": "KY",
        "latitude": 19.513469,
        "longitude": -80.566956,
        "name": "Cayman Islands"
    },
    {
        "country": "KZ",
        "latitude": 48.019573,
        "longitude": 66.923684,
        "name": "Kazakhstan"
    },
    {
        "country": "LA",
        "latitude": 19.85627,
        "longitude": 102.495496,
        "name": "Laos"
    },
    {
        "country": "LB",
        "latitude": 33.854721,
        "longitude": 35.862285,
        "name": "Lebanon"
    },
    {
        "country": "LC",
        "latitude": 13.909444,
        "longitude": -60.978893,
        "name": "Saint Lucia"
    },
    {
        "country": "LI",
        "latitude": 47.166,
        "longitude": 9.555373,
        "name": "Liechtenstein"
    },
    {
        "country": "LK",
        "latitude": 7.873054,
        "longitude": 80.771797,
        "name": "Sri Lanka"
    },
    {
        "country": "LR",
        "latitude": 6.428055,
        "longitude": -9.429499,
        "name": "Liberia"
    },
    {
        "country": "LS",
        "latitude": -29.609988,
        "longitude": 28.233608,
        "name": "Lesotho"
    },
    {
        "country": "LT",
        "latitude": 55.169438,
        "longitude": 23.881275,
        "name": "Lithuania"
    },
    {
        "country": "LU",
        "latitude": 49.815273,
        "longitude": 6.129583,
        "name": "Luxembourg"
    },
    {
        "country": "LV",
        "latitude": 56.879635,
        "longitude": 24.603189,
        "name": "Latvia"
    },
    {
        "country": "LY",
        "latitude": 26.3351,
        "longitude": 17.228331,
        "name": "Libya"
    },
    {
        "country": "MA",
        "latitude": 31.791702,
        "longitude": -7.09262,
        "name": "Morocco"
    },
    {
        "country": "MC",
        "latitude": 43.750298,
        "longitude": 7.412841,
        "name": "Monaco"
    },
    {
        "country": "MD",
        "latitude": 47.411631,
        "longitude": 28.369885,
        "name": "Moldova"
    },
    {
        "country": "ME",
        "latitude": 42.708678,
        "longitude": 19.37439,
        "name": "Montenegro"
    },
    {
        "country": "MG",
        "latitude": -18.766947,
        "longitude": 46.869107,
        "name": "Madagascar"
    },
    {
        "country": "MH",
        "latitude": 7.131474,
        "longitude": 171.184478,
        "name": "Marshall Islands"
    },
    {
        "country": "MK",
        "latitude": 41.608635,
        "longitude": 21.745275,
        "name": "Macedonia [FYROM]"
    },
    {
        "country": "ML",
        "latitude": 17.570692,
        "longitude": -3.996166,
        "name": "Mali"
    },
    {
        "country": "MM",
        "latitude": 21.913965,
        "longitude": 95.956223,
        "name": "Myanmar [Burma]"
    },
    {
        "country": "MN",
        "latitude": 46.862496,
        "longitude": 103.846656,
        "name": "Mongolia"
    },
    {
        "country": "MO",
        "latitude": 22.198745,
        "longitude": 113.543873,
        "name": "Macau"
    },
    {
        "country": "MP",
        "latitude": 17.33083,
        "longitude": 145.38469,
        "name": "Northern Mariana Islands"
    },
    {
        "country": "MQ",
        "latitude": 14.641528,
        "longitude": -61.024174,
        "name": "Martinique"
    },
    {
        "country": "MR",
        "latitude": 21.00789,
        "longitude": -10.940835,
        "name": "Mauritania"
    },
    {
        "country": "MS",
        "latitude": 16.742498,
        "longitude": -62.187366,
        "name": "Montserrat"
    },
    {
        "country": "MT",
        "latitude": 35.937496,
        "longitude": 14.375416,
        "name": "Malta"
    },
    {
        "country": "MU",
        "latitude": -20.348404,
        "longitude": 57.552152,
        "name": "Mauritius"
    },
    {
        "country": "MV",
        "latitude": 3.202778,
        "longitude": 73.22068,
        "name": "Maldives"
    },
    {
        "country": "MW",
        "latitude": -13.254308,
        "longitude": 34.301525,
        "name": "Malawi"
    },
    {
        "country": "MX",
        "latitude": 23.634501,
        "longitude": -102.552784,
        "name": "Mexico"
    },
    {
        "country": "MY",
        "latitude": 4.210484,
        "longitude": 101.975766,
        "name": "Malaysia"
    },
    {
        "country": "MZ",
        "latitude": -18.665695,
        "longitude": 35.529562,
        "name": "Mozambique"
    },
    {
        "country": "NA",
        "latitude": -22.95764,
        "longitude": 18.49041,
        "name": "Namibia"
    },
    {
        "country": "NC",
        "latitude": -20.904305,
        "longitude": 165.618042,
        "name": "New Caledonia"
    },
    {
        "country": "NE",
        "latitude": 17.607789,
        "longitude": 8.081666,
        "name": "Niger"
    },
    {
        "country": "NF",
        "latitude": -29.040835,
        "longitude": 167.954712,
        "name": "Norfolk Island"
    },
    {
        "country": "NG",
        "latitude": 9.081999,
        "longitude": 8.675277,
        "name": "Nigeria"
    },
    {
        "country": "NI",
        "latitude": 12.865416,
        "longitude": -85.207229,
        "name": "Nicaragua"
    },
    {
        "country": "NL",
        "latitude": 52.132633,
        "longitude": 5.291266,
        "name": "Netherlands"
    },
    {
        "country": "NO",
        "latitude": 60.472024,
        "longitude": 8.468946,
        "name": "Norway"
    },
    {
        "country": "NP",
        "latitude": 28.394857,
        "longitude": 84.124008,
        "name": "Nepal"
    },
    {
        "country": "NR",
        "latitude": -0.522778,
        "longitude": 166.931503,
        "name": "Nauru"
    },
    {
        "country": "NU",
        "latitude": -19.054445,
        "longitude": -169.867233,
        "name": "Niue"
    },
    {
        "country": "NZ",
        "latitude": -40.900557,
        "longitude": 174.885971,
        "name": "New Zealand"
    },
    {
        "country": "OM",
        "latitude": 21.512583,
        "longitude": 55.923255,
        "name": "Oman"
    },
    {
        "country": "PA",
        "latitude": 8.537981,
        "longitude": -80.782127,
        "name": "Panama"
    },
    {
        "country": "PE",
        "latitude": -9.189967,
        "longitude": -75.015152,
        "name": "Peru"
    },
    {
        "country": "PF",
        "latitude": -17.679742,
        "longitude": -149.406843,
        "name": "French Polynesia"
    },
    {
        "country": "PG",
        "latitude": -6.314993,
        "longitude": 143.95555,
        "name": "Papua New Guinea"
    },
    {
        "country": "PH",
        "latitude": 12.879721,
        "longitude": 121.774017,
        "name": "Philippines"
    },
    {
        "country": "PK",
        "latitude": 30.375321,
        "longitude": 69.345116,
        "name": "Pakistan"
    },
    {
        "country": "PL",
        "latitude": 51.919438,
        "longitude": 19.145136,
        "name": "Poland"
    },
    {
        "country": "PM",
        "latitude": 46.941936,
        "longitude": -56.27111,
        "name": "Saint Pierre and Miquelon"
    },
    {
        "country": "PN",
        "latitude": -24.703615,
        "longitude": -127.439308,
        "name": "Pitcairn Islands"
    },
    {
        "country": "PR",
        "latitude": 18.220833,
        "longitude": -66.590149,
        "name": "Puerto Rico"
    },
    {
        "country": "PS",
        "latitude": 31.952162,
        "longitude": 35.233154,
        "name": "Palestinian Territories"
    },
    {
        "country": "PT",
        "latitude": 39.399872,
        "longitude": -8.224454,
        "name": "Portugal"
    },
    {
        "country": "PW",
        "latitude": 7.51498,
        "longitude": 134.58252,
        "name": "Palau"
    },
    {
        "country": "PY",
        "latitude": -23.442503,
        "longitude": -58.443832,
        "name": "Paraguay"
    },
    {
        "country": "QA",
        "latitude": 25.354826,
        "longitude": 51.183884,
        "name": "Qatar"
    },
    {
        "country": "RE",
        "latitude": -21.115141,
        "longitude": 55.536384,
        "name": "Réunion"
    },
    {
        "country": "RO",
        "latitude": 45.943161,
        "longitude": 24.96676,
        "name": "Romania"
    },
    {
        "country": "RS",
        "latitude": 44.016521,
        "longitude": 21.005859,
        "name": "Serbia"
    },
    {
        "country": "RU",
        "latitude": 61.52401,
        "longitude": 105.318756,
        "name": "Russia"
    },
    {
        "country": "RW",
        "latitude": -1.940278,
        "longitude": 29.873888,
        "name": "Rwanda"
    },
    {
        "country": "SA",
        "latitude": 23.885942,
        "longitude": 45.079162,
        "name": "Saudi Arabia"
    },
    {
        "country": "SB",
        "latitude": -9.64571,
        "longitude": 160.156194,
        "name": "Solomon Islands"
    },
    {
        "country": "SC",
        "latitude": -4.679574,
        "longitude": 55.491977,
        "name": "Seychelles"
    },
    {
        "country": "SD",
        "latitude": 12.862807,
        "longitude": 30.217636,
        "name": "Sudan"
    },
    {
        "country": "SE",
        "latitude": 60.128161,
        "longitude": 18.643501,
        "name": "Sweden"
    },
    {
        "country": "SG",
        "latitude": 1.352083,
        "longitude": 103.819836,
        "name": "Singapore"
    },
    {
        "country": "SH",
        "latitude": -24.143474,
        "longitude": -10.030696,
        "name": "Saint Helena"
    },
    {
        "country": "SI",
        "latitude": 46.151241,
        "longitude": 14.995463,
        "name": "Slovenia"
    },
    {
        "country": "SJ",
        "latitude": 77.553604,
        "longitude": 23.670272,
        "name": "Svalbard and Jan Mayen"
    },
    {
        "country": "SK",
        "latitude": 48.669026,
        "longitude": 19.699024,
        "name": "Slovakia"
    },
    {
        "country": "SL",
        "latitude": 8.460555,
        "longitude": -11.779889,
        "name": "Sierra Leone"
    },
    {
        "country": "SM",
        "latitude": 43.94236,
        "longitude": 12.457777,
        "name": "San Marino"
    },
    {
        "country": "SN",
        "latitude": 14.497401,
        "longitude": -14.452362,
        "name": "Senegal"
    },
    {
        "country": "SO",
        "latitude": 5.152149,
        "longitude": 46.199616,
        "name": "Somalia"
    },
    {
        "country": "SR",
        "latitude": 3.919305,
        "longitude": -56.027783,
        "name": "Suriname"
    },
    {
        "country": "ST",
        "latitude": 0.18636,
        "longitude": 6.613081,
        "name": "São Tomé and Príncipe"
    },
    {
        "country": "SV",
        "latitude": 13.794185,
        "longitude": -88.89653,
        "name": "El Salvador"
    },
    {
        "country": "SY",
        "latitude": 34.802075,
        "longitude": 38.996815,
        "name": "Syria"
    },
    {
        "country": "SZ",
        "latitude": -26.522503,
        "longitude": 31.465866,
        "name": "Swaziland"
    },
    {
        "country": "TC",
        "latitude": 21.694025,
        "longitude": -71.797928,
        "name": "Turks and Caicos Islands"
    },
    {
        "country": "TD",
        "latitude": 15.454166,
        "longitude": 18.732207,
        "name": "Chad"
    },
    {
        "country": "TF",
        "latitude": -49.280366,
        "longitude": 69.348557,
        "name": "French Southern Territories"
    },
    {
        "country": "TG",
        "latitude": 8.619543,
        "longitude": 0.824782,
        "name": "Togo"
    },
    {
        "country": "TH",
        "latitude": 15.870032,
        "longitude": 100.992541,
        "name": "Thailand"
    },
    {
        "country": "TJ",
        "latitude": 38.861034,
        "longitude": 71.276093,
        "name": "Tajikistan"
    },
    {
        "country": "TK",
        "latitude": -8.967363,
        "longitude": -171.855881,
        "name": "Tokelau"
    },
    {
        "country": "TL",
        "latitude": -8.874217,
        "longitude": 125.727539,
        "name": "Timor-Leste"
    },
    {
        "country": "TM",
        "latitude": 38.969719,
        "longitude": 59.556278,
        "name": "Turkmenistan"
    },
    {
        "country": "TN",
        "latitude": 33.886917,
        "longitude": 9.537499,
        "name": "Tunisia"
    },
    {
        "country": "TO",
        "latitude": -21.178986,
        "longitude": -175.198242,
        "name": "Tonga"
    },
    {
        "country": "TR",
        "latitude": 38.963745,
        "longitude": 35.243322,
        "name": "Turkey"
    },
    {
        "country": "TT",
        "latitude": 10.691803,
        "longitude": -61.222503,
        "name": "Trinidad and Tobago"
    },
    {
        "country": "TV",
        "latitude": -7.109535,
        "longitude": 177.64933,
        "name": "Tuvalu"
    },
    {
        "country": "TW",
        "latitude": 23.69781,
        "longitude": 120.960515,
        "name": "Taiwan"
    },
    {
        "country": "TZ",
        "latitude": -6.369028,
        "longitude": 34.888822,
        "name": "Tanzania"
    },
    {
        "country": "UA",
        "latitude": 48.379433,
        "longitude": 31.16558,
        "name": "Ukraine"
    },
    {
        "country": "UG",
        "latitude": 1.373333,
        "longitude": 32.290275,
        "name": "Uganda"
    },
    {
        "country": "US",
        "latitude": 37.09024,
        "longitude": -95.712891,
        "name": "United States"
    },
    {
        "country": "UY",
        "latitude": -32.522779,
        "longitude": -55.765835,
        "name": "Uruguay"
    },
    {
        "country": "UZ",
        "latitude": 41.377491,
        "longitude": 64.585262,
        "name": "Uzbekistan"
    },
    {
        "country": "VA",
        "latitude": 41.902916,
        "longitude": 12.453389,
        "name": "Vatican City"
    },
    {
        "country": "VC",
        "latitude": 12.984305,
        "longitude": -61.287228,
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "country": "VE",
        "latitude": 6.42375,
        "longitude": -66.58973,
        "name": "Venezuela"
    },
    {
        "country": "VG",
        "latitude": 18.420695,
        "longitude": -64.639968,
        "name": "British Virgin Islands"
    },
    {
        "country": "VI",
        "latitude": 18.335765,
        "longitude": -64.896335,
        "name": "U.S. Virgin Islands"
    },
    {
        "country": "VN",
        "latitude": 14.058324,
        "longitude": 108.277199,
        "name": "Vietnam"
    },
    {
        "country": "VU",
        "latitude": -15.376706,
        "longitude": 166.959158,
        "name": "Vanuatu"
    },
    {
        "country": "WF",
        "latitude": -13.768752,
        "longitude": -177.156097,
        "name": "Wallis and Futuna"
    },
    {
        "country": "WS",
        "latitude": -13.759029,
        "longitude": -172.104629,
        "name": "Samoa"
    },
    {
        "country": "XK",
        "latitude": 42.602636,
        "longitude": 20.902977,
        "name": "Kosovo"
    },
    {
        "country": "YE",
        "latitude": 15.552727,
        "longitude": 48.516388,
        "name": "Yemen"
    },
    {
        "country": "YT",
        "latitude": -12.8275,
        "longitude": 45.166244,
        "name": "Mayotte"
    },
    {
        "country": "ZA",
        "latitude": -30.559482,
        "longitude": 22.937506,
        "name": "South Africa"
    },
    {
        "country": "ZM",
        "latitude": -13.133897,
        "longitude": 27.849332,
        "name": "Zambia"
    },
    {
        "country": "ZW",
        "latitude": -19.015438,
        "longitude": 29.154857,
        "name": "Zimbabwe"
    }
]

export const findCountryGPS = (countryName: string): {
    country: string;
    latitude: number;
    longitude: number;
    name: string;
} | undefined => {
    let f = countryGPS.filter(c => c.name === countryName);
    if (f && f[0]) return f[0];
    return undefined;
}

export const findRegionGPS = (regionName: string): IRegion | undefined => {
    let f = Regions.filter(r => r.code === regionName);
    if (f && f[0]) return f[0];
    return undefined;
}
