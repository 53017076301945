// https://community.iotnxt.com/docs/commander/integration/theming-api/

// https://community.iotnxt.com/docs/commander/integration/entity-access-api/

// by Rouan van der Ende Fri 08 Jul 2022

import { IAPIOptions } from "./main"


export class APIIoTnxtDAPIAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }


    CommanderConfig = {
        GetDynamicConfig: async (): Promise<unknown> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/CommanderConfig/GetDynamicConfig/`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        }
    }

    EntityAccessGroup = {
        GetList: async (): Promise<DaPIAppResponse<IDapiEntityAccessGroup[]>> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/EntityAccessGroup/GetList`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        }
    }

    EntityAccess = {
        GetPathAccess: async (params: {
            path: string
            recursive: true,
            /** like "698e9150-d9ce-4697-905a-1f26b81a74b2" */
            sid: string
            sidType: "groupId" | string
        }): Promise<DaPIAppResponse<IEntityPathAccess[]>> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/EntityAccess/GetPathAccess/`, {
                method: 'post',
                headers: {
                    authorization: this.options.authorization,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(params)
            }).then((res) => res.json());
        },
        /** from a username get the user's entity access groups */
        GetEntityAccessGroups: async (params: { username: string }): Promise<DaPIAppResponse<IDapiEntityAccessGroup[]>> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/EntityAccess/GetEntityAccessGroups`, {
                method: 'post',
                headers: {
                    authorization: this.options.authorization,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(params)
            }).then((res) => res.json());
        }
    }

    EntityTree = {
        GetAccountChildren: async (): Promise<DaPIAppResponse<IEntity[]>> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/EntityTree/GetAccountChildren/`, {
                method: 'get',
                headers: {
                    authorization: this.options.authorization,
                },
            }).then((res) => res.json());
        }
    }

    TreeBaseEntity = {
        GetChildren: async (params: { entityTypes: string[], id: string }): Promise<DaPIAppResponse<IEntityFull[]>> => {
            return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/dapi/v1/TreeBaseEntity/GetChildren`, {
                method: 'post',
                headers: {
                    authorization: this.options.authorization,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(params)
            }).then((res) => res.json());
        }
    }
}


export interface DaPIAppResponse<T> {
    "result": T,
    "messageType": "OK" | string,
    "state": any | null,
    "sourceMessageID": string
}

export interface IDapiEntityAccessGroup {
    id: string
    name: string
}


export type TEntityType = "GroupEntity" | "Site" | string
export interface IEntitySmall {
    entityId: string
    entityType: TEntityType
}
export interface IEntity extends IEntitySmall {
    name: string
    idPath?: string
    parentId: string
    hasChildren?: boolean
    ancestors: IEntitySmall[]
    children?: any[]
}

export interface IEntityFull extends IEntity {
    rowVersion: string,
    associatedViewId: string
    entityId: string
    entityType: TEntityType,
    uiMayEdit: boolean
    managedBy: string
    ancestors: IEntitySmall[]
    name: string
    parentId: string,
    accountId: string
    tags: string[]
    canHaveChildren: boolean
    level: number
    children?: any[]
}

export interface IEntityPathAccess {
    path:string
    sidType: 'groupId'
    sid: string
    grant: 'allowed'
}
