import React from 'react';
import {
    Typography,
    Switch,
    Stack,
    Card,
    CardContent,
} from '@mui/material';

// TODO
// import { Sparkline } from 'mui-plus';

import { WarningTwoTone } from '@mui/icons-material';

export interface WidgetProps {
    name?: string,
    label?: string
    data?: number[]
    type?: "boolean" | "number" | "string"
    on?: boolean
    onChange?: (e: any) => void
    busy?: boolean
    schedule?: Schedule
    thresholds?: Threshold[]
    value?: number | boolean | string | undefined | any
    unit?: string
    message?: string
    /** round number values to digit length */
    toFixed?: number
}

export interface Schedule {
    on: string
    off: string
}

export interface Threshold {
    min: number
    max: number
    level: 'error' | 'warning' | 'info',
    color: 'primary' | 'secondary' | 'error' | 'warning'
    message: string
}

export interface WidgetState { }

export class Widget extends React.Component<WidgetProps, WidgetState> {
    state: WidgetState = {};

    renderMessage() {
        const t = this.checkThresholds();

        if (t) {
            return <Typography color={t.color}>{t.message}</Typography>;
        }

        if (this.props.schedule) {
            return <Typography>{this.props.schedule.on} - {this.props.schedule.off}</Typography>;
        }

        if (this.props.message) {
            return (
                <Typography
                    sx={{ opacity: 0.4 }}
                >
                    {this.props.message}
                </Typography>
            );
        }

        return (<Typography
            sx={{ opacity: 0.4 }}
        >
            no schedule
        </Typography>);
    }

    checkThresholds() {
        if (!this.props.thresholds) return;
        if (this.props.type !== 'number') return;
        if (this.props.value === undefined) return;

        for (var t of this.props.thresholds) {
            if ((t.max > this.props.value) && (
                t.min < this.props.value
            )) {
                return t;
            }
        }
    }

    renderValue() {
        const t = this.checkThresholds();

        if (this.props.type === 'number') {
            return (<Typography
                variant="h4"
                color={t?.color}
            >
                {this.props.toFixed && typeof this.props.value === 'number' ? this.props.value?.toFixed(this.props.toFixed) : this.props.value}
                {this.props.unit}
                {t?.level === 'warning' && <WarningTwoTone
                    sx={{ position: 'relative', bottom: -3, left: 6 }}
                />}
            </Typography>);
        }

        if (this.props.type === 'boolean') {
            return (<Typography
                color={this.props.on ? "secondary" : 'error'}
                variant="h4"
            >
                {this.props.on ? "On" : "Off"}
                &nbsp;{this.props.unit}
            </Typography>);
        }

        if (this.props.type === 'string') {
            return (<Typography
                variant="h6"
            >
                {this.props.value}{this.props.unit}
            </Typography>);
        }

        return <Typography variant="h4">type?</Typography>;
    }

    render() {
        if (this.props.busy) {
            return (<Card sx={{ width: 182, m: 0.5, border: '1px solid gray' }}>
                <CardContent sx={{ position: 'relative' }}>
                    <Stack direction="column">
                        <Typography
                            variant="subtitle2"
                        >
                            {this.props.label}
                        </Typography>

                        <Typography
                            sx={{ opacity: 0.25 }}
                            variant="h4"
                        >

                        </Typography>

                        <Typography
                            sx={{ opacity: 0.4 }}
                        >
                            connecting
                        </Typography>

                    </Stack>
                </CardContent>
            </Card>);
        }

        return (<Card sx={{ width: 182, m: 0.5 }}>
            <CardContent sx={{ position: 'relative' }}>
                <Stack direction="column">
                    <Typography
                        variant="subtitle2"
                    >
                        {this.props.label}
                    </Typography>

                    {(this.props.type === 'boolean' && this.props.onChange) &&
                        <Switch
                            name={this.props.name}
                            checked={this.props.on}
                            onChange={this.props.onChange}
                            color={this.props.on ? "primary" : 'error'}
                            sx={{
                                position: 'absolute',
                                top: 6,
                                right: 3
                            }}
                        />}

                    {this.renderValue()}
                    {this.renderMessage()}

                            Sparkline TODO

                    {/* {this.props.data && <Sparkline
                        sx={{ mt: 2 }}
                        data={this.props.data}
                        color={t?.color}
                    />} */}

                </Stack>
            </CardContent>
        </Card>);
    }
}
