import { Paper } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { INodeSettings } from "../../../interfaces";
import { checkToken, catchToken, authSPA, IToken } from "./";

export interface IAppSettings {
  authenticationServer: string;
  webApiUrl: string;
  clientUrl: string;
  clientId: string;
  nodeapisettings: INodeSettings
}

export interface IOAuthState {
  hasValidToken?: boolean;
}

export interface IOAuthProps {
  appsettings?: IAppSettings;
  children?: React.ReactNode;
  onAuth: (auth: { token: IToken; authorization: string }) => void;
}

export class OAuth extends React.Component<IOAuthProps, IOAuthState> {
  state: IOAuthState = {};

  componentDidUpdate = () => {
    let token = checkToken(localStorage.getItem("token"));

    if (!token) {
      console.log('could not retrieve valid token.')
      localStorage.removeItem("token");
    }

    // if we did get a new token use it.
    const newtoken = (!token) ? catchToken() : undefined;
    if (newtoken) token = newtoken;
    if (this.props.appsettings && !token) {
      authSPA({
        scope: "role api permissions",
        client_id: this.props.appsettings.clientId,
        server: this.props.appsettings.authenticationServer,
      });
    }

    if (token && !this.state.hasValidToken) {
      this.props.onAuth({
        token,
        authorization: `${token.token_type} ${token.access_token}`,
      });
      this.setState({ hasValidToken: true });
    }
  };

  render() {
    let loadingStyle = { height: 2000 }
    if (!this.props.appsettings)
      return (
        <Paper sx={loadingStyle}>
          <LinearProgress />
        </Paper>
      );
    if (this.state.hasValidToken) return <>{this.props.children}</>;
    return (
      <Paper sx={loadingStyle}>
        <LinearProgress />
      </Paper>
    );
  }
}
