// Title: Vodafone VSpaces QA API
// Auto Generated API using OpenSwag on 2022-04-12T07:41:38.278Z
// Servers: https://vspaces.vodafone.qa.iotnxt.io

import { IToken } from '../components/utils/oauth/itoken';
import {
    DigitwinPaginationFilter,
    ICloudServiceActionResult,
    ICloudServiceActionResultPaged,
    HistoryRecord,
    IThemeResponse
} from './interfaces';

export class APIVodafoneVSpacesAPI {
    authorization = '';
    baseUrl = '';

    constructor(token?: IToken) {
        if (token) {
            this.authorization = `${token.token_type} ${token.access_token}`;
        }
    }

    private rest_get_apiAppSettingsGet = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/AppSettings/Get`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinUploadCsv = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/UploadCsv`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinGetPagedCsvInstances = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/GetPagedCsvInstances`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    private rest_put_apiv1VSpacesDigitwinApproveCsvInstance = async (params: { id: string, }): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/ApproveCsvInstance?${encodeQueryData(params)}`, {
            method: 'put',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_delete_apiv1VSpacesDigitwinRemoveCsvInstance = async (params: { id: string, }): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/RemoveCsvInstance?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_put_apiv1VSpacesDigitwinApproveDraftCsvInstances = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/ApproveDraftCsvInstances`, {
            method: 'put',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_delete_apiv1VSpacesDigitwinRemoveErrorCsvInstances = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/RemoveErrorCsvInstances`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_get_apiv1VSpacesDigitwinGetInstance = async (params: { id: string, }): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/GetInstance?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinGetPagedDigitwinInstances = async <T>(query: DigitwinPaginationFilter): Promise<ICloudServiceActionResultPaged<T>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/GetPagedDigitwinInstances`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify(query),
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinAddOrUpdateInstance = async <T>(body: T): Promise<ICloudServiceActionResult<T>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/AddOrUpdateInstance`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify(body),
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinAddOrUpdateManyInstances = async <T>(body: T[]): Promise<ICloudServiceActionResult<T[]>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/AddOrUpdateManyInstances`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify(body),
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinRemoveInstance = async <T>(body: T): Promise<ICloudServiceActionResult<T>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/RemoveInstance`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify(body)
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesDigitwinGetPagedUpdateHistory = async <T>(query: DigitwinPaginationFilter): Promise<ICloudServiceActionResultPaged<HistoryRecord<T>[]>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/GetPagedUpdateHistory`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify(query),
        }).then((res) => res.json());
    };

    private rest_get_apiv1VSpacesDigitwinGetUpdateHistory = async (params: { id: string, }): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesDigitwin/GetUpdateHistory?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_get_apiv1VSpacesFrontendGetJsonProperties = async (params: { csvOnly: boolean, }): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesFrontend/GetJsonProperties?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_get_apiv1VSpacesFrontendGetThemeProperties = async (): Promise<ICloudServiceActionResult<IThemeResponse>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesFrontend/GetThemeProperties`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_get_apiv1VSpacesFrontendGetUserCustomData = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesFrontend/GetUserCustomData`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
        }).then((res) => res.json());
    };

    private rest_post_apiv1VSpacesFrontendSetUserCustomData = async (): Promise<ICloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/v1/VSpacesFrontend/SetUserCustomData`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    api = {
        AppSettings: {
            Get: this.rest_get_apiAppSettingsGet
        },
        v1: {
            VSpacesDigitwin: {
                UploadCsv: this.rest_post_apiv1VSpacesDigitwinUploadCsv,
                GetPagedCsvInstances: this.rest_post_apiv1VSpacesDigitwinGetPagedCsvInstances,
                ApproveCsvInstance: this.rest_put_apiv1VSpacesDigitwinApproveCsvInstance,
                RemoveCsvInstance: this.rest_delete_apiv1VSpacesDigitwinRemoveCsvInstance,
                ApproveDraftCsvInstances: this.rest_put_apiv1VSpacesDigitwinApproveDraftCsvInstances,
                RemoveErrorCsvInstances: this.rest_delete_apiv1VSpacesDigitwinRemoveErrorCsvInstances,
                GetInstance: this.rest_get_apiv1VSpacesDigitwinGetInstance,
                GetPagedDigitwinInstances: this.rest_post_apiv1VSpacesDigitwinGetPagedDigitwinInstances,
                AddOrUpdateInstance: this.rest_post_apiv1VSpacesDigitwinAddOrUpdateInstance,
                AddOrUpdateManyInstances: this.rest_post_apiv1VSpacesDigitwinAddOrUpdateManyInstances,
                RemoveInstance: this.rest_post_apiv1VSpacesDigitwinRemoveInstance,
                GetPagedUpdateHistory: this.rest_post_apiv1VSpacesDigitwinGetPagedUpdateHistory,
                GetUpdateHistory: this.rest_get_apiv1VSpacesDigitwinGetUpdateHistory
            },
            VSpacesFrontend: {
                GetJsonProperties: this.rest_get_apiv1VSpacesFrontendGetJsonProperties,
                GetThemeProperties: this.rest_get_apiv1VSpacesFrontendGetThemeProperties,
                GetUserCustomData: this.rest_get_apiv1VSpacesFrontendGetUserCustomData,
                SetUserCustomData: this.rest_post_apiv1VSpacesFrontendSetUserCustomData
            }
        }
    }

}

export function encodeQueryData(data: Record<string, unknown>): string {
    const ret = [];
    for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d] as string)}`);
    return ret.join('&');
}