import React from 'react';

import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';

import {
  Box,
  CircularProgress,
} from '@mui/material'

import {
  IAppSettings,
  Main,
} from './components';

import { IGridConfigType, INodeSettings, IToken } from './interfaces';
import VSpaceMain from './vspace/vspace_main';
import { APIVodafoneVSpacesAPI } from './vspace/api';
import RouterWrapper from './components/wrapper/wrapper';
import {
  IProfile,
  IGroup,
  IDapiEntityAccessGroup,
  IEntityPathAccess,
  IEntity
} from './commanderapi';

import { getInitialData, ISimplifiedAccessRules } from './vspace/getInitialData';

export interface IAppState {
  title: string;
  appsettings?: IAppSettings;
  token?: IToken,
  profile?: IProfile
  groups?: IGroup[]
  entityAccessGroups?: IDapiEntityAccessGroup[]
  entityAccessGroupData?: {
    entityPathAccess: IEntityPathAccess[];
    entityAccessGroup: IDapiEntityAccessGroup;
  }[]
  entityList?: IEntity[]
  simplifiedAccessRules?: ISimplifiedAccessRules
  /** the names of the entities the user can access. Admin ignores this. */
  userPermissionEntities?: string[]
}



export const config = {
  /** uses commander api proxy with own auth token */
  baseUrl: '/nodeapi',
  /** uses commander api proxy with robot token */
  baseUrlRobot: '/nodeapirobot'
}

export default class App extends React.Component<{}, IAppState> {
  state: IAppState = {
    title: 'VSpaces'
  }

  api = new APIVodafoneVSpacesAPI();

  componentDidMount = async () => {
    document.title = this.state.title;
    let appsettings: IAppSettings = await fetch(`/nodeapisettings`)
      .then(r => r.json())
      .then((nodeapisettings: INodeSettings) => {
        return {
          authenticationServer: nodeapisettings.ServiceOptions.AuthenticationServer,
          webApiUrl: window.location.origin,
          clientUrl: window.location.origin,
          clientId: nodeapisettings.ServiceOptions.ClientId,
          nodeapisettings
        } as IAppSettings
      });

    this.setState({ appsettings });
  };



  render() {
    if (!this.state.appsettings) return <Box sx={{ mt: 2, ml: 2 }}><CircularProgress /></Box>

    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Main
                fullscreen={false}
                title={"VSpaces"}
                appsettings={this.state.appsettings}
                onAuth={async (auth) => {

                  if (!this.state.appsettings) throw Error('nodeapisettings missing.')

                  this.api.authorization = `${auth.token.token_type} ${auth.token.access_token}`;
                  let data = await getInitialData(
                    {
                      authorization: `${auth.token.token_type} ${auth.token.access_token}`,
                      baseUrl: config.baseUrl,
                      baseUrlRobot: config.baseUrlRobot,
                      nodeapisettings: this.state.appsettings?.nodeapisettings
                    })
                  this.setState(data);
                  this.setState({ token: auth.token })
                  console.log('AUTHED! ', auth);
                }}
              />}
          >

            {this.state.token
              // LOGGED IN
              ? <>
                <Route path="/" element={<RouterWrapper
                  appState={this.state}
                  nodesettings={this.state.appsettings.nodeapisettings}
                  token={this.state.token}
                />}>
                  <Route index element={<VSpaceMain token={this.state.token} appState={this.state} />} />
                  <Route path="*" element={<VSpaceMain token={this.state.token} appState={this.state} />} />
                </Route>
              </>
              // NOT LOGGED IN
              : <>
                <Route index element={<Box sx={{ mt: 2, ml: 2 }}><CircularProgress /></Box>} />
                <Route path="*" element={<Box sx={{ mt: 2, ml: 2 }}><CircularProgress /></Box>} />
              </>}

          </Route>
        </Routes>
      </BrowserRouter >
    );
  }
}