import * as React from 'react';

import {
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material';

import { IToken } from '../components';
import { APIVodafoneVSpacesAPI } from './api';
import { ICloudServiceActionResultPaged, HistoryRecord } from './interfaces';
import { IVSpaceProperty } from './interfaces_vspaces';
import { HumanReadableHistoryDiff } from './vspace_history';
import moment from 'moment';

interface VSpaceHistoryMostRecentProps {
    token: IToken;
}

interface VSpaceHistoryMostRecentState {
    timestampFormat: boolean
    historyResponse?: ICloudServiceActionResultPaged<HistoryRecord<IVSpaceProperty>[]>;
}

export class VSpaceHistoryMostRecent extends React.Component<VSpaceHistoryMostRecentProps, VSpaceHistoryMostRecentState> {
    state: VSpaceHistoryMostRecentState = {
        timestampFormat: true
    }
    api: APIVodafoneVSpacesAPI = new APIVodafoneVSpacesAPI();

    componentDidMount = async () => {
        this.api.authorization = `${this.props.token.token_type} ${this.props.token.access_token}`;

        let historyResponse = await this.api.api.v1.VSpacesDigitwin
            .GetPagedUpdateHistory<IVSpaceProperty>(
                {
                    "orderByQuery": "",
                    "pageNumber": 1,
                    "pageSize": 1,
                    "selectQuery": "",
                    "whereQuery": {}
                }
            );
        this.setState({ historyResponse });
    }

    render() {
        if (!this.state.historyResponse) return <CircularProgress size="small" />;

        if (this.state.historyResponse.isSuccessful === false || !this.state.historyResponse.data) {
            return <Alert severity='error'>
                {this.state.historyResponse.message} {this.state.historyResponse.exceptionMessage}
            </Alert>
        }

        if (!this.state.historyResponse.data) return 'no recent history';
        if (!this.state.historyResponse.data[0]) return 'no recent history';

        return (<Typography sx={{ pl: 4, p: 1 }} variant="caption" >
            {HumanReadableHistoryDiffWithDetails(this.state.historyResponse.data[0])}
        </Typography>);
    }
}

export function HumanReadableHistoryDiffWithDetails(
    mostrecent: HistoryRecord<IVSpaceProperty>
): string {
    if (!mostrecent) return '';
    
    return `${ moment(mostrecent.timestamp).fromNow()} ${mostrecent.username} ${mostrecent.action} ${mostrecent.item.instanceName} ${mostrecent.item.use} ${HumanReadableHistoryDiff(mostrecent, true)}`;
}
