


import _ from 'lodash';
import * as React from 'react';
import {
    Link as RouterLink,
    NavigateFunction,
    Route,
    Routes,
    useNavigate
} from 'react-router-dom';

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Paper,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';


import {
    EntityAccessGroupsPage,
    IMapBounds,
    INode,
    IToken,
    Maps,
    PickInfo,
    ProfileDisplay,
    UsersDisplay
} from '../components';

import { APIVodafoneVSpacesAPI } from './api';
import { ICloudServiceActionResult, ICloudServiceActionResultPaged } from './interfaces';
import { IVSpaceProperty } from './interfaces_vspaces';
import { PageView } from './page';
import { VSpaceHistoryMostRecent } from './vspace_historyMostRecent';
import { VSpaceGrid } from './vspace_grid';
import { AllowedUses } from './lists_use';
// import { vspaceproddata } from './proddata_2022_04_07';
import VSpaceSiteView from './vspace_siteview';
import { GridColumns } from '@mui/x-data-grid-pro';
import VSpaceHistoryRoute, { VSpaceHistory } from './vspace_history';
import { Countries, findCountryGPS, findRegionGPS, regionCodeToName } from './countries';
import { allowedOperatingEntities } from './list_operatingEntities';
import { LinkTwoTone, SaveAsTwoTone } from '@mui/icons-material';
import { FilterCriteria, IFilterCriteriaState } from './filterCriteria';
import { InitialViewStateProps } from "@deck.gl/core/lib/deck"
import { processInstanceCalculations } from './vspace_calculations';
import clsx from 'clsx';
import { gridStyles } from './vspace_gridstyles';
import { formatCurrency } from '../components/widget/formatCurrency';
import { localStorageGetBoolean, localStorageSetBoolean } from '../components/utils/localstorage';
import { IAppState } from '../App';
interface VSpaceMainProps {
    token: IToken;
    router?: {
        navigate: NavigateFunction
    }
    appState: IAppState
}

interface VSpaceMainState {
    digitwinInstances?: ICloudServiceActionResultPaged<IVSpaceProperty[]>
    columns?: GridColumns;
    mapHoverItem?: IVSpaceProperty;
    mapClickedEvent?: PickInfo<INode<IVSpaceProperty>>;
    mapBounds?: IMapBounds
    lastResponses: ICloudServiceActionResult<any>[]
    pleaseWait?: boolean
    showTable: boolean
    filterCriteria?: IFilterCriteriaState,
    /** control the map view */
    initialViewState?: InitialViewStateProps
    enableFilterToMapBounds: boolean

    /** used with user permissions on operating entities, will only show what they are allowed to edit */
    showOnlyEditable: boolean
}

export const templateNameHardcoded = "V-Space Site";

export const defaultInitialViewState = {
    longitude: 0,
    latitude: 10,
    zoom: 1,
    pitch: 0,
    bearing: 0
}

/** TODO, get this from the backend instead when ready */
// export const userPermissionEntities = [
//     "VOIS", "South Africa"
// ]

export class VSpaceMainClass extends React.Component<VSpaceMainProps, VSpaceMainState> {
    state: VSpaceMainState = {
        lastResponses: [],
        showTable: localStorageGetBoolean('showTable', false),
        showOnlyEditable: localStorageGetBoolean('showOnlyEditable', false),
        enableFilterToMapBounds: localStorageGetBoolean('enableFilterToMapBounds', false),
        initialViewState: defaultInitialViewState
    }

    api: APIVodafoneVSpacesAPI = new APIVodafoneVSpacesAPI();
    map?: Maps<IVSpaceProperty>;

    componentDidMount = async () => {
        if (this.props.token) this.api.authorization = `${this.props.token.token_type} ${this.props.token.access_token}`;
        this.getData().then(r => {
            console.log("Get Data Completed " + r !== undefined);
        });
    }

    getData = async () => {
        // uncomment to use server
        this.setState({ pleaseWait: true });

        let digitwinInstances = await this.api.api.v1.VSpacesDigitwin
            .GetPagedDigitwinInstances<IVSpaceProperty[]>(
                {
                    "orderByQuery": "",
                    "pageNumber": 1,
                    "pageSize": 100000,
                    "selectQuery": "",
                    "whereQuery": {}
                }
            );
        // let digitwinInstances = vspaceproddata; // prod data to test

        if (!digitwinInstances.data) {
            this.setState({ digitwinInstances, pleaseWait: false }); return;
        }

        // set lat long to 0,0 if no data.
        digitwinInstances.data = digitwinInstances.data.map(processInstanceCalculations);

        let columns: GridColumns<IVSpaceProperty> = [
            {
                field: "id",
                headerName: "Id",
                hide: true
            },
            {
                editable: true,
                field: "instanceName",
                headerName: "Name",
                headerClassName: 'super-app-theme--header',
                align: 'left',
                width: 350,
                renderCell: (props) => {
                    if (props.row.isNew) return <Button color="success" disabled startIcon={<SaveAsTwoTone />}>{props.value} (unsaved)</Button>;
                    return <Tooltip title="View site details" arrow placement="top">
                        <Button startIcon={<LinkTwoTone />}
                            sx={{ textTransform: 'none' }}
                            size="small"
                            component={RouterLink}
                            to={`/site/${props.row.instanceId}`}>{props.value}</Button>
                    </Tooltip>
                }
            },
            {
                editable: true,
                field: "use",
                headerName: "Use",
                headerClassName: 'super-app-theme--header',
                type: "singleSelect",
                valueOptions: AllowedUses,
                // required: true
            },
            {
                editable: true,
                field: "numberOfBuildings",
                headerClassName: 'super-app-theme--header',
                headerName: "Number of Buildings",
                type: 'number',
            },
            {
                editable: true,
                field: "country",
                headerClassName: 'super-app-theme--header',
                headerName: "Country",
                type: "singleSelect",
                valueOptions: Countries.map(c => c.name)
                // valueSetter: (params) => {
                //     let find = Countries.filter( c => c.name === params.value)
                //     if (!find || !find[0]) return { ...params.row };
                //     return { ...params.row, currency: find[0].currency.code }
                // }
                // required: true
            },
            {
                editable: true,
                field: "region",
                headerName: "Region",
                // type: "singleSelect",
                // valueOptions: Regions.map( r => r.name)
            },
            {
                editable: true,
                field: "currency",
                headerName: "Currency",
                type: "singleSelect",
                valueOptions: _.uniq(Countries.map(c => c.currency.code)).sort((a, b) => a > b ? 1 : -1),
                valueGetter: (params) => {
                    let find = Countries.filter(c => c.name === params.row.country)
                    if (!find || !find[0]) return;
                    return find[0].currency.code
                }
            },
            {
                editable: true,
                field: "city",
                headerName: "City",
                // type: "singleSelect",
                // valueOptions: enumList_city
            },

            {
                editable: true,
                field: "operatingEntity",
                headerName: "Operating Entity",
                type: "singleSelect",
                valueOptions: allowedOperatingEntities,
                width: 150
            },

            {
                editable: true,
                field: "originalUse",
                headerName: "Original Use",
                // type: "singleSelect",                
                // valueOptions: enumList_originalUse
            },
            {
                editable: true,
                field: "latitude",
                headerName: "Latitude"
            },
            {
                editable: true,
                field: "longitude",
                headerName: "Longitude"
            },
            {
                editable: true,
                field: "assetId",
                headerName: "Asset ID"
            },
            {
                editable: true,
                field: "freeHoldBuildings",
                headerName: "Freehold Buildings"
            },
            {
                editable: true,
                field: "buildingArea",
                headerName: "Building Area"
            },

            {
                editable: true,
                field: "actualUsers",
                headerName: "Actual Users"
            },
            {
                editable: true,
                field: "areaOccupiedByTech",
                headerName: "Area Occupied By Tech"
            },
            {
                editable: true,
                field: "assignedUsers",
                headerName: "Assigned Users"
            },
            {
                editable: true,
                field: "energyConsumed",
                headerName: "Energy Consumed"
            },
            {
                editable: true,
                field: "estateCost",
                headerName: "Estate Cost"
            },
            {
                editable: true,
                field: "facilitiesHardCost",
                headerName: "Facilities Hard Cost"
            },
            {
                editable: true,
                field: "facilitiesSoftCost",
                headerName: "Facilities Soft Cost"
            },

            {
                editable: true,
                field: "peakOccupancyWeekday",
                headerName: "Peak Occupancy Weekday"
            },
            {
                editable: true,
                field: "peakOccupancyWeekend",
                headerName: "Peak Occupancy Weekend"
            },
            {
                editable: true,
                field: "retailId",
                headerName: "Retail ID"
            },
            {
                editable: true,
                field: "retailRevenue",
                headerName: "Retail Revenue"
            },
            {
                editable: true,
                field: "techRoomArea",
                headerName: "Tech Room Area"
            },
            {
                editable: true,
                field: "utilityCost",
                headerName: "Utility Cost",
            },
            {
                editable: true,
                field: "utilizedArea",
                headerName: "Utilized Area"
            },
            {
                editable: true,
                field: "workstations",
                headerName: "Workstations"
            },
            {
                editable: true,
                field: "year",
                headerName: "Year"
            },
            { field: "templateName", headerName: "Template Name", hide: true },
            { field: "instanceId", headerName: "InstanceId", hide: true },
            { field: "templateId", headerName: "TemplateId", hide: true },
            { field: "state", headerName: "State", hide: true },
            {
                field: "calculatedAreaRequiredToMeetGroupTarget",
                headerName: "Area Required To Meet Group Target",
                editable: false,
                align: 'right',
                width: 200,
                cellClassName: (params) => {
                    return clsx('vspacegrid', {
                        notEditable: (params.isEditable === false)
                    })
                }
            },
            {
                field: 'calculatedTotalCost',
                headerName: 'Total Cost',
                editable: false,
                align: 'right',
                width: 200,
                cellClassName: (params) => {
                    return clsx('vspacegrid', {
                        notEditable: (params.isEditable === false)
                    })
                },
                renderCell: (params) => {
                    return <Typography>{formatCurrency(params.value, params.row.currency)}</Typography>
                }
            },
            {
                field: 'calculatedCostPerSQM',
                headerName: 'Cost per sqm',
                editable: false,
                align: 'right',
                width: 200,
                cellClassName: (params) => {
                    return clsx('vspacegrid', {
                        notEditable: (params.isEditable === false)
                    })
                },
                renderCell: (params) => {
                    return <Typography>{formatCurrency(params.value, params.row.currency)}</Typography>
                }
            },
            {
                field: 'calculatedCostOfSurplusAreaPA',
                headerName: 'Cost of surplus area p.a.',
                editable: false,
                align: 'right',
                width: 200,
                cellClassName: (params) => {
                    return clsx('vspacegrid', {
                        notEditable: (params.isEditable === false)
                    })
                },
                renderCell: (params) => {
                    return <Typography>{formatCurrency(params.value, params.row.currency)}</Typography>
                }
            }
        ]

        await this.setState({ digitwinInstances, columns, pleaseWait: false });
    }

    render() {
        if (!this.state.digitwinInstances) return <CircularProgress sx={{ m: 5 }} />

        if (!this.props.appState.appsettings?.nodeapisettings) return <CircularProgress sx={{ m: 5 }} />

        return (<>
            <Routes>
                <Route index element={<>{this.renderMain()}</>} />


                <Route path="/site/:instanceId" element={<VSpaceSiteView
                    appState={this.props.appState}
                    onClickRefresh={async () => {
                        await this.getData();
                    }}
                    token={this.props.token}
                    columns={this.state.columns}
                    onInstanceId={(instanceId) => {
                        if (!this.state.digitwinInstances?.data) throw new Error('missing instance Id');
                        return this.state.digitwinInstances.data.filter(d => d.instanceId === instanceId);
                    }} />} />

                <Route
                    path="/history"
                    element={<VSpaceHistory token={this.props.token} />}
                />
                <Route path="/account" element={<ProfileDisplay authorization={`${this.props.token.token_type} ${this.props.token.access_token}`} nodesettings={this.props.appState.appsettings.nodeapisettings} />} />
                <Route path="/members" element={<UsersDisplay authorization={`${this.props.token.token_type} ${this.props.token.access_token}`} nodesettings={this.props.appState.appsettings.nodeapisettings} />} />
                <Route path="/entityaccess" element={<EntityAccessGroupsPage
                    authorization={`${this.props.token.token_type} ${this.props.token.access_token}`}
                    nodeapisettings={this.props.appState.appsettings.nodeapisettings} />} />
                <Route path="/history/:instanceId" element={<VSpaceHistoryRoute token={this.props.token} />} />
                <Route path="*" element={<Typography>all</Typography>} />
            </Routes>
        </>)
    }

    addResponseMessage = (entry: ICloudServiceActionResult<any>) => {
        // changed to only show one response message.
        let lastResponses: ICloudServiceActionResult<any>[] = []; //this.state.lastResponses;
        lastResponses.push(entry);
        this.setState({ lastResponses });
        setTimeout(() => {
            lastResponses.shift();
            this.setState({ lastResponses: [] })
        }, 1000);
    }

    applyFilterCriteria = (row: IVSpaceProperty): boolean => {

        if (this.state.showOnlyEditable && this.props.appState.userPermissionEntities) {
            if (this.props.appState.simplifiedAccessRules?.isAdmin) {
                // return true;
            } else {
                if (this.props.appState.userPermissionEntities.indexOf(row.operatingEntity || '') < 0) return false;
            }
        }

        // map bounds
        if (this.state.enableFilterToMapBounds && this.state.mapBounds && row.latitude !== undefined && row.longitude !== undefined) {
            if (row.latitude > this.state.mapBounds.nw.latitude) return false;
            if (row.latitude < this.state.mapBounds.se.latitude) return false;
            if (row.longitude < this.state.mapBounds.nw.longitude) return false;
            if (row.longitude > this.state.mapBounds.se.longitude) return false;
        }

        if (!this.state.filterCriteria) return true;

        if (this.state.filterCriteria.region) {
            // check row region
            let regionName = regionCodeToName(this.state.filterCriteria.region);
            if (row.region?.toLowerCase() !== regionName.toLocaleLowerCase()) return false;
        }

        if (this.state.filterCriteria.country) {
            if (row.country !== this.state.filterCriteria.country) return false;
        }

        if (this.state.filterCriteria.city) {
            if (!row.city) return false;
            if (row.city.toLowerCase().indexOf(this.state.filterCriteria.city.toLowerCase()) < 0) return false;
        }

        if (this.state.filterCriteria.operatingEntity) {
            if (!row.operatingEntity) return false;
            if (row.operatingEntity !== this.state.filterCriteria.operatingEntity) return false;
        }

        if (this.state.filterCriteria.use) {
            if (!row.use) return false;
            if (row.use !== this.state.filterCriteria.use) return false;
        }

        if (this.state.filterCriteria.instanceName) {
            if (!row.instanceName) return false;
            if (row.instanceName.toLowerCase().indexOf(this.state.filterCriteria.instanceName.toLowerCase()) < 0) return false;
        }



        return true;
    }

    goToNewFilterCriteriaOnMap = async (filterCriteria: IFilterCriteriaState) => {

        console.log(filterCriteria);

        this.setState({ showTable: true });

        if (this.map && filterCriteria.region === '') {
            this.map.goToNewLocation(defaultInitialViewState)
        }

        if (this.map && filterCriteria.region && !filterCriteria.country) {
            let regionGPS = findRegionGPS(filterCriteria.region);
            if (!regionGPS) return;
            this.map.goToNewLocation(regionGPS.viewState)
        }

        // use mapbox api to find the GPS coordinates for the selected region/country/city
        let countryGps = findCountryGPS(filterCriteria.country);

        if (this.map && countryGps && countryGps.latitude && countryGps.longitude) {
            this.map.goToNewLocation({
                longitude: countryGps.longitude,
                latitude: countryGps.latitude,
                zoom: 5,
                pitch: 0,
                bearing: 0
            });
        }
        // let mapboxReverseResult = await new APIMapBox().lookupPlaceName(`${filterCriteria.country}, ${regionCodeToName(filterCriteria.region)}`)
    }

    sortInstances = (a: IVSpaceProperty, b: IVSpaceProperty) => {
        return ((a.instanceName || '') > (b.instanceName || '')) ? 1 : -1;
    }

    renderMain() {
        if (!this.state.digitwinInstances) return <CircularProgress sx={{ m: 5 }} />

        if (!this.state.digitwinInstances.isSuccessful || !this.state.digitwinInstances.data) {
            return <Alert severity='error'>
                {this.state.digitwinInstances.message} {this.state.digitwinInstances.exceptionMessage}
            </Alert>
        }

        let rows = this.state.digitwinInstances.data.map(processInstanceCalculations).filter(this.applyFilterCriteria);

        if (!this.state.columns) return <Alert severity='error'>Missing column data</Alert>

        let columns = this.state.columns;

        return <PageView title="VSpaces" id="vspace">
            <Paper elevation={0} sx={{
                display: 'flex', flex: 1, flexDirection: 'row',
            }}>

                <FilterCriteria sx={{ width: 300, p: 1, pr: 0 }}
                    onFilterChange={async (filterCriteria) => {
                        await this.setState({ filterCriteria });
                        await this.goToNewFilterCriteriaOnMap(filterCriteria);
                    }} />


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Paper sx={{ m: 1, p: 0, flex: 1 }} elevation={0}>
                        <Paper sx={{
                            m: 0, p: 0,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                        }} elevation={0}>



                            <Maps<IVSpaceProperty>
                                ref={(el) => {
                                    if (el) this.map = el;
                                }}
                                enableMapStyleToggle
                                showCluster
                                mapboxApiAccessToken={'pk.eyJ1IjoiaW90bnh0IiwiYSI6ImNsMXE0YXFoZjExNjYzZXBkY3l1Mms5NzEifQ._1ILYjgYE380E5YsUGGoiw'}
                                height={'100%'}
                                onClick={(info) => {
                                    console.log(info);
                                    if (info) this.setState({ mapClickedEvent: info });

                                    let ob = info.object as any;
                                    if (!ob) return;
                                    if (!ob.cluster) {
                                        console.log('clicked on an instance')
                                        console.log({ ob });
                                        if (!ob.data) return;
                                        let instance: IVSpaceProperty = ob.data;
                                        if (this.props.router?.navigate) this.props.router.navigate(`/site/${instance.instanceId}`)
                                    }
                                }}
                                onHover={(mapHoverItem: IVSpaceProperty) => { this.setState({ mapHoverItem }); }}
                                initialViewState={this.state.initialViewState}
                                onViewStateChange={(data) => {
                                    // console.log('bounds', data.bounds)
                                    this.setState({ mapBounds: data.bounds });
                                }}

                                listOfNodes={rows.map(d => {
                                    let coordinates: [number, number] = [0, 0];
                                    if (d.latitude && d.latitude >= -90 && d.latitude <= 90) coordinates[1] = d.latitude;
                                    if (d.longitude && d.longitude >= -180 && d.longitude <= 180) coordinates[0] = d.longitude;
                                    return { data: d, location: { type: "Point", coordinates } };
                                })} />

                        </Paper>
                    </Paper>

                    <Box sx={{ pl: 2 }}>

                        <Tooltip
                            arrow placement="left-start"
                            title="Hides entities you are not allowed to edit.">
                            <FormControlLabel control={<Switch
                                checked={this.state.showOnlyEditable}
                                onChange={() => {
                                    const showOnlyEditable = !this.state.showOnlyEditable;
                                    localStorageSetBoolean('showOnlyEditable', showOnlyEditable);
                                    this.setState({ showOnlyEditable });
                                }} />} label='Show only editable' />
                        </Tooltip>

                        <FormControlLabel control={<Switch
                            checked={this.state.showTable}
                            onChange={() => {
                                const showTable = !this.state.showTable;
                                localStorageSetBoolean('showTable', showTable);
                                this.setState({ showTable });
                            }} />} label='View Site details' />

                        <FormControlLabel control={<Switch
                            disabled={!this.state.showTable}
                            checked={this.state.enableFilterToMapBounds}
                            onChange={() => {
                                const enableFilterToMapBounds = !this.state.enableFilterToMapBounds;
                                localStorageSetBoolean('enableFilterToMapBounds', enableFilterToMapBounds);
                                this.setState({ enableFilterToMapBounds })
                            }} />} label='Only list within map bounds' />
                    </Box>

                    {this.state.showTable && <Paper sx={{ m: 1, mt: 0, p: 0, flex: 2 }} elevation={0}>
                        <Paper sx={{
                            ...{
                                height: '100%',
                                m: 0,
                                p: 0,
                                display: 'flex', flexDirection: 'column',
                                '& .super-app-theme--header': {
                                    color: 'error.main',
                                }
                            }, ...gridStyles
                        }} elevation={0}>

                            {this.state.pleaseWait && <Alert severity='info'>Please Wait...</Alert>}

                            {this.state.lastResponses && this.state.lastResponses.map((r, i) => <Alert key={i}
                                severity={r.isSuccessful ? 'success' : 'error'}
                            >{r.exceptionMessage || r.message}</Alert>)}

                            <VSpaceGrid
                                isAdmin={this.props.appState.simplifiedAccessRules?.isAdmin || false}
                                userPermissionEntities={this.props.appState.userPermissionEntities || []}
                                triggerRefresh={async () => {
                                    this.getData().then(r => {
                                        console.log("Get Data Completed " + r !== undefined);
                                    });
                                }}
                                processRowUpdate={async (item) => {

                                    let prepItem = _.clone(item);

                                    if (prepItem.isNew) {
                                        delete prepItem.id; // we need to send a row without an id else the server complains.
                                        delete prepItem.isNew;
                                    }

                                    this.setState({ pleaseWait: true });
                                    prepItem.templateName = templateNameHardcoded;
                                    const response = await this.api.api.v1.VSpacesDigitwin.AddOrUpdateInstance<IVSpaceProperty>(prepItem);
                                    if (response.isSuccessful) {
                                        if (response.data) {
                                            await this.AddOrUpdateInstanceLocal(response.data);
                                        } else {
                                            await this.getData();
                                        }
                                    }
                                    this.addResponseMessage(response);
                                    this.setState({ pleaseWait: false }); // so we can display success/error

                                    return response;
                                }}
                                processRowDelete={async (item) => {
                                    this.setState({ pleaseWait: true });
                                    item.templateName = templateNameHardcoded;
                                    const response = await this.api.api.v1.VSpacesDigitwin.RemoveInstance<IVSpaceProperty>(item);
                                    if (response.isSuccessful) await this.RemoveInstanceLocal(item);
                                    // await this.getData();
                                    this.addResponseMessage(response);
                                    this.setState({ pleaseWait: false })
                                    return response;
                                }}
                                processSaveAll={async (rows) => {
                                    this.setState({ pleaseWait: true, lastResponses: [] });

                                    let chunks = [];

                                    while (rows.length) {
                                        let chunk = rows.splice(0, 10);
                                        chunks.push(chunk);
                                    }
                                    for (const c of chunks) {
                                        let response = await this.api.api.v1.VSpacesDigitwin.AddOrUpdateManyInstances<IVSpaceProperty>(c);
                                        this.addResponseMessage(response);
                                    }
                                    // let tasks = chunks.map(c => {
                                    //     return new Promise<ICloudServiceActionResult<any>>(async resolve => {
                                    //         let response = await this.api.api.v1.VSpacesDigitwin.AddOrUpdateManyInstances<IVSpaceProperty>(c);
                                    //         this.addResponseMessage(response);
                                    //         resolve(response);
                                    //     });
                                    // })
                                    // let response = await Promise.all(tasks);

                                    this.setState({ pleaseWait: false }); // so we can display success/error
                                    // if (!response) throw Error('missing response');
                                    return new Promise(r => setTimeout(r, 10));
                                    // return response[0];
                                }}
                                rows={rows}
                                columns={columns}
                            />
                            <Box sx={{ p: 0, display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flex: 1 }}><VSpaceHistoryMostRecent token={this.props.token} /></Box>
                                <Button variant="text" component={RouterLink} to="/history">View History</Button>
                            </Box>
                        </Paper>
                    </Paper>}
                </Box>
            </Paper>
        </PageView>
    }

    /** add or update local memory version while we wait for server sync */
    AddOrUpdateInstanceLocal = (item: IVSpaceProperty) => {

        if (!this.state.digitwinInstances) throw new Error('missing digitwinInstances');
        if (!this.state.digitwinInstances.data) throw new Error('missing digitwinInstances.data');

        // find entry 
        let filter = this.state.digitwinInstances.data.filter(d => d.id === item.id)
        if (filter.length !== 1) {
            // new entry?
            console.log('adding item to list', item)
            this.state.digitwinInstances.data.push(item);
        }

        if (filter.length === 1) {
            // found
            console.log('updating item in list', item)
            const index = _.findIndex(this.state.digitwinInstances.data, { id: item.id });
            this.state.digitwinInstances.data.splice(index, 1, item);
        }

        let digitwinInstances = this.state.digitwinInstances;
        this.setState({ digitwinInstances });
    }

    /** remove local memory version while we wait for server sync */
    RemoveInstanceLocal = (item: IVSpaceProperty) => {
        if (!this.state.digitwinInstances) throw new Error('missing digitwinInstances');
        if (!this.state.digitwinInstances.data) throw new Error('missing digitwinInstances.data');

        let digitwinInstances = this.state.digitwinInstances;
        digitwinInstances.data = this.state.digitwinInstances.data.filter(d => d.id !== item.id);

        this.setState({ digitwinInstances });
    }
}

export default function VSpaceMain(props: VSpaceMainProps) {
    let navigate = useNavigate();
    return <VSpaceMainClass {...props} router={{ navigate }} />
}