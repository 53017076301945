import { Box, Button, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import {
    APIIoTnxtMain,
    IProfile,
    IGroup,
    IDapiEntityAccessGroup,
    IEntity,
    IEntityPathAccess
} from "../../commanderapi";

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { alpha, styled } from '@mui/material/styles';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import { CheckBoxRounded, CheckBoxOutlineBlankRounded, LinkRounded } from "@mui/icons-material";

import { config } from '../../App'
import { INodeSettings } from "../../interfaces";

interface Props {
    authorization: string
    entityAccessGroup: IDapiEntityAccessGroup
    nodeapisettings: INodeSettings
}

interface State {
    profile?: IProfile
    groups?: IGroup[]
    entityAccessGroups?: IDapiEntityAccessGroup[]
    entityList?: IEntity[]
    entityAccessGetPathAccess?: IEntityPathAccess[]
}

export class EntityAccessDisplay extends React.Component<Props, State> {
    state: State = {

    }

    componentDidMount = () => { this.getDataAsync(); }

    getDataAsync = async () => {

        const iotnxtAPI = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrl,
            nodeapisettings: this.props.nodeapisettings
        });

        const iotnxtAPIRobot = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrlRobot,
            nodeapisettings: this.props.nodeapisettings
        });

        const [
            profile,
            groups,
            entityList
        ] = await Promise.all([
            iotnxtAPI.authorization.profile.get(),
            iotnxtAPIRobot.policyServer.groups(),
            iotnxtAPI.dapi.EntityTree.GetAccountChildren().then(r => r.result)
        ])

        const entityAccessGetPathAccess = await iotnxtAPIRobot.dapi.EntityAccess.GetPathAccess({
            "path": `/${this.props.nodeapisettings.DigitwinClientOptions.DefaultParentId}`,
            recursive: true,
            "sidType": "groupId",
            "sid": this.props.entityAccessGroup.id
        }).then(r => r.result)

        this.setState({ profile, groups, entityList, entityAccessGetPathAccess });
        console.log({ profile, groups, entityList, entityAccessGetPathAccess })
    }

    getEntityPathAccessByEntityId = (entityId: string): IEntityPathAccess | undefined => {
        if (!this.state.entityAccessGetPathAccess) return undefined;
        let find = this.state.entityAccessGetPathAccess.filter(a => a.path.endsWith(entityId))
        if (!find || !find[0]) return undefined;
        return find[0]
    }

    RichObjectTreeView = (props: { data: RenderTree }) => {
        const renderTree = (nodes: RenderTree, parentGrant?: any) => {
            let pathaccess = this.getEntityPathAccessByEntityId(nodes.entityId)

            let grant = (parentGrant !== undefined) ? parentGrant : pathaccess?.grant;

            return <StyledTreeItem key={nodes.entityId} nodeId={nodes.entityId}

                label={<Box
                    sx={{
                        opacity: (!pathaccess) ? 0.5 : undefined,
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >{grant ? <CheckBoxRounded color="success" /> : <CheckBoxOutlineBlankRounded />} <Typography>{nodes.name} {nodes.entityType ? `(${nodes.entityType})` : ''}</Typography>
                </Box>}>
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node, grant))
                    : null}
            </StyledTreeItem>
        };

        return (
            <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: '100%', flexGrow: 1, width: '100%', overflowY: 'auto' }}
            >
                {renderTree(props.data)}
            </TreeView>
        );
    }

    render() {
        if (!this.state.entityList) return <Skeleton />

        return <Paper elevation={0} sx={{ mb: 1 }}>
            {/* <Typography sx={{ fontSize: '0.85em' }}>{this.props.entityAccessGroup.id}</Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>{this.props.entityAccessGroup.name}</Typography> */}
            <Button
                startIcon={<LinkRounded />}
                target="_blank"
                href={`${this.props.nodeapisettings.NodeSettings.DashboardUrl}/_apps/account-manager/details/entity-access-groups/edit/${this.props.entityAccessGroup.id}`}>
                {this.props.entityAccessGroup.name}
            </Button>
            <Box sx={{ maxHeight: 300, overflowY: 'scroll' }}>
                {this.RichObjectTreeView({ data: generateTree(this.state.entityList, this.props.nodeapisettings.DigitwinClientOptions.DefaultParentId) })}
            </Box>
        </Paper>
    }


}


function generateTree(list: IEntity[], parentId: string): RenderTree {
    const tree: RenderTree = {
        entityId: parentId,
        name: 'Click to expand',
        // entityType: "Click to expand",
        children: [],
    };

    list.forEach(e => {
        if (e.parentId && e.parentId === tree.entityId) {
            if (tree.children) { tree.children.push(e) } else { tree.children = [e]; }
        }
    })

    return tree
}



interface RenderTree {
    entityId: string;
    name?: string;
    entityType?: string
    children?: RenderTree[];
}


function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));
