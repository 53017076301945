import { Box, Button, Chip, CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import {
    APIIoTnxtMain,
    IProfile,
    IGroup,
    IDapiEntityAccessGroup
} from "../../commanderapi";
import { config } from '../../App'
import { INodeSettings } from "../../interfaces";

interface Props {
    authorization: string
    nodesettings: INodeSettings
}

interface State {
    profile?: IProfile
    groups?: IGroup[]
    entityAccessGroups?: IDapiEntityAccessGroup[]
}

export class ProfileDisplay extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = () => { this.getDataAsync(); }

    getDataAsync = async () => {

        const iotnxtAPI = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrl,
            nodeapisettings: this.props.nodesettings
        });

        const iotnxtAPIRobot = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrlRobot,
            nodeapisettings: this.props.nodesettings
        });

        // execute in parallel
        const [
            profile,
            groups
        ] = await Promise.all([
            iotnxtAPI.authorization.profile.get(),
            iotnxtAPIRobot.policyServer.groups()
        ])
        this.setState({ profile, groups });

        let entityAccessGroups = await iotnxtAPIRobot.dapi.EntityAccess.GetEntityAccessGroups({ username: profile.username }).then(r => r.result)
        this.setState({ entityAccessGroups });
        // const profile = await iotnxtAPI.authorization.profile.get();


        // const groups = await iotnxtAPI.policyServer.groups();
        // this.setState({ groups });


    }

    render() {
        if (!this.state.profile) return <CircularProgress />

        let p = this.state.profile;
        return <>

            <Paper elevation={0} sx={{ m: 2, p: 2 }}>

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h4" sx={{ p: 0, pr: 2 }}>Account Profile</Typography>
                    <Box sx={{ mt: 1 }}>
                        <Button
                            size="small"
                            variant="outlined"
                            target="_blank"
                            href={`${this.props.nodesettings.NodeSettings.DashboardUrl}/_apps/account-manager/details/members/${p.id}/edit`}>
                            Edit
                        </Button>
                    </Box>
                    <Box sx={{ flex: 1 }} />
                </Box>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>User Type</Typography>
                <Typography>{p.userType}</Typography>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>First Name</Typography>
                <Typography>{p.firstName}</Typography>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Last Name</Typography>
                <Typography>{p.lastName}</Typography>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Email</Typography>
                <Typography>{p.email} {p.isEmailVerified ? '(verified)' : '(not verified)'}</Typography>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Roles</Typography>
                <Box>
                    {p.roles.map(r => <Chip size="small"
                        key={r} label={r} sx={{ mr: 1, mb: 1 }} />)}
                </Box>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Groups</Typography>
                <Box>
                    {p.groups.map(groupid => {
                        let groupDisplayName = groupid;
                        if (this.state.groups) {
                            this.state.groups.filter(g => g.id === groupid).forEach(g => { groupDisplayName = g.displayName })
                        }

                        return <Chip size="small"
                            key={groupid}
                            label={groupDisplayName}
                            sx={{ mr: 1, mb: 1 }} />
                    }
                    )}
                </Box>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Applications</Typography>
                <Box>
                    {p.applications.map(r => <Chip size="small"
                        key={r} label={r} sx={{ mr: 1, mb: 1 }} />)}
                </Box>

                <Typography sx={{ pt: 2, fontSize: '0.75em' }}>Entity Access Groups</Typography>
                {!this.state.entityAccessGroups && <CircularProgress />}
                {this.state.entityAccessGroups && <Box>
                    {this.state.entityAccessGroups.map(e => <Chip sx={{ mr: 1, mb: 1 }} size="small" key={e.id} label={e.name} />)}
                </Box>}
                {(this.state.entityAccessGroups && !(this.state.entityAccessGroups.length > 0)) && <Typography>None</Typography>}
            </Paper>
        </>
    }
}

// class Testapi extends React.Component<{ url: string, requestOptions?: RequestInit }> {
//     state: any = {
//         response: undefined
//     }
//     render() {
//         return <Paper elevation={10} sx={{ m: 2, p: 2 }}>
//             <Typography>{this.props.url}</Typography>
//             <Button onClick={async () => {
//                 const response = await fetch(this.props.url, this.props.requestOptions).then(res => res.json()).catch(err => {
//                     console.log(err);
//                     this.setState({ err: err.message })
//                 })
//                 this.setState({ response });
//             }}>Test</Button>
//             {this.state.error && <Alert>{this.state.error}</Alert>}
//             {JSON.stringify(this.state.response)}
//         </Paper>
//     }
// }