import React, { useState, Suspense } from "react";

import {
    Alert,
    AlertColor,
    Box,
    Button,
    Divider,
    Tooltip
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';

// https://mui.com/x/react-data-grid/#commercial-version
import {
    useGridApiRef,
    DataGridPro,
    GridApi,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridEvents,
    GridRowId,
    GridRowModel,
    LicenseInfo,
    GridSortModel,
    GridSortItem,
    GridFilterModel,
    GridLinkOperator
} from '@mui/x-data-grid-pro';


import {
    randomId,
} from '@mui/x-data-grid-generator';

import _ from "lodash";
import {
    AddTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    RefreshTwoTone,
    SaveTwoTone,
    UndoTwoTone,
    UploadFileTwoTone,
} from "@mui/icons-material";
import { ICloudServiceActionResult } from "./interfaces";

import { IVSpaceProperty } from "./interfaces_vspaces";
import { templateNameHardcoded } from "./vspace_main";

import { isDataDifferent } from "./vspace_calculations";

const ExportXLSX = React.lazy(() => import("./export_xlsx"));
const ImportXLSX = React.lazy(() => import("./import_xlsx"));

interface EditToolbarProps {
    apiRef: React.MutableRefObject<GridApi>;
}

LicenseInfo.setLicenseKey('11dfa392be05d10d58887edf4f20e775T1JERVI6NDE2MjgsRVhQSVJZPTE2ODEzMzgwODU1NTIsS0VZVkVSU0lPTj0x');

export interface VSpaceGridProps<IVSpaceProperty> {
    rows: IVSpaceProperty[],
    columns: GridColumns
    processRowUpdate: (item: IVSpaceProperty) => Promise<ICloudServiceActionResult<IVSpaceProperty>>
    processRowDelete: (item: IVSpaceProperty) => Promise<ICloudServiceActionResult<IVSpaceProperty>>
    processSaveAll: (items: IVSpaceProperty[]) => Promise<ICloudServiceActionResult<IVSpaceProperty[]>>
    triggerRefresh: () => Promise<any>
    userPermissionEntities?: string[]
    isAdmin: boolean
}

export function VSpaceGrid(props: VSpaceGridProps<IVSpaceProperty>) {
    // used to display Alert Messages on the grid
    const [alertDisplay, setAlertDisplay] = useState<{ message: string, severity: AlertColor }>();

    const apiRef = useGridApiRef();
    // const sortByNew: GridSortItem = { field: 'isNew', sort: 'desc' };
    const sortByDefault: GridSortItem = { field: 'instanceName', sort: 'asc' };
    // const sortByDefault: GridSortItem = { field: 'id', sort: 'asc' };
    const [sortModel, setSortModel] = useState<GridSortModel>([sortByDefault]);

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            { id: 1, columnField: 'instanceName', operatorValue: 'isNotEmpty', value: undefined },
            { id: 2, columnField: 'isNew', operatorValue: 'equals', value: 'true' }],
        linkOperator: GridLinkOperator.Or
    });

    // const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (
        params,
        event,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
        event.stopPropagation();
        apiRef.current.startRowEditMode({ id });
    };

    const handleCloneClick = (id: GridRowId) => (event: React.MouseEvent) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        let newRow = _.clone(row);
        newRow.id = randomId(); // make unique
        newRow.isNew = true;
        newRow.templateName = templateNameHardcoded;
        apiRef.current.updateRows([newRow]);
        apiRef.current.startRowEditMode({ id: newRow.id })
    };

    const handleSaveClick = (id: GridRowId) => async (event: React.MouseEvent) => {
        event.stopPropagation();
        await apiRef.current.stopRowEditMode({ id });
    };

    const handleDeleteClick = (id: GridRowId) => async (event: React.MouseEvent) => {
        event.stopPropagation();

        const row = apiRef.current.getRow<IVSpaceProperty>(id);

        if (!row) throw new Error(`could not find row with id ${id}`)
        const response = await props.processRowDelete(row);
        console.log(response);
        if (response.isSuccessful === true) {
            console.log(`Removing row with ${id}`)
            apiRef.current.updateRows([{ id, _action: 'delete' }]);
        }
    };

    const handleCancelClick = (id: GridRowId) => async (event: React.MouseEvent) => {
        event.stopPropagation();
        await apiRef.current.stopRowEditMode({ id, ignoreModifications: true });

        const row = apiRef.current.getRow(id);
        if (row!.isNew) {
            apiRef.current.updateRows([{ id, _action: 'delete' }]);
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        let updatedRow = _.clone(newRow);
        await props.processRowUpdate(updatedRow);
        return { ...newRow, isNew: false };
    };

    const checkPermission = (operatingEntity: string | undefined) => {
        if (props.isAdmin) return true;
        if (!operatingEntity) return false;
        if (!props.userPermissionEntities) return false;
        return (props.userPermissionEntities?.indexOf(operatingEntity || '') >= 0)
    };

    const EditToolbar = (edittoolbarprops: EditToolbarProps) => {
        const { apiRef } = edittoolbarprops;

        const handleAddRecordClick = () => {

            // show latest at top
            // setSortModel([sortByNew]);

            // add a row
            const id = randomId();
            let newEntry = {
                id,
                isNew: true,
                templateName: templateNameHardcoded
            }
            apiRef.current.updateRows([newEntry]);
            apiRef.current.startRowEditMode({ id });

            // // Wait for the grid to render with the new row
            setTimeout(() => {
                apiRef.current.scrollToIndexes({
                    rowIndex: 0 // apiRef.current.getRowsCount() - 1,
                });
                apiRef.current.setCellFocus(id, 'instanceName');

            }, 250);
        };







        return (
            <><GridToolbarContainer >

                <Box sx={{ alignContent: 'right', display: 'flex', justifyContent: 'right', width: '100%' }}>

                    <Button
                        id="vspace_refresh_button"
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={async () => { await props.triggerRefresh(); }}
                        startIcon={<RefreshTwoTone />}
                    >Refresh</Button>

                    <Button
                        id="vspace_add_new_button"
                        sx={{ mr: 1 }}
                        variant="outlined"
                        // color="success"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleAddRecordClick}>
                        Add New
                    </Button>



                    {/* <ImportCSV<T>
                        onData={(importData) => {
                            // add the rows.
                            importData.rows = importData.rows.map(r => {
                                // id field should exist.
                                // @ts-ignore
                                if (r.id === undefined) throw new Error('missing id field on import');
                                // @ts-ignore
                                if (r.id === '') r.id = randomId();
                                return r
                            })
                            apiRef.current.updateRows(importData.rows);
                        }} /> */}

                    {/* <ExportCSV apiRef={apiRef} /> */}

                    <Divider orientation="vertical" sx={{ height: '30px', mr: 1 }} />

                    <Suspense fallback={<Button variant="outlined" disabled>Export XLSX</Button>}>
                        <ExportXLSX apiRef={apiRef} />
                    </Suspense>



                    <Suspense fallback={<Button disabled startIcon={<UploadFileTwoTone />} variant="outlined">Import XLSX</Button>}>
                        <ImportXLSX
                            onData={async (importDataWrongColumns: IVSpaceProperty[]) => {

                                let importData = importDataWrongColumns.map(r => {
                                    let newr: IVSpaceProperty = {};

                                    Object.entries(r).forEach(([key, value]) => {
                                        let field = columns.filter(c => c.headerName === key)[0].field as keyof IVSpaceProperty;
                                        newr[field] = value;
                                    })

                                    return newr;
                                })

                                // try to find the matching row;
                                let currentRows: IVSpaceProperty[] = Array.from(apiRef.current.getRowModels(), ([name, value]) => value);

                                let preppedData = importData.map(importedrow => {
                                    let find = currentRows.filter(cr => (cr.use === importedrow.use) && (cr.instanceName === importedrow.instanceName));


                                    if (find && find[0] && find[0].id) {
                                        importedrow.id = find[0].id;
                                        importedrow.state = find[0].state;
                                        importedrow.instanceId = find[0].instanceId;

                                        // only mark as new if the data is different
                                        if (isDataDifferent(importedrow, find[0])) {
                                            importedrow.isNew = true;
                                        }

                                    } else {
                                        importedrow.id = 'generatedId' + randomId();
                                        importedrow.isNew = true;
                                    }

                                    importedrow.longitude = importedrow.longitude || 0;
                                    importedrow.latitude = importedrow.latitude || 0;

                                    importedrow.templateName = templateNameHardcoded;

                                    return importedrow;
                                });

                                // add the rows.
                                // importData.rows = importData.rows.map(r => {
                                //     // id field should exist.
                                //     // @ts-ignore
                                //     if (r.id === undefined) throw new Error('missing id field on import');
                                //     // @ts-ignore
                                //     if (r.id === '') r.id = randomId();
                                //     return r
                                // })


                                console.log('preppedData', preppedData);

                                // APPLY PERMISSION CHECKS
                                let allowedRows = preppedData.filter(p => checkPermission(p.operatingEntity));
                                console.log('allowedRows', allowedRows)

                                let blockedRowCount = preppedData.length - allowedRows.length;
                                if (blockedRowCount > 0) {
                                    setAlertDisplay({
                                        message: `Error. Can not import ${blockedRowCount} ${addS('row', blockedRowCount)} due to permissions. Please only import rows you have permission to edit.`,
                                        severity: 'error'
                                    });
                                    return;
                                }

                                if (allowedRows.length === 0) {
                                    setAlertDisplay({
                                        message: `0 rows imported. Please check your file.`,
                                        severity: 'error'
                                    })    
                                    return;
                                }

                                setAlertDisplay({
                                    message: `Success! Imported ${allowedRows.length} ${addS('row', allowedRows.length)}. Please click SAVE ALL to commit changes.`,
                                    severity: 'success'
                                });

                                // update rows
                                setTimeout(() => {
                                    apiRef.current.updateRows(allowedRows);
                                }, 100);
                                



                                // let currentRowsSave: IVSpaceProperty[] = Array.from(apiRef.current.getRowModels(), ([name, value]) => value);
                                // await props.processSaveAll(preppedData);
                                // await props.triggerRefresh();
                            }} />
                    </Suspense>


                    <Button
                        id="vspace_save_button"
                        sx={{ mr: 0 }}
                        variant="outlined"
                        disabled={!(Array.from(apiRef.current.getRowModels(), ([name, value]) => value).filter(r => r.isNew).length > 0)}
                        color={(Array.from(apiRef.current.getRowModels(), ([name, value]) => value).filter(r => r.isNew).length > 0) ? "success" : undefined}
                        size="small"
                        startIcon={<SaveTwoTone />}
                        onClick={async () => {
                            let currentRows: IVSpaceProperty[] = Array.from(apiRef.current.getRowModels(), ([name, value]) => value);
                            currentRows = currentRows.filter(r => r.isNew)
                            await props.processSaveAll(currentRows);
                            await props.triggerRefresh();
                        }}>
                        Save all
                    </Button>
                </Box>
            </GridToolbarContainer>
                {alertDisplay && <Alert severity={alertDisplay.severity}>{alertDisplay.message}</Alert>}
                <Divider orientation="horizontal" sx={{ mt: 0.5 }} />
            </>
        );
    }

    let modifieddata = _.clone(props.rows).map((r: any) => {
        if (!r.id) r.id = randomId();
        if (r.isNew === undefined) r.isNew = false;
        return r;
    });

    let cols: GridColumns<IVSpaceProperty> = [];

    cols = cols.concat([{
        field: "isNew", headerName: "Is New", align: 'left',
        hide: true
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (props) => {
            const { id } = props;
            const isInEditMode = apiRef.current.getRowMode(id) === 'edit';
            let isEditable = false;
            if (checkPermission(props.row.operatingEntity)) isEditable = true;

            if (isInEditMode) {
                return [
                    <Tooltip title="Cancel" arrow placement="top">
                        <GridActionsCellItem
                            icon={<UndoTwoTone />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="info"
                        /></Tooltip>,
                    <Tooltip title="Save Row" arrow placement="top"><GridActionsCellItem
                        icon={<SaveTwoTone />}
                        label="Save"
                        onClick={handleSaveClick(id)}
                        color="success"
                    />
                    </Tooltip>,

                ];
            }

            return [
                <Tooltip title="Delete Row" arrow placement="top">
                    <Box>
                        <GridActionsCellItem
                            icon={<DeleteTwoTone />}
                            disabled={!isEditable}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="error"
                        />
                    </Box>
                </Tooltip>,

                <Tooltip title="Clone Row" arrow placement="top">
                    <Box>
                        <GridActionsCellItem
                            icon={<AddTwoTone />}
                            label="Clone"
                            disabled={!isEditable}
                            className="textPrimary"
                            onClick={handleCloneClick(id)}
                            color="info"
                        />
                    </Box>
                </Tooltip>,

                <Tooltip title="Edit Row" arrow placement="top">
                    <Box>
                        <GridActionsCellItem
                            disabled={!isEditable}
                            icon={<EditTwoTone />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="warning"
                        />
                    </Box>
                </Tooltip>,



            ];
        },
    }]);

    cols = cols.concat(props.columns);
    let columns: GridColumns = cols;

    return <Box id="vspace_grid" sx={{ width: '100%', height: '100%' }}>
        <DataGridPro
            density="compact"
            apiRef={apiRef}
            pageSize={10}
            // rowsPerPageOptions={[5, 10, 20]}
            // pagination
            editMode="row"

            // disableSelectionOnClick
            sortModel={sortModel}
            filterModel={filterModel}
            onFilterModelChange={(model, details) => {
                console.log(model);
                setFilterModel(model);
            }}
            onSortModelChange={(model, details) => {
                // let newmodel = _.clone(model);
                // newmodel.unshift(sortByNew);
                // console.log({ model, newmodel, details })
                setSortModel(model);
            }}
            // add a id
            rows={modifieddata}
            initialState={{ pinnedColumns: { left: ['actions', 'isNew', 'instanceName'] } }}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
                Toolbar: EditToolbar,
            }}
            componentsProps={{
                toolbar: { apiRef },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            columns={columns}
        />
    </Box>
}



const addS = (input: string, count: number) => {
    if (count < 2) return input;
    return `${input}s`;
}