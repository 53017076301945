import { Alert, Box, Divider, IconButton, Typography } from '@mui/material';
import { ArrowBackTwoTone } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { ICloudServiceActionResult } from './interfaces';

export function PageView(props: {
    children: any,
    id?: string,
    backButton?: boolean
    /** custom url for back button */
    to?: string
    title?: string,
    caption?: string
    header?: any
    /** show please wait message */
    pleaseWait?: boolean
    /** used to display success/error responses */
    lastResponses?: ICloudServiceActionResult<any>[]
}) {
    if (props.title && !props.caption) document.title = `${props.title}`
    if (!props.title && props.caption) document.title = `${props.caption}`
    if (props.title && props.caption) document.title = `${props.caption} ${props.title}`

    return (<Box id={props.id} sx={{
        width: '100vw',
        p: 1,
        flex: 1,
    }}>
        {(props.backButton && (props.caption || props.title)) &&
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row' }}>
                {props.backButton && <Box sx={{ mr: 1, mt: 0.5 }}>
                    <IconButton component={RouterLink} to={props.to || "/"}><ArrowBackTwoTone /></IconButton>
                </Box>}

                <Divider sx={{ height: '50px', ml: 1, mr: 1 }} orientation="vertical" />

                <Box sx={{ ml: 1 }}>
                    {props.caption && <Typography variant="caption">{props.caption.toUpperCase()}</Typography>}
                    {props.title && <Typography variant="h5">{props.title}</Typography>}
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: 1 }} />
                    <Box>
                        {props.header}
                    </Box>

                </Box>
            </Box>}

        {props.pleaseWait && <Alert severity='info'>Please Wait...</Alert>}        

        {props.lastResponses && props.lastResponses.map((r, i) => <Alert key={i}
            severity={r.isSuccessful ? 'success' : 'error'}
        >{r.exceptionMessage || r.message}</Alert>)}
        
            <Box sx={{ m: 0, p: 0, height: '100%', width: '100%' }}>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {props.children}
                </Box >
            </Box>
        
    </Box>);
}
