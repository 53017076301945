import React from 'react'
import {
    Box,
    Paper,
    TextField,
    Typography
} from "@mui/material";

export interface ITextAreaProps {
    id?: string
    value: string
    onChange: (value: string) => void
    title?: string
    placeholder?: string
    editMode?: boolean
}

export interface ITextAreaState {
    value?: string
}

export class TextArea extends React.Component<ITextAreaProps, ITextAreaState> {
    state: ITextAreaState = {};

    render() {
        let id = undefined;

        if (this.props.title) id = `textarea_${this.props.title?.toLowerCase().split(' ').join('_')}`;
        if (this.props.id) id = this.props.id;

        return <Box id={id} sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 1 }}>
            <Paper elevation={1} sx={{ p: 0, flex: 1 }}>
                <Paper elevation={0} sx={{ flex: 1, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                    <Typography variant="h6" color="secondary" sx={{ p: 0.5, pl:1, m:0 }}>{this.props.title}</Typography>
                </Paper>

                {(this.props.editMode === true)
                    ? <TextField
                        id="outlined-multiline-static"
                        multiline
                        placeholder={this.props.placeholder}
                        value={this.props.value || ""}
                        sx={{ width: '100%', mt: 0 }}
                        style={{ paddingTop: 1}}
                        InputProps={{
                            sx: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
                        }}
                        onChange={(e) => {
                            this.props.onChange(e.target.value);
                            // this.setState({ value: e.target.value });
                        }}
                    />
                    : <Typography paragraph style={{ padding: "16.5px 14px 0px 14px"}} >{this.props.value}</Typography>}

            </Paper>
        </Box>
    }
}