import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import {
    APIIoTnxtMain,
    IProfile,
    IGroup,
    IDapiEntityAccessGroup,
    IEntity,
    IEntityPathAccess
} from "../../commanderapi";

import { EntityAccessDisplay } from ".";

import { config } from '../../App'
import { INodeSettings } from "../../interfaces";

interface IProps {
    authorization: string
    nodeapisettings: INodeSettings
}

interface IState {
    profile?: IProfile
    groups?: IGroup[]
    entityAccessGroups?: IDapiEntityAccessGroup[]
    entityList?: IEntity[]
    entityAccessGetPathAccess?: IEntityPathAccess[]
}

export class EntityAccessGroupsPage extends React.Component<IProps, IState> {
    state: IState = {

    }

    componentDidMount = () => { this.getDataAsync(); }

    getDataAsync = async () => {

        const iotnxtAPI = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrl,
            nodeapisettings: this.props.nodeapisettings
        });

        const iotnxtAPIRobot = new APIIoTnxtMain({
            authorization: this.props.authorization,
            baseUrl: config.baseUrlRobot,
            nodeapisettings: this.props.nodeapisettings
        });

        const [
            profile,
            groups,
            entityList
        ] = await Promise.all([
            iotnxtAPI.authorization.profile.get(),
            iotnxtAPIRobot.policyServer.groups(),
            iotnxtAPIRobot.dapi.EntityTree.GetAccountChildren().then(r => r.result)
        ])


        let entityAccessGroups = await iotnxtAPIRobot.dapi.EntityAccessGroup.GetList().then(r => r.result)


        this.setState({ profile, groups, entityList, entityAccessGroups });
        console.log({ profile, groups, entityList, entityAccessGroups })
    }


    render() {
        if (!this.state.entityAccessGroups) return <CircularProgress />


        return <Box sx={{
            m: 2, p: 2,
            height: '100vh',
            overflowY: 'scroll'
        }}>
            <Typography variant="h4">Entity Access Groups</Typography>

            {this.state.entityAccessGroups.map(entityAccessGroup => <EntityAccessDisplay
                key={entityAccessGroup.id}
                authorization={this.props.authorization}
                nodeapisettings={this.props.nodeapisettings}
                entityAccessGroup={entityAccessGroup}
            />)}

        </Box>
    }


}

