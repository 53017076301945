import {
    DataGridPro,
    GridActionsCellItem
} from '@mui/x-data-grid-pro';

import * as React from 'react';
import { PageView } from './page';

import {
    Box,
    Paper,
    Typography,
    CircularProgress,
    Alert,
    FormControlLabel,
    FormGroup,
    Switch
} from '@mui/material';

import { IToken } from '../components';
import { APIVodafoneVSpacesAPI } from './api';
import { ICloudServiceActionResultPaged, HistoryRecord } from './interfaces';
import { IVSpaceProperty } from './interfaces_vspaces';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { UndoTwoTone } from '@mui/icons-material';


interface VSpaceHistoryProps {
    token: IToken;
    instanceId?: string
    /** dont use page layout only return component */
    only?: boolean 
}

interface VSpaceHistoryState {
    timestampFormat: boolean
    historyResponse?: ICloudServiceActionResultPaged<HistoryRecord<IVSpaceProperty>[]>;
}

export class VSpaceHistory extends React.Component<VSpaceHistoryProps, VSpaceHistoryState> {
    state: VSpaceHistoryState = {
        timestampFormat: true
    }
    api: APIVodafoneVSpacesAPI = new APIVodafoneVSpacesAPI();

    componentDidMount = async () => {
        this.api.authorization = `${this.props.token.token_type} ${this.props.token.access_token}`;

        let historyResponse = await this.api.api.v1.VSpacesDigitwin
            .GetPagedUpdateHistory<IVSpaceProperty>(
                {
                    "orderByQuery": "",
                    "pageNumber": 1,
                    "pageSize": 100000,
                    "selectQuery": "",
                    "whereQuery": {}
                }
            );

        if (this.props.instanceId && historyResponse && historyResponse.data) {
            historyResponse.data = historyResponse.data.filter(i => i.item.instanceId === this.props.instanceId)
        }

        this.setState({ historyResponse });
    }

    render() {
        if (!this.state.historyResponse) return <CircularProgress sx={{ m: 5 }} />;

        if (this.state.historyResponse.isSuccessful === false || !this.state.historyResponse.data) {
            return <Alert severity='error'>
                {this.state.historyResponse.message} {this.state.historyResponse.exceptionMessage}
            </Alert>
        }

        if (this.props.only) return <Box sx={{ height: 500, display:'flex', flexDirection:'column' }}>
                {this.renderComponent()}
                </Box >

        let instanceName = '';
        if (this.state.historyResponse?.data && this.state.historyResponse?.data[0] && this.state.historyResponse?.data[0].item?.instanceName) {
            instanceName = this.state.historyResponse.data[0].item.instanceName;
        }

        return <PageView caption={instanceName} backButton to={this.props.instanceId ? `/site/${this.props.instanceId}` : '/'} title={'History View'}>{this.renderComponent()}</PageView>
    }

    renderComponent() {
        if (!this.state.historyResponse) return <CircularProgress sx={{ m: 5 }} />;

        if (this.state.historyResponse.isSuccessful === false || !this.state.historyResponse.data) {
            return <Alert severity='error'>
                {this.state.historyResponse.message} {this.state.historyResponse.exceptionMessage}
            </Alert>
        }

        return (<>

            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row' }}>
                <FormGroup sx={{ pl: 4 }}>
                    <FormControlLabel control={<Switch defaultChecked
                        checked={this.state.timestampFormat}
                        onChange={() => {
                            this.setState({ timestampFormat: !this.state.timestampFormat })
                        }} />} label="Format timestamp" />
                </FormGroup>
            </Box>

            <Paper sx={{ m: 1, mt: 0, p: 0, flex: 2 }} elevation={5}>
                <Paper sx={{
                    height: '100%',
                    m: 0,
                    p: 0,
                    display: 'flex', flexDirection: 'column',
                    '& .super-app-theme--header': {
                        color: 'error.main',
                    },
                }} elevation={1}>

                    <DataGridPro
                        density="compact"
                        rows={this.state.historyResponse.data.map(r => {
                            // @ts-ignore
                            r.timestamp = new Date(r.timestamp);
                            return r;
                        })}
                        sortModel={[{ field: 'timestamp', sort: 'desc' }]}
                        columns={[
                            {
                                field: 'undo',
                                headerName: '',
                                width: 50,
                                renderHeader: (props) => {
                                    return <></>;
                                },
                                renderCell: (props) => {
                                    return <GridActionsCellItem
                                        icon={<UndoTwoTone />}
                                        label="Undo"
                                        onClick={async () => {
                                            const response = await this.api.api.v1.VSpacesDigitwin.AddOrUpdateInstance(props.row.historyItem)
                                            console.log(response);
                                        }}
                                        color="info"
                                    />
                                }
                            },
                            {
                                field: 'timestamp',
                                headerName: 'Timestamp',
                                type: 'dateTime',
                                flex: 0,
                                width: 180,
                                renderCell: this.state.timestampFormat ? (props) => <Typography title={props.value} >
                                    {moment(props.value).fromNow()}
                                </Typography>
                                    : undefined
                            }, {
                                field: 'username',
                                headerName: 'Username',
                                flex: 0,
                                width: 180,
                            },
                            {
                                field: 'item',
                                headerName: 'Instance Name',
                                renderCell: (props) => props.row.item.instanceName,
                                width: 180,
                            }, {
                                field: 'action',
                                headerName: 'Action',
                                flex: 1,
                                renderCell: (props) => {
                                    return HumanReadableHistoryDiff(props.row as any)
                                }
                            }]}
                    />

                </Paper>
            </Paper>
        </>);
    }
}

export default function VSpaceHistoryRoute(props: VSpaceHistoryProps) {
    let params = useParams<"instanceId">();
    // let location = useLocation();
    // let navigate = useNavigate();

    if (!params.instanceId) return <Alert severity="info">Missing instanceId</Alert>

    return <VSpaceHistory instanceId={params.instanceId} token={props.token} only={props.only} />
}


export function HumanReadableHistoryDiff(
    mostrecent: HistoryRecord<IVSpaceProperty>,
    simplified?: boolean
): string {
    if (!mostrecent) return '';

    if (mostrecent && mostrecent.action === "Updated") {
        let description = simplified ? `` : `${mostrecent.action} `;
        Object.keys(mostrecent.item).forEach((prop) => {
            // if (prop === 'cacheLastUpdatedDate') return;
            // @ts-ignore
            if (mostrecent.item[prop] !== mostrecent.historyItem[prop]) {
                // @ts-ignore
                description += `${prop} changed from "${mostrecent.historyItem[prop]}" to "${mostrecent.item[prop]}" `;
            }
        });
        return `${description}`;
    }

    if (simplified) return ''; // used on the footer. Then we only show what changed.

    return `${mostrecent.action}`;
}