// https://community.iotnxt.com/docs/commander/integration/user-preference/
// https://community.iotnxt.com/assets/js/swagger-definitions/user-preference.json
// by Rouan van der Ende Fri 08 Jul 2022

import { IAPIOptions } from "./main";
import { encodeQueryData } from "./util_encodequerydata"



export interface UserRecentAppModel {
    appId?: string
    lastModified: string
};

export class APIIoTnxtUserPreferenceAPIServiceAPIs {
    private options: IAPIOptions;

    constructor(options: IAPIOptions) {
        this.options = options;
    }

    private rest_get_v1default_view = async (): Promise<string> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/default-view`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    /** returns theme id */
    private rest_get_v1themes = async (): Promise<string> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/themes`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_post_v1themes_themeId_ = async (params: { themeId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/themes/{themeId}?${encodeQueryData(params)}`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    private rest_get_v1favorite_apps = async (): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/favorite-apps`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_post_v1favorite_apps_appId_ = async (params: { appId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/favorite-apps/{appId}?${encodeQueryData(params)}`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    private rest_delete_v1favorite_apps_appId_ = async (params: { appId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/favorite-apps/{appId}?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_get_v1recent_apps = async (): Promise<UserRecentAppModel> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/recent-apps`, {
            method: 'get',
            headers: {
                authorization: this.options.authorization,
            },
        }).then((res) => res.json());
    };

    private rest_post_v1recent_apps_appId = async (params: { appId: string, }): Promise<unknown> => {
        return fetch(`${this.options.baseUrl}/${this.options.nodeapisettings.NodeSettings.TenantUrlName}/_apis/user-preference/v1/recent-apps/{appId}?${encodeQueryData(params)}`, {
            method: 'post',
            headers: {
                authorization: this.options.authorization,
            },
            body: JSON.stringify({}),
        }).then((res) => res.json());
    };

    // API
    defaultView = {
        get: this.rest_get_v1default_view
    }
    themes = {
        get: this.rest_get_v1themes
    }
    favoriteApps = {
        get: this.rest_get_v1favorite_apps
    }
    recentApps = {
        get: this.rest_get_v1recent_apps
    }
}